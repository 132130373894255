import React from 'react'
import {useSelector} from 'react-redux'
import {Button, Popconfirm, Tag, Tooltip} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {labels} from '../Labels';
import {store} from '../../App';
import {filterBadgeCounter, toggleHeaderDatepicker} from '../../redux/actions';
import DataTableView from '../../components/uplink-components/DataTableView';
import {pagination} from '../../utils/helper';
import util from '../../utils';
import IntlMessage from '../../components/util-components/IntlMessage';
import {useIntl} from 'react-intl';
import * as api from 'services';
import SmartRoutineFormInDrawer from '../../components/uplink-components/SmartRoutineFormInDrawer';

export const SmartRoutine = (props) => {
  const branchId = useSelector(state => state.user?.branchId);
  const smartRoutineList = useSelector(state => state.ws?.smartRoutineList);
  const smartRoutineListTotal = useSelector(state => state.ws?.smartRoutineListTotal);
  const [searchData, setSearchData] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isRefresh, setIsRefresh] = React.useState(false);

  const intl = useIntl();
  const prevKey = React.useRef()

  if (prevKey.current !== props.location.key) {
    prevKey.current = props.location.key;
    setIsRefresh(true);
  }

  const onSearch = async (searchInfo) => {
    setSearchData(searchInfo);
    const tableInfo = {...pagination, page: currentPage, branchId, ...searchInfo};
    setTimeout(() => {
      api.getSmartRoutineList(tableInfo);
    }, 0);
  };

  React.useEffect(() => {
    if (isRefresh && branchId !== undefined) {
      onSearch({page: 1, searchWord: searchData?.searchWord}).catch();
    }
    if (!isRefresh && branchId === undefined) {
      setIsRefresh(true);
    }
  }, [branchId, isRefresh]);

  React.useEffect(() => {
    pagination['searchWord'] = '';
    store.dispatch(toggleHeaderDatepicker(false));
    store.dispatch(filterBadgeCounter(0));
  }, []);

  const setRefresh = (data) => {
    const {isRefresh, page} = data
    setSearchData(data);
    setIsRefresh(isRefresh);
    setCurrentPage(page);
  };

  const getCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const deleteSmartRoutine = async elm => {
    await api.smartRoutineCUD({isDel: true, branchId, routineId: elm['routine_id']}).then(res => {
      onSearch().catch();
    });
  };

  const tableColumns = [
    {
      title: intl.formatMessage({id: 'branch_name'}),
      dataIndex: 'branch_name',
      width: 200,
      sorter: true,
    },
    {
      title: intl.formatMessage({id: 'houseName'}),
      dataIndex: 'house_name',
      width: 300,
      sorter: true,
    },
    {
      title: intl.formatMessage({id: 'routineName'}),
      dataIndex: 'routine_name',
      width: 250,
      sorter: true,
    },
    {
      title: intl.formatMessage({id: 'create_date'}),
      dataIndex: 'created',
      width: 200,
      render: (_, record) => (
        <div className="d-flex">
          {util.formatDTS(record['created'])}
        </div>
      ),
      sorter: true,
    },
    {
      title: intl.formatMessage({id: 'lastRun'}),
      dataIndex: 'last_run',
      width: 200,
      render: (_, record) => (
        <div className="d-flex">
          {util.formatDTS(record['last_run'])}
        </div>
      ),
    },
    {
      title: intl.formatMessage({id: 'activate'}),
      dataIndex: 'isactivated',
      width: 100,
      render: status => (
        <Tag className="text-capitalize" color={status === 'Y' || status === true ? 'cyan' : 'red'}>
          {(status === 'Y' || status === true) ? labels.ACTIVATE : labels.DEACTIVATE}
        </Tag>
      ),
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 100,
      render: (_, elm, index) => (
        <div className="text-right d-flex justify-content-end" style={{height: '5px', alignItems: 'center'}}>
          <Tooltip title="View">
            <SmartRoutineFormInDrawer title={labels.SMART_ROUTINE_EDIT}
                                      elm={elm}
                                      elmIndex={index}
                                      smartRoutineData={smartRoutineList}
                                      onSearch={onSearch}/>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm placement="leftTop" title={<IntlMessage id={'DELETE_TITLE'}/>} onConfirm={() => {
              deleteSmartRoutine(elm).catch();
            }} okText={<IntlMessage id={'YES'}/>} cancelText={<IntlMessage id={'NO'}/>}>
              <Button danger icon={<DeleteOutlined/>} size="small"/>
            </Popconfirm>
          </Tooltip>
        </div>
      )
    }
  ];
  return (
    <div>
      <DataTableView
        columns={tableColumns}
        dataSource={smartRoutineList}
        rowKey="routine_id"
        totalCount={smartRoutineListTotal}
        onSearch={onSearch}
        getCurrentPage={getCurrentPage}
        showSearchWord={true}
        isRefresh={isRefresh}
        setRefresh={setRefresh}
        title={labels.SMART_ROUTINE_ADD}
        showBranch={true}
        showSearchDate={false}
      />
    </div>
  );
};

export default SmartRoutine;
