import React from 'react';
import {Typography} from 'antd';
import {DEVICE_PROTOCOL_MAP} from '../../constants/UplinkConstant';
import _ from 'lodash';
import DeviceConnectCodeDownButton from './DeviceConnectCodeDownButton';
import {useIntl} from 'react-intl';

const {Link} = Typography;

const DeviceConnectInfo = ({protocol, apiKey, serial, sensors, commands}) => {
  const intl = useIntl();

  const getPayloadTCPSampleString = () => {
    let dataString = '{';
    if (sensors !== undefined) {
      sensors.forEach((sensor, index, sensors) => {
        const sensorString = `\\"${sensor.sensorType}\\": ${Math.floor(Math.random() * 50) + 10}`
        if (index !== sensors.length - 1) {
          dataString += `${sensorString}, `;
        } else {
          if (commands && commands?.length > 0) {
            dataString += `${sensorString}, `;
          } else {
            dataString += `${sensorString}`;
          }
        }
      })
    }
    if (commands !== undefined) {
      commands.forEach((command, index, commands) => {
        const commandString = `\\"${command.commandType}\\": \\"${_.get(command.commandValues[0], 'value')}\\"`
        if (index !== commands.length - 1) {
          dataString += `${commandString}, `;
        } else {
          dataString += `${commandString}`;
        }
      })
    }
    dataString += '}';
    return `"{\\"serial\\": \\"${serial}\\", \\"api_key\\": \\"${apiKey}\\", \\"data\\":${dataString}}"`
  }

  const getPayloadHTTPSampleString = () => {
    let dataString = '{';
    if (sensors !== undefined) {
      sensors.forEach((sensor, index, sensors) => {
        const sensorString = `"${sensor.sensorType}": ${Math.floor(Math.random() * 50) + 10}`
        if (index !== sensors.length - 1) {
          dataString += `${sensorString}, `;
        } else {
          if (commands && commands?.length > 0) {
            dataString += `${sensorString}, `;
          } else {
            dataString += `${sensorString}`;
          }
        }
      })
    }
    if (commands !== undefined) {
      commands.forEach((command, index, commands) => {
        const commandString = `"${command.commandType}": "${_.get(command.commandValues[0], 'value')}"`
        if (index !== commands.length - 1) {
          dataString += `${commandString}, `;
        } else {
          dataString += `${commandString}`;
        }
      })
    }
    dataString += '}';
    return `{"serial": "${serial}", "api_key": "${apiKey}", "data":${dataString}}`
  }

  const renderMQTTCOAPConnectionInfo = () => {
    return (
      <>
        <h1>{intl.formatMessage({id: 'protocol.title'}, {protocol})}</h1>
        <br/>
        <h2>Step1</h2>
        <div>{intl.formatMessage({id: 'protocol.step1'})}</div>
        <br/>
        <DeviceConnectCodeDownButton protocol={protocol} apiKey={apiKey} serial={serial} sensors={sensors}
                                     commands={commands}/>
        <br/><br/>
        <h2>Step2</h2>
        <div>{intl.formatMessage({id: 'protocol.step2'})}</div>
        <br/>
        <Link href={protocol === DEVICE_PROTOCOL_MAP.mqtt
          ? 'https://github.com/UplinkPlus/uplink_iot/tree/master/examples/mqtt'
          : 'https://github.com/UplinkPlus/uplink_iot/tree/master/examples/coap'}
              target="_blank">https://github.com/UplinkPlus/uplink_iot</Link>
        <br/><br/>
      </>
    )
  }

  const renderTCPHTTPConnectionInfo = () => {
    return (
      <>
        <h1>{intl.formatMessage({id: 'protocol.title'}, {protocol})}</h1>
        <br/>
        <span>예시:</span>
        <div style={{
          maxWidth: 'fit-content',
          background: '#f9f9f9',
          border: '1px solid #ededed',
          borderRadius: '8px',
          padding: '14px 10px 0px 10px',
        }}>{protocol === DEVICE_PROTOCOL_MAP.tcp
          ? <pre>{`Hostname: iotdevice.uplink.plus
Port: 8811
Payload: ${getPayloadTCPSampleString()}`}</pre>
          : <pre>{`URL: http://tbed.uplink.plus/stat
Payload(JSON String in Body): ${getPayloadHTTPSampleString()}`}</pre>}
        </div>
        <br/>
        <br/>
        <p>{intl.formatMessage({id: 'protocol.info1'}, {protocol})}</p>
        <p>{intl.formatMessage({id: 'protocol.info2'})}</p>
      </>
    )
  }

  const renderDeviceConnectInfo = () => {
    switch (protocol) {
      case DEVICE_PROTOCOL_MAP.mqtt:
        return renderMQTTCOAPConnectionInfo();
      case DEVICE_PROTOCOL_MAP.coap:
        return renderMQTTCOAPConnectionInfo();
      case DEVICE_PROTOCOL_MAP.tcp:
        return renderTCPHTTPConnectionInfo();
      case DEVICE_PROTOCOL_MAP.http:
        return renderTCPHTTPConnectionInfo();
      default:
        return null;
    }
  }

  return (
    <>
      {renderDeviceConnectInfo()}
    </>
  );
}

export default DeviceConnectInfo;
