import {
  SION_DASHBOARD_HOUSE_LIST
} from '../constants/Sion';

export const sionDashboardHouseList = (data) => {
  return {
    type: SION_DASHBOARD_HOUSE_LIST,
    sionDashboardHouseList: data
  }
}
