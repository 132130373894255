import React from 'react';
import {Button, Form, Input, notification, Select, Row, Col} from 'antd';
import {DEVICE_KIND_MAP, DEVICE_PROTOCOL_LIST} from '../../../constants/UplinkConstant';
import utils from '../../../utils';
import {useIntl} from 'react-intl';
import IntlMessage from '../../../components/util-components/IntlMessage';
import * as api from 'services';
import {useSelector} from 'react-redux';
import SelectInDrawer, {SELECT_IN_DRAWER_DATA_MAP} from 'components/uplink-components/SelectInDrawer';

const {Option} = Select;

export const AddDeviceFirstStep = (props) => {
  const [deviceKind, setDeviceKind] = React.useState(props.fields?.deviceKind);
  const [remoteTypeId, setRemoteTypeId] = React.useState(props.fields?.remoteTypeId);
  const remoteTypeList = useSelector(state => state.ws?.selectRemoteTypeList) ?? [];
  const [form] = Form.useForm();
  const intl = useIntl();
  const rowCount = 10;

  React.useEffect(() => {
    api.getRemoteTypes({rowCount}).catch();
  }, [])

  const getSelectedRemoteType = (selectedRemoteType) => {
    setRemoteTypeId(selectedRemoteType?.remote_type_id);
    form.setFieldsValue({remoteType: selectedRemoteType?.remote_type_name});
  }

  const getRemoteTypeName = () => {
    return remoteTypeList.find((remoteType) => remoteType?.remote_type_id === remoteTypeId)?.remote_type_name;
  }

  const onFinish = (values) => {
    const matchedDeviceNameIndex = props.deviceData?.findIndex((device) => {
      return device.device_name === values.deviceName;
    })
    if (matchedDeviceNameIndex !== -1) {
      notification.warning({message: intl.formatMessage({id: 'error_device_name'})})
      return;
    }

    const matchedSerialNumberIndex = props.deviceData?.findIndex((device) => {
      return device.serial === values.serial;
    })
    if (matchedSerialNumberIndex !== -1) {
      notification.warning({message: intl.formatMessage({id: 'error_serial'})})
      return;
    }
    props.submittedValues({
      protocol: values?.protocol,
      deviceKind: values.deviceKind,
      deviceName: values.deviceName,
      serial: values.serial,
      remoteTypeId: remoteTypeId,
    });
    props.handleNextButton();
  }

  return (
    <Form className="w-100 mt-3 mx-3" layout="vertical" onFinish={onFinish} form={form}>
      <Form.Item name="protocol" label={intl.formatMessage({id: 'device_protocol'})}
                 initialValue={props.fields?.protocol}
                 rules={[
                   {
                     required: true,
                     message: intl.formatMessage({id: 'select_protocol'})
                   }
                 ]}>
        <Select placeholder={intl.formatMessage({id: 'select_protocol'})}>
          {DEVICE_PROTOCOL_LIST.map((protocol) => {
            return <Option key={protocol} value={protocol}>{protocol}</Option>
          })}
        </Select>
      </Form.Item>
      <Form.Item name="deviceKind" label={intl.formatMessage({id: 'KIND_OF_DEVICE'})}
                 initialValue={props.fields?.deviceKind}
                 rules={[
                   {
                     required: true,
                     message: intl.formatMessage({id: 'select_device_kind'})
                   }
                 ]}>
        <Select onChange={setDeviceKind} placeholder={intl.formatMessage({id: 'select_device_kind'})}>
          {Object.keys(DEVICE_KIND_MAP).map((kind) => {
            return <Option key={kind} value={kind}><IntlMessage id={`${kind}`}/></Option>
          })}
        </Select>
      </Form.Item>
      <Form.Item name="deviceName" label={intl.formatMessage({id: 'device_name'})} rules={[
        {
          required: true,
          message: intl.formatMessage({id: 'write_device_name'})
        }
      ]}
                 initialValue={props.fields?.deviceName}>
        <Input placeholder={intl.formatMessage({id: 'write_device_name'})}/>
      </Form.Item>
      <Form.Item name="serial" label={intl.formatMessage({id: 'serial'})} rules={[
        {
          required: true,
          message: intl.formatMessage({id: 'write_serial'})
        }
      ]}
                 initialValue={props.fields?.serial}>
        <Input placeholder={intl.formatMessage({id: 'write_serial'})}/>
      </Form.Item>
      {utils.isActuator(deviceKind) &&
      <Row>
        <Col xs={19} sm={19} md={22} lg={22} xl={22}>
          <Form.Item
            style={{flexGrow: 1}}
            rules={[
              {
                required: true,
                message: intl.formatMessage({id: 'select_remote_type'})
              }
            ]}
            name="remoteType"
            label={<IntlMessage id={'remote_type'}/>}
            initialValue={getRemoteTypeName(remoteTypeId)}
          >
            <Input disabled={true} placeholder={intl.formatMessage({id: 'select_remote_type'})}/>
          </Form.Item>
        </Col>
        <Col xs={5} sm={4} md={2} lg={2} xl={2}>
          <div style={{marginLeft: '10px', marginTop: '31px'}}>
            <SelectInDrawer
              selectedRowKey={remoteTypeId}
              getSelectedRowData={getSelectedRemoteType}
              componentDataMap={SELECT_IN_DRAWER_DATA_MAP.remoteType}/>
          </div>
        </Col>
      </Row>
      }
      <Form.Item>
        <div className="steps-action mt-3 d-flex justify-content-between">
          <Button onClick={props.handleCancelButton}>
            {intl.formatMessage({id: 'cancel'})}
          </Button>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({id: 'next'})}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default AddDeviceFirstStep;
