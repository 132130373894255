import React from 'react';
import {Row, Col, Empty} from 'antd';
import {useSelector} from 'react-redux';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import CardSensorData from './components/CardSensorData';
import EventLogTable from './components/EventLogTable';
import ChartSensorData from './components/ChartSensorData';
import CardControllerData from './components/CardControllerData';
import NotWorkingDeviceTable from './components/NotWorkingDeviceTable';
import DashboardFormInDrawer from './components/DashboardFormInDrawer';
import utils from '../../utils';
import {labels} from '../Labels';
import {useIntl} from 'react-intl';
import {USER_TYPE_NUMBER_MAP, WIDGET_TYPE_OPTIONS_MAP} from '../../constants/UplinkConstant';
import _ from 'lodash';
import * as api from 'services';

const owner = utils.getUserInfo();

export const Default = () => {
  const isTestBed = utils.isTestBed();
  const {direction} = useSelector(state => state.theme);
  const branchId = useSelector(state => isTestBed ? state.user?.testBedBranchId : state.user?.branchId);
  const [leftSideWidgets, setLeftSideWidgets] = React.useState({});
  const [rightSideWidgets, setRightSideWidgets] = React.useState({});
  const [hasSampleData, setHasSampleData] = React.useState(false);
  const intl = useIntl();

  React.useEffect(() => {
    if (branchId !== undefined) {
      if (owner?.auth === USER_TYPE_NUMBER_MAP.super_admin
        || _.isNil(getDataFromLocalStorage(true))) {
        api.getUserUI({branchId: branchId, isTestBed: isTestBed}).then(res => {
            const dashboard = res.data?.dashboard;
            const controller = res.data?.controller;
            const isSample = res.data?.isSample || false;

            setLeftSideWidgets({})
            setRightSideWidgets({})
            setHasSampleData(isSample);
            getDashboardData(dashboard, true, isSample);
            getDashboardData(controller, false, isSample);
        });
      } else {
        const dashboard = getDataFromLocalStorage(true);
        const controller = getDataFromLocalStorage(false);

        setLeftSideWidgets({})
        setRightSideWidgets({})
        setHasSampleData(false);
        getDashboardData(dashboard, true, false);
        getDashboardData(controller, false, false);
      }
    }
  }, [branchId]);

  const getDataFromLocalStorage = (isLeftSide) => {
    const data = JSON.parse(localStorage.getItem(isLeftSide ? 'localDashboardLayout' : 'localControllerLayout')) || [];
    return data.find((d) => d.branchId === branchId)
  }

  const onSaveLocalStorage = (result, isLeftSide) => {
    try {
      if (owner.auth !== USER_TYPE_NUMBER_MAP.super_admin) {
        let data = JSON.parse(localStorage.getItem(isLeftSide ? 'localDashboardLayout' : 'localControllerLayout')) || [];
        const matchedDataIndex = data.findIndex((d) => d.branchId === branchId);
        if (matchedDataIndex === -1) {
          data.push(result);
        } else {
          data[matchedDataIndex] = result;
        }
        localStorage.setItem(isLeftSide ? 'localDashboardLayout' : 'localControllerLayout', JSON.stringify(data));
      }
    } catch (e) {
      console.log('error:', e);
    }
  }

  const onDragEnd = React.useCallback((result, isLeftSide) => {
    if (!result.destination) {
      return;
    }
    Reorder(isLeftSide ? leftSideWidgets?.result : rightSideWidgets?.result,
      result.source.index, result.destination.index, labels.DRAG, '', branchId, isLeftSide);
  }, [leftSideWidgets, rightSideWidgets]);

  const onSearch = async (data, type) => {
    const dataInfo = {branchId: branchId, ...data};
    if (type === WIDGET_TYPE_OPTIONS_MAP.card) {
      return (isTestBed)
        ? await api.getTestBedItemInfo(dataInfo)
        : await api.getItemInfo(dataInfo)
    } else if (type === WIDGET_TYPE_OPTIONS_MAP.graph) {
      return (isTestBed)
        ? await api.getTestBedGraphInfo(dataInfo)
        : await api.getGraphInfo(dataInfo)
    } else if (type === WIDGET_TYPE_OPTIONS_MAP.event_log) {
      return (isTestBed)
        ? await api.getTestBedEventLog(dataInfo)
        : await api.getEventLog(dataInfo)
    } else if (type === WIDGET_TYPE_OPTIONS_MAP.controller_status) {
      return (isTestBed)
        ? await api.getTestBedControllerInfo(dataInfo)
        : await api.getControllerInfo(dataInfo)
    } else if (type === WIDGET_TYPE_OPTIONS_MAP.not_working_device_list) {
      return (isTestBed)
        ? await api.getTestBedErrorDevice(dataInfo)
        : await api.getErrorDevice(dataInfo)
    } else {
      return [];
    }
  };

  const onSave = () => {
    api.setUserUI({
      branchId: branchId,
      dashboard: {dashboard: leftSideWidgets, controller: rightSideWidgets},
      isTestBed: isTestBed,
    }).then(res => {
      if (res) {
        utils.showSuccess(intl.formatMessage({id: 'success_save'}));
      }
    });
  };

  const onAdd = (newData) => {
    onSearch(newData.items, newData.key).then((res) => {
      const resData = res?.data || {};
      if (resData) {
        let items = undefined;
        let isLeftSide = undefined;
        switch (newData.key) {
          case WIDGET_TYPE_OPTIONS_MAP.card:
            items = resData;
            isLeftSide = true;
            break;
          case WIDGET_TYPE_OPTIONS_MAP.graph:
            items = {
              xCount: newData.xValueCount,
              series: resData?.items,
              xAxis: resData?.categories,
              xValueStr: resData?.xValueStr,
              graphType: resData?.graphType,
              height: '400px',
              direction: direction
            }
            isLeftSide = true;
            break;
          case WIDGET_TYPE_OPTIONS_MAP.event_log:
            items = resData;
            isLeftSide = true;
            break;
          case WIDGET_TYPE_OPTIONS_MAP.controller_status:
            items = {
              controllerId: resData?.controller_id,
              updated: resData?.updated,
              currentVal: resData?.current_val,
              iconUrl: resData?.icon_url
            }
            isLeftSide = false;
            break;
          case WIDGET_TYPE_OPTIONS_MAP.not_working_device_list:
            items = resData;
            isLeftSide = false;
            break;
          default :
            break;
        }

        const dataList = {
          key: newData.key,
          widgetTitle: newData.widgetTitle,
          params: newData.items,
          items: items,
        };
        setHasSampleData(false)
        if (items !== undefined) {
          Reorder(isLeftSide ? leftSideWidgets?.result : rightSideWidgets?.result,
            0, 0, labels.ADD, dataList, branchId, isLeftSide);
        } else {
          utils.showWarning(intl.formatMessage({id: 'no_data'}));
        }
      } else {
        utils.showWarning(intl.formatMessage({id: 'no_data'}));
      }
    });
  };

  const onDelete = (event, index, isLeftSide) => {
    event.preventDefault();
    Reorder(isLeftSide ? leftSideWidgets?.result : rightSideWidgets?.result,
      index, 0, labels.DELETE, '', branchId, isLeftSide);
  };

  const getDashboardData = (dashboard, isLeftSide, isSample) => {
    const getWidget = (data, resData = []) => {
      let widget;
      if (data.key === WIDGET_TYPE_OPTIONS_MAP.card) {
        widget = resData?.length > 0 ? {...data, items: resData} : {...data};
      } else if (data.key === WIDGET_TYPE_OPTIONS_MAP.graph) {
        widget = resData?.length > 0 ? {
          ...data,
          items: {
            xCount: data.items.xValueCount,
            series: resData?.items,
            xAxis: resData?.categories,
            xValueStr: resData?.xValueStr,
            type: resData?.type,
            height: '400px',
            direction: direction
          }
        } : {...data};
      } else if (data.key === WIDGET_TYPE_OPTIONS_MAP.controller_status) {
        widget = resData?.length > 0 ? {
          ...data,
          items: {
            controllerId: resData?.controller_id,
            updated: resData?.updated,
            currentVal: resData?.current_val,
            iconUrl: resData?.icon_url
          }
        } : {...data};
      } else if (data.key === WIDGET_TYPE_OPTIONS_MAP.event_log) {
        widget = resData?.length > 0 ? {...data, items: resData} : {...data};
      } else if (data.key === WIDGET_TYPE_OPTIONS_MAP.not_working_device_list) {
        widget = resData?.length > 0 ? {...data, items: resData} : {...data};
      }
      return widget;
    }

    const newWidgets = [];
    if (dashboard && dashboard?.result !== undefined) {
      if (isSample) {
        dashboard?.result?.map((data) => {
          const widget = {key: data?.key, widgetTitle: data?.widgetTitle, items: data?.items};
          newWidgets.push(widget);
        })
        isLeftSide
          ? setLeftSideWidgets({branchId: dashboard?.branchId, result: newWidgets})
          : setRightSideWidgets({branchId: dashboard?.branchId, result: newWidgets});
      } else {
        Promise.all(dashboard?.result?.map((data, index) => {
          if (data === null) {
            return null;
          }
          return onSearch(data.params, data.key).then((res) => {
            newWidgets[index] = getWidget(data, res?.data);
            ;
          });
        })).then(() => {
          isLeftSide
            ? setLeftSideWidgets({branchId: dashboard?.branchId, result: newWidgets})
            : setRightSideWidgets({branchId: dashboard?.branchId, result: newWidgets})
        })
      }
    }
  };

  const Reorder = (list, startIndex, endIndex, mode, newData, branchId, isLeftSide) => {
    let result = list?.length > 0 ? Array.from(list) : [];
    if (mode === labels.DRAG) {
      const removed = result.splice(startIndex, 1)[0];
      result.splice(endIndex, 0, removed);
    } else if (mode === labels.ADD) {
      if (hasSampleData) {
        setLeftSideWidgets({});
        setRightSideWidgets({});
        result = [newData]
      } else {
        result.splice(0, 0, newData);
      }
    } else if (mode === labels.DELETE) {
      result.splice(startIndex, 1);
    }
    const resultItem = {branchId, result};

    onSaveLocalStorage(resultItem, isLeftSide);
    isLeftSide ? setLeftSideWidgets(resultItem) : setRightSideWidgets(resultItem);
  }

  const renderDashboardTop = () => {
    return (
      <Col span={24}>
        <div style={{display: 'flex', justifyContent: 'flex-end', paddingBottom: 10}}>
          <DashboardFormInDrawer onAdd={onAdd} onSave={onSave} hasSampleData={hasSampleData}/>
        </div>
      </Col>
    )
  }

  const renderDashboardLeftSide = () => {
    const isLeftSide = true;
    return (
      <Col xs={24} sm={24} md={24} lg={24} xl={18}>
        <DragDropContext onDragEnd={(result) => {
          onDragEnd(result, isLeftSide)
        }}>
          <Droppable droppableId="dashboard">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {leftSideWidgets?.result?.map((data, i) => {
                  let views = '';
                  const onLeftSideDelete = (event) => {
                    onDelete(event, i, isLeftSide)
                  }
                  if (data?.key) {
                    switch (data?.key) {
                      case WIDGET_TYPE_OPTIONS_MAP.card:
                        views =
                          <CardSensorData title={data.widgetTitle} onDelete={onLeftSideDelete}
                                          items={data.items}/>
                        break;
                      case WIDGET_TYPE_OPTIONS_MAP.graph:
                        views =
                          <ChartSensorData title={data.widgetTitle} onDelete={onLeftSideDelete}
                                           items={data.items}/>;
                        break;
                      case WIDGET_TYPE_OPTIONS_MAP.event_log:
                        views =
                          <EventLogTable title={data.widgetTitle} onDelete={onLeftSideDelete}
                                         dataSource={data.items}/>;
                        break;
                      default:
                        views = '';
                        break;
                    }
                  }
                  return (
                    <Draggable key={`data${i}`} draggableId={`data${i}`} index={i}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          <Row gutter={16}>
                            <Col span={24}>
                              {views}
                            </Col>
                          </Row>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Col>
    )
  }

  const renderDashboardRightSide = () => {
    const isLeftSide = false;
    return (
      <Col xs={24} sm={24} md={24} lg={24} xl={6}>
        <DragDropContext onDragEnd={(result) => {
          onDragEnd(result, isLeftSide)
        }}>
          <Droppable droppableId="controller">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {rightSideWidgets?.result?.map((data, i) => {
                  let views = '';
                  const onRightSideDelete = (event) => {
                    onDelete(event, i, isLeftSide)
                  }
                  if (data?.key) {
                    switch (data?.key) {
                      case WIDGET_TYPE_OPTIONS_MAP.controller_status:
                        views = <CardControllerData title={data.widgetTitle} onDelete={onRightSideDelete}
                                                    items={data.items}/>
                        break;
                      case WIDGET_TYPE_OPTIONS_MAP.not_working_device_list:
                        views = <NotWorkingDeviceTable title={data.widgetTitle} onDelete={onRightSideDelete}
                                                       dataSource={data.items}/>
                        break;
                      default:
                        break;
                    }
                    return (
                      <Draggable key={`controller${i}`} draggableId={`controller${i}`} index={i}>
                        {(provided, snapshot) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            {views}
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Col>
    )
  }

  return (
    <>
      <Row gutter={16}>
        {renderDashboardTop()}
      </Row>
      {
        (leftSideWidgets.result?.length > 0 || rightSideWidgets.result?.length > 0) ?
          <Row gutter={10} >
            {renderDashboardLeftSide()}
            {renderDashboardRightSide()}
          </Row> : <Empty style={{marginTop: '200px'}}/>
      }
    </>
  )
}

export default Default;
