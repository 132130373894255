import API from './API';
import utils from 'utils';

export const login = async (data) => {
  const action = 'LogIn';
  const reqData = {action, data};
  const {data: res} = await API.sendList('user/R', reqData);

  // const res = [{id: 1, email: 'test@uplink.plus', token: 'asdlfaskldf', key_str: 'alkajsdf'}]
  if (res) {
    const {id, email, key_str} = res;
    utils.setUserAndToken(res);
    return {id, email, key_str};
  } else {
    return null;
  }
}
