import {
  SET_BRANCH_ID,
  SET_BRANCH_TYPE,
  SET_TESTBED_BRANCH_ID,
  SET_LOGO,
} from '../constants/User';

export const setBranchId = (branchId) => {
  return {
    type: SET_BRANCH_ID,
    branchId: branchId
  }
};

export const setBranchType = (branchType) => {
  return {
    type: SET_BRANCH_TYPE,
    branchType: branchType
  }
};

export const setTestBedBranchId = (testBedBranchId) => {
  return {
    type: SET_TESTBED_BRANCH_ID,
    testBedBranchId: testBedBranchId,
  }
};

export const setLogo = (logo) => {
  return {
    type: SET_LOGO,
    logo: logo,
  }
};
