import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Button, Form, Input, Divider, Alert} from "antd";
import {MailOutlined, LockOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types';
import {
  signIn,
  showLoading,
  showAuthMessage,
  hideAuthMessage,
  signInWithGoogle,
  signInWithFacebook
} from 'redux/actions/Auth';
import {useHistory} from "react-router-dom";
import {motion} from "framer-motion"
import {useIntl} from "react-intl";
import IntlMessage from "../util-components/IntlMessage";
import * as api from 'services';

// import { GoogleSVG, FacebookSVG } from 'assets/svg/icon';
// import CustomIcon from 'components/util-components/CustomIcon'

export const LoginForm = props => {
  let history = useHistory();
  const intl = useIntl();

  const {
    otherSignIn,
    showForgetPassword,
    hideAuthMessage,
    onForgetPasswordClick,
    showLoading,
    signInWithGoogle,
    signInWithFacebook,
    extra,
    token,
    loading,
    redirect,
    showMessage,
    message,
    allowRedirect
  } = props

  const onLogin = values => {
    showLoading()
    api.login(values);
  };

  const onGoogleLogin = () => {
    showLoading()
    signInWithGoogle()
  }

  const onFacebookLogin = () => {
    showLoading()
    signInWithFacebook()
  }

  useEffect(() => {
    if (token !== null && allowRedirect) {
      history.push(redirect)
    }
    if (showMessage) {
      setTimeout(() => {
        hideAuthMessage();
      }, 3000);
    }
  });

  return (
    <>
      <motion.div
        initial={{opacity: 0, marginBottom: 0}}
        animate={{
          opacity: showMessage ? 1 : 0,
          marginBottom: showMessage ? 20 : 0
        }}>
        <Alert type="error" showIcon message={message}></Alert>
      </motion.div>
      <Form
        layout="vertical"
        name="login-form"
        onFinish={onLogin}
      >
        <Form.Item
          name="loginId"
          label={intl.formatMessage({id: "email"})}
          rules={[
            {
              required: true,
              message: <IntlMessage id={"write_email"}/>,
            },
            {
              type: 'email',
              message: <IntlMessage id={"write_valid_email"}/>
            }
          ]}>
          <Input prefix={<MailOutlined className="text-primary"/>}/>
        </Form.Item>
        <Form.Item
          name="password"
          label={
            <div className={`${showForgetPassword ? 'd-flex justify-content-between w-100 align-items-center' : ''}`}>
              <span>{intl.formatMessage({id: "password"})}</span>
              {
                showForgetPassword &&
                <span
                  onClick={() => onForgetPasswordClick}
                  className="cursor-pointer font-size-sm font-weight-normal text-muted"
                >
									Forget Password?
								</span>
              }
            </div>
          }
          rules={[
            {
              required: true,
              message:  <IntlMessage id={"write_password"}/>,
            }
          ]}
        >
          <Input.Password prefix={<LockOutlined className="text-primary"/>}/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {intl.formatMessage({id: "login"})}
          </Button>
        </Form.Item>
        {/*{*/}
        {/*	otherSignIn ? renderOtherSignIn : null*/}
        {/*}*/}
        {extra}
      </Form>
    </>
  )
}

LoginForm.propTypes = {
  otherSignIn: PropTypes.bool,
  showForgetPassword: PropTypes.bool,
  extra: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
};

LoginForm.defaultProps = {
  otherSignIn: true,
  showForgetPassword: false
};

const mapStateToProps = ({auth}) => {
  const {loading, message, showMessage, token, redirect} = auth;
  return {loading, message, showMessage, token, redirect}
}

const mapDispatchToProps = {
  signIn,
  showAuthMessage,
  showLoading,
  hideAuthMessage,
  signInWithGoogle,
  signInWithFacebook
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
