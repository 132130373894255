import React, {useEffect, useState} from "react";
import {Tag, Tooltip, Button, Popconfirm} from "antd";
import {DeleteOutlined} from "@ant-design/icons";
import {labels} from "../Labels";
import {useSelector} from "react-redux";
import RemoteTypeFormInDrawer from "../../components/uplink-components/RemoteTypeFormInDrawer";
import {store} from "../../App";
import {filterBadgeCounter, toggleHeaderDatepicker} from "../../redux/actions";
import DataTableView from "../../components/uplink-components/DataTableView";
import {pagination} from "../../utils/helper";
import IntlMessage from "../../components/util-components/IntlMessage";
import util from "../../utils";
import * as api from 'services';

function RemoteType(props) {
  const [remoteTypeList, setRemoteTypeList] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const wsData = useSelector((state) => state.ws);
  const [totalCount, setTotalCount] = React.useState(0);
  const onSearch = async (searchInfo) => {
    const tableInfo = {...pagination, page: currentPage, ...searchInfo};
    setTimeout(() => {
      api.getRemoteTypeList(tableInfo);
    }, 0);
  };

  React.useEffect(() => {
    pagination["searchWord"] = "";
    onSearch().catch();
    store.dispatch(toggleHeaderDatepicker(false));
    store.dispatch(filterBadgeCounter(0));
  }, []);

  React.useEffect(() => {
    setRemoteTypeList(wsData.remoteTypeList);
    setTotalCount(wsData.remoteTypeTotal);
  }, [wsData]);

  const getCurrentPage = (page) => {
    setCurrentPage(page);
  };
  //deviceTypeId
  const deleteDeviceType = async (elm) => {
    await api
      .remoteTypeListCUD({remoteTypeId: elm["remote_type_id"], isDel: true})
      .then((res) => {
        onSearch({page: currentPage}).catch();
      });
  };

  const tableColumns = [
    {
      title: <IntlMessage id={'create_date'}/>,
      dataIndex: "created",
      width: 200,
      sorter: true,
      render: (_, record) => (
        <div className="d-flex">
          {util.formatDTS(record['created'])}
        </div>
      ),
    },
    {
      title: <IntlMessage id={"name"}/>,
      dataIndex: "remote_type_name",
      width: 150,
      sorter: true,
    },
    {
      title: <IntlMessage id={"remark"}/>,
      dataIndex: "remark",
      width: 200,
      sorter: true,

    },
    {
      title: <IntlMessage id={'activate'}/>,
      dataIndex: "isactivated",
      width: 200,
      render: (status) => (
        <Tag
          className="text-capitalize"
          color={status === "Y" || status === true ? "cyan" : "red"}
        >
          {status === "Y" || status === true ? labels.ACTIVATE : labels.DEACTIVATE}
        </Tag>
      ),
      sorter: true,
    },
    {
      title: <IntlMessage id={"UploadedImage"}/>,
      dataIndex: "image_list",
      width: 200,
      render: (status) => {

        if (status !== null) {
          let arr = status.map((item) => {
            return " " + item.command + ": " + item.value
          });

          return arr.toString();
        }
      },
    },
    {
      title: "",
      dataIndex: "actions",
      width: 100,
      render: (_, elm) => (
        <div
          className="text-right d-flex justify-content-end"
          style={{height: "5px", alignItems: "center"}}
        >
          <Tooltip title="View">
            <RemoteTypeFormInDrawer
              elm={elm}
              onSearch={onSearch}
              title={labels.REMOTE_TYPE_EDIT}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm
              placement="leftTop"
              title={<IntlMessage id={"DELETE_TITLE"}/>}
              onConfirm={() => {
                deleteDeviceType(elm).catch();
              }}
              okText={<IntlMessage id={"YES"}/>}
              cancelText={<IntlMessage id={"NO"}/>}
            >
              <Button danger icon={<DeleteOutlined/>} size="small"/>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    }
  ];
  return (
    <div>
      <DataTableView
        columns={tableColumns}
        dataSource={remoteTypeList}
        totalCount={totalCount}
        rowKey="remote_type_id"
        onSearch={onSearch}
        getCurrentPage={getCurrentPage}
        showSearchWord={true}
        title={labels.REMOTE_TYPE_ADD}
      />
    </div>
  );
}

export default RemoteType;
