import axios from 'axios';
import {labels} from '../views/Labels';
import utils from '../utils';
import {env} from 'configs/EnvironmentConfig'

class API {
  static async sendList(path, reqData, doNotShowError) {
    const res = await API.send(path, reqData, doNotShowError);
    return res ?? {data: null, totalCount: 0};
  }

  static async send(path, reqData, doNotShowError) {
    try {
      //process.env.NODE_ENV !== 'production' && console.log('[API] REQ', path, reqData);
      const res = await axios.request({
        url: `${env.API_ENDPOINT_URL}/${path}`,
        method: 'POST',
        data: reqData.data ? reqData : {...reqData, data: {}},
        headers: {
          // Authorization: `bearer ${utils.getToken()}`,
        }
      });
      process.env.NODE_ENV !== 'production' && console.log('[API]', path, reqData, res);

      const {error_msg: message, data, total_count: totalCount = 0} = res.data;

      if (!message || typeof message === 'number') {
        return {data, totalCount};
      } else {
        // const msg = message.toLowerCase();
        if (message === labels.ERROR999 || message === labels.LOGIN_ERROR) {
          // sessionStorage.clear();
          await utils.logout();
          document.location.href = '/admin/login';
        } else {
          if (message) {
            utils.showError(message);
            return console.log('[ERROR] : ', message);
          }
        }
      }
    } catch (error) {
      if (error?.isAxiosError && error?.response?.status === 401) {
        document.location.href = '/admin/login';
      } else {
        if (doNotShowError !== true) {
          utils.showError(error);
        }
      }
    }
  }
}

export default API;
