import React from 'react';
import {Button, Col, Drawer, Form, Input, Row, Select, Switch} from 'antd';
import {PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {labels} from '../../views/Labels';
import SelectInDrawer, {SELECT_IN_DRAWER_DATA_MAP} from 'components/uplink-components/SelectInDrawer';
import {scopeNaNOptions, scopeOptions} from '../../utils/helper';
import {useSelector} from 'react-redux';
import util from '../../utils';
import utils from '../../utils';
import {useIntl} from 'react-intl';
import IntlMessage from '../../components/util-components/IntlMessage';
import * as api from 'services';

const {Option} = Select;

const SmartRoutineJtriggerFormInDrawer = ({title, elm, elmIndex, getJtriggerData, houseId}) => {
  const branchId = useSelector(state => state.user?.branchId);
  const deviceId = React.useRef();
  const deviceTypeId = React.useRef();
  const [isactivated, setIsactivated] = React.useState(false);
  const [sensorTypeOptions, setSensorTypeOptions] = React.useState([]);
  const [sensorValue, setSensorValue] = React.useState();
  const deviceTypeList = useSelector(state => state.ws?.deviceTypeList);
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();
  const intl = useIntl();

  React.useEffect(() => {
    const jsensor = deviceTypeList[0]?.jsensor || [];
    const sensorTypeOptions = jsensor.map((data) => {
      return ({
          label: data.sensorName,
          value: data.sensorType
        }
      );
    })
    setSensorTypeOptions(sensorTypeOptions);
  }, [deviceTypeList])

  const onSearchSensorType = (id) => {
    return api.getDeviceTypeList({branchId, deviceTypeId: id});
  };

  const showDrawer = async (elm) => {
    if (!houseId) {
      return utils.showWarning(intl.formatMessage({id: 'select_house'}));
    }
    setVisible(true);
    resetFields();
    if (elm) {
      if (elm.deviceTypeId) {
        await onSearchSensorType(elm.deviceTypeId).catch();
      }
      deviceId.current = elm?.deviceId;
      deviceTypeId.current = elm?.deviceTypeId;
      setIsactivated(elm?.isactivated);
      setSensorValue(elm?.value)
      form.setFieldsValue({
        deviceName: elm?.deviceName,
        sensorType: elm?.sensorType,
        value: elm?.value,
        scope: elm?.scope,
        fromTime: elm?.fromTime,
        toTime: elm?.toTime,
      });
    }
  };

  const resetFields = () => {
    form.resetFields();
    deviceId.current = undefined;
    deviceTypeId.current = undefined;
    setIsactivated(false);
    setSensorTypeOptions([]);
  }

  const onClose = () => {
    setVisible(false);
    resetFields();
  };

  const getSensorNameWithSensorType = (sensorType) => {
    if (sensorType === undefined) {
      return undefined;
    }
    const jsensor = deviceTypeList[0]?.jsensor || [];
    return jsensor.find((data) => data.sensorType === sensorType)?.sensorName;
  }

  const onFinish = (values) => {
    if (values.deviceName || values.fromTime) {
      const value = values?.value;
      const fromTime = values.fromTime ? parseInt(values.fromTime) : undefined;
      const toTime = values.toTime ? parseInt(values.toTime) : undefined;
      const dataInfo = {
        deviceId: deviceId.current,
        deviceTypeId: deviceTypeId.current,
        deviceName: values?.deviceName,
        sensorType: values?.sensorType,
        sensorName: getSensorNameWithSensorType(values?.sensorType),
        scope: values?.scope,
        value,
        fromTime: fromTime || 0,
        toTime: toTime || 0,
        isactivated,
      };
      setVisible(false);
      getJtriggerData(dataInfo, elmIndex);
      resetFields();
    } else {
      return util.showWarning(intl.formatMessage({id: 'write_deviceN_fromtime'}));
    }
  };

  const getSelectedDeviceData = (selectedDevice) => {
    const selectedDeviceId = selectedDevice?.device_id;

    if (deviceId.current !== selectedDeviceId) {
      const selectedDeviceName = selectedDevice?.device_name;
      const selectedDeviceTypeId = selectedDevice?.device_type_id;

      deviceId.current = selectedDeviceId;
      deviceTypeId.current = selectedDeviceTypeId;
      setIsactivated(false);
      form.resetFields(['sensorType', 'value', 'scope', 'toTime', 'fromTime']);
      form.setFieldsValue({deviceName: selectedDeviceName});
      onSearchSensorType(selectedDeviceTypeId).catch();
    }
  };

  const handleUserActivativeSwitch = value => {
    setIsactivated(value);
  };

  const buttonStyle = title === labels.SMART_ROUTINE_JTRIGGER_EDIT ?
    {display: 'flex', justifyContent: 'flex-end', marginRight: '8px'} :
    {display: 'flex', justifyContent: 'flex-end', marginTop: '12px', marginRight: '8px'};

  return (
    <div style={buttonStyle}>
      <Button type="primary"
              icon={title === labels.SMART_ROUTINE_JTRIGGER_EDIT ? <SettingOutlined/> :
                <PlusOutlined/>}
              onClick={() => showDrawer(elm)} size={'small'}>
        {title === labels.SMART_ROUTINE_JTRIGGER_EDIT ? undefined : intl.formatMessage({id: 'smart_routine_jtrigger_add'})}
      </Button>
      <Drawer
        title={title === labels.SMART_ROUTINE_JTRIGGER_EDIT ? <IntlMessage id={'smart_routine_jtrigger_edit'}/> :
          <IntlMessage id={'smart_routine_jtrigger_add'}/>}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={onClose}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={10}>
              <Form.Item
                name="deviceName"
                label={intl.formatMessage({id: 'device_name'})} rules={[
                {
                  required: true,
                  message: intl.formatMessage({id: 'select_device_name'})
                }
              ]}
              >
                <Input disabled={true} placeholder={intl.formatMessage({id: 'select_device_name'})}/>
              </Form.Item>
            </Col>
            <Col span={2} style={{marginTop: '29px'}}>
              <SelectInDrawer branchId={branchId} selectedRowKey={deviceId.current}
                              getSelectedRowData={getSelectedDeviceData}
                              componentDataMap={SELECT_IN_DRAWER_DATA_MAP.device}
                              houseId={houseId}/>
            </Col>
            <Col span={12}>
              <Form.Item
                name="sensorType"
                label={intl.formatMessage({id: 'sensor_type'})} rules={[
                {
                  required: true,
                  message: intl.formatMessage({id: 'select_sensor_type'})
                }
              ]}
              >
                <Select placeholder={intl.formatMessage({id: 'select_sensor_type'})}>
                  {sensorTypeOptions?.map((type, index) => {
                    return (
                      <Option key={index} value={type.value} name={type.label}>{type.label}</Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="value"
                label={intl.formatMessage({id: 'value'})}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({id: 'write_value'})
                  }
                ]}
              >
                <Input onChange={(e) => {
                  setSensorValue(e?.target?.value);
                  if (isNaN(e?.target?.value)) {
                    form.setFieldsValue({scope: 'equal',});
                  }
                }}
                       placeholder={intl.formatMessage({id: 'write_value'})}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="scope"
                label={intl.formatMessage({id: 'SCOPE'})}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({id: 'select_scope'})
                  }
                ]}
              >
                <Select disabled={isNaN(sensorValue)} placeholder={intl.formatMessage({id: 'select_scope'})}>
                  {isNaN(sensorValue) ?
                    scopeNaNOptions?.map((type, index) => {
                      return (
                        <Option key={index} value={type.value} name={type.label}>{type.label}</Option>
                      )
                    }) : scopeOptions?.map((type, index) => {
                      return (
                        <Option key={index} value={type.value} name={type.label}>{type.label}</Option>
                      )
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={6}>
              <Form.Item
                name="fromTime"
                label={intl.formatMessage({id: 'set_time_range'})}
              >
                <Input type={'number'} placeholder={intl.formatMessage({id: 'start_time'})}/>
              </Form.Item>
            </Col>
            <Col span={2} style={{marginTop: '36px', paddingLeft: '25px', paddingRight: '25px'}}>~</Col>
            <Col span={6}>
              <Form.Item
                name="toTime"
                label=" "
              >
                <Input type={'number'} placeholder={intl.formatMessage({id: 'end_time'})}/>
              </Form.Item>
            </Col>
            <Col span={6} style={{paddingLeft: 20}}>
              <Form.Item
                name="isactivated"
                label={intl.formatMessage({id: 'activate'})}
                initialValue={isactivated}
                getValueFromEvent={e => handleUserActivativeSwitch(e)}
              >
                <Switch checked={isactivated}/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="drawer-form-buttons">
            <Button
              onClick={onClose}
              style={{marginRight: 8}}
            >
              {intl.formatMessage({id: 'cancel'})}
            </Button>
            <Button htmlType="submit" type="primary">
              {title === labels.SMART_ROUTINE_JTRIGGER_EDIT ? intl.formatMessage({id: 'edit'}) : intl.formatMessage({id: 'add'})}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default SmartRoutineJtriggerFormInDrawer;
