import React from 'react'
import {store} from '../../App';
import {filterBadgeCounter, toggleHeaderDatepicker} from '../../redux/actions';
import DataTableView from '../../components/uplink-components/DataTableView';
import {pagination} from '../../utils/helper';
import util from '../../utils';
import {useSelector} from 'react-redux';
import {useIntl} from "react-intl";
import * as api from 'services';
import utils from '../../utils';
import IntlMessage from "../../components/util-components/IntlMessage";

export const Data = (props) => {
  const isTestBed = utils.isTestBed();
  const [dataList, setDataList] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [deviceTypeItemsShow, setDeviceTypeItemsShow] = React.useState([]);
  const [searchData, setSearchData] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const branchId = useSelector(state => isTestBed ? state.user?.testBedBranchId : state.user?.branchId);
  const [fromDate, setFromDate] = React.useState(util.toStartDateTS(util.getCurrentDate()));
  const [toDate, setToDate] = React.useState(util.toEndDateTS(util.getCurrentDate()));
  const deviceDataList = useSelector(state => state.ws?.deviceDataList);
  const deviceDataListTotal = useSelector(state => state.ws?.deviceDataListTotal);
  const deviceTypeItems = useSelector(state => state.ws?.deviceTypeItems);
  const [rowCount, setRowCount] = React.useState(10);
  const prevKey = React.useRef()
  const intl = useIntl();

  if (prevKey.current !== props.location.key) {
    prevKey.current = props.location.key;
    setIsRefresh(true);
  }

  const onSearch = async (searchInfo) => {
    setSearchData(searchInfo);
    const tableInfo = {...pagination, page: currentPage, branchId, rowCount, ...searchInfo};
    return await api.getDeviceDataList(tableInfo);
  };

  React.useEffect(() => {
    if (isRefresh && branchId !== undefined) {
      const fromDt = searchData?.fromDate ? searchData.fromDate : fromDate;
      const toDt = searchData?.toDate ? searchData.toDate : toDate;
      onSearch({page: 1, searchWord: searchData?.searchWord, fromDate: fromDt, toDate: toDt}).then((res) => {
        if (res) {
          setDataList(res.data?.dataList);
          setTotalCount(res.totalCount);
        }
      });
    }
    if (!isRefresh && branchId === undefined) {
      setIsRefresh(true);
    }
  }, [branchId, fromDate, toDate, rowCount, isRefresh]);

  const initialTableColumns = [
    {
      title: <IntlMessage id={'create_date'}/>,
      dataIndex: 'created',
      width: 200,
      render: (_, record) => (
        <div className="d-flex">
          {util.formatDTS(record['created'])}
        </div>
      ),
      sorter: true,
    },
    {
      title: <IntlMessage id={'serial'}/>,
      dataIndex: 'serial',
      width: 150,
      sorter: true,
    },
    {
      title: <IntlMessage id={'device_name'}/>,
      dataIndex: 'device_name',
      width: 200,
      sorter: true,
    },
  ];
  const [tableColumns, setTableColumns] = React.useState(initialTableColumns);

  const getRowCount = (rowCount) => {
    setRowCount(rowCount)
  }

  React.useEffect(() => {
    pagination['searchWord'] = '';
    store.dispatch(toggleHeaderDatepicker(false));
    store.dispatch(filterBadgeCounter(0));
  }, []);

  React.useEffect(() => {
    setDataList(deviceDataList);
    setTotalCount(deviceDataListTotal);
  }, [deviceDataList, deviceDataListTotal]);

  React.useEffect(() => {
    setDeviceTypeItemsShow(deviceTypeItems);
    addExtraTableColumn(deviceTypeItems);
  }, [deviceTypeItems])

  const getCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const getFromAndToDate = (fDate, tDate) => {
    setFromDate(fDate);
    setToDate(tDate);
  };

  const setRefresh = (data) => {
    const {isRefresh, page} = data
    setSearchData(data);
    setIsRefresh(isRefresh);
    setCurrentPage(page);
  };

  const addExtraTableColumn = (deviceTypeItems) => {
    const newColumns = deviceTypeItems?.map((item) => {
      return {
        title: `${item?.sensorName} (${item?.sensorUnit})`,
        dataIndex: item?.sensorType,
        width: 180,
        sorter: true,
      }
    }) || [];
    setTableColumns([...initialTableColumns, ...newColumns]);
  }


  return (
    <div>
      <DataTableView
        columns={tableColumns}
        dataSource={dataList}
        exportData={true}
        getRowCount={getRowCount}
        rowKey="created"
        pageSize={rowCount}
        totalCount={totalCount}
        onSearch={onSearch}
        getCurrentPage={getCurrentPage}
        columnFilterList={deviceTypeItemsShow?.map((item) => {
          return {label: item.sensorName, value: item.sensorType}
        })}
        showSearchWord={true}
        showSearchDate={true}
        isRefresh={isRefresh}
        setRefresh={setRefresh}
        showBranch={true}
        getFromAndToDate={getFromAndToDate}
        deviceTypeItems={deviceTypeItems}
      />
    </div>
  );
};

export default Data;
