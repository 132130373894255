import React from 'react';
import {Button, Card, Drawer, Input, Pagination, Space, Table} from 'antd';
import Highlighter from 'react-highlight-words';
import {SearchOutlined} from '@ant-design/icons';
import {useSelector} from 'react-redux';
import IntlMessage from '../util-components/IntlMessage';
import * as api from 'services';
import {useIntl} from 'react-intl';
import utils from '../../utils';

export const SELECT_IN_DRAWER_DATA_MAP = {
  house: {
    title: 'house_select',
    dataList: 'houseList',
    dataListTotal: 'houseListTotal',
    onSearch: api.getHouseArray,
    key: 'house_id',
    columnsMap: {house_name: 'houseName', branch_name: 'branchName'}
  },
  device: {
    title: 'device_select',
    dataList: 'deviceList',
    dataListTotal: 'deviceListTotal',
    onSearch: api.getDeviceList,
    key: 'device_id',
    columnsMap: {device_name: 'deviceName', device_type_name: 'deviceTypeName'}
  },
  testBedDevice: {
    title: 'device_select',
    dataList: 'testBedDeviceList',
    dataListTotal: 'testBedDeviceListTotal',
    onSearch: api.getTestBedDeviceList,
    key: 'device_id',
    columnsMap: {device_name: 'deviceName', device_type_name: 'deviceTypeName'},
    isTestBed: true,
  },
  remoteType: {
    title: 'remote_type_select',
    dataList: 'selectRemoteTypeList',
    dataListTotal: 'selectRemoteTypeListTotal',
    onSearch: api.getRemoteTypes,
    key: 'remote_type_id',
    columnsMap: {remote_type_name: 'remoteTypeName', remark: 'remark'}
  },
}

const SelectInDrawer = ({branchId, houseId, selectedRowKey, getSelectedRowData, componentDataMap}) => {
  const title = componentDataMap?.title;
  const isTestBed = componentDataMap?.isTestBed ?? false;
  const [searchText, setSearchText] = React.useState();
  const [searchedColumn, setSearchedColumn] = React.useState();
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const dataList = useSelector(state => isTestBed
    ? state.testBed?.[componentDataMap?.dataList]
    : state.ws?.[componentDataMap?.dataList]);
  const dataListTotal = useSelector(state => isTestBed
    ? state.testBed?.[componentDataMap?.dataListTotal]
    : state.ws?.[componentDataMap?.dataListTotal]);
  const rowCount = 10;
  const intl = useIntl();
  const [selectedKeys, setSelectedKeys] = React.useState([]);
  const onSearch = (searchInfo) => componentDataMap?.onSearch({branchId, houseId, ...searchInfo});
  const tableKeyString = componentDataMap?.key;
  const columnsMap = componentDataMap?.columnsMap;

  const showDrawer = () => {
    // 일반 디바이스 선택 드르워의 경우는 houseId필요
    if (componentDataMap?.key !== 'device_id' || isTestBed || houseId) {
      onSearch({page: 1, rowCount});
      setVisible(true);
      setSelectedRowKeys([selectedRowKey])
    } else {
      return utils.showWarning(intl.formatMessage({id: 'select_house'}));
    }
  };

  const onClose = () => {
    setVisible(false);
    setSearchText(undefined);
    setSearchedColumn(undefined);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setCurrentPage(1);
    setSelectedKeys([]);
  };

  const onSubmit = () => {
    if (selectedRows.length > 0) {
      getSelectedRowData(selectedRows[0]);
    }
    onClose();
  }

  const getDataListWithKey = () => {
    const dataListWithKey = dataList ? [...dataList] : [];
    for (let data of dataListWithKey) {
      if (typeof data[tableKeyString] === 'number') {
        data['key'] = data[tableKeyString];
      }
    }
    return dataListWithKey;
  }

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
    ],
  };

  const onChange = (value) => {
    setCurrentPage(value);
    onSearch({
      page: value,
      searchWord: searchText,
      searchName: columnsMap[searchedColumn],
      rowCount
    })
  };

  const getColumnSearchProps = (dataIndex) => {
    const columnNumberMap = {};
    Object.keys(columnsMap).forEach((key, index) => {
      columnNumberMap[key] = index;
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      onSearch({
        page: 1,
        searchWord: selectedKeys[columnNumberMap[dataIndex]],
        searchName: columnsMap[dataIndex],
        rowCount
      })
      setSearchText(selectedKeys[columnNumberMap[dataIndex]])
      setSearchedColumn(dataIndex)
    };

    const handleReset = clearFilters => {
      clearFilters();
      setSearchText(undefined);
      setSelectedKeys([]);
      onSearch({page: 1, rowCount})
    };

    return ({
      filterDropdown: ({_, __, confirm, clearFilters}) => (
        <div style={{padding: 8}}>
          <Input
            placeholder={intl.formatMessage({id: `${dataIndex}_search`})}
            value={selectedKeys[columnNumberMap[dataIndex]]}
            onChange={e => {
              const selectedKeys = [];
              if (e?.target?.value) {
                selectedKeys[columnNumberMap[dataIndex]] = e.target.value;
              }
              setSelectedKeys(selectedKeys);
            }}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{marginBottom: 8, display: 'block'}}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined/>}
              size="small"
              style={{width: 90}}
            >
              {intl.formatMessage({id: 'search'})}
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{width: 90}}>
              {intl.formatMessage({id: 'reset'})}
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({closeDropdown: false});
                setSearchText(selectedKeys[columnNumberMap[dataIndex]]);
                setSearchedColumn(dataIndex);
                onSearch({
                  page: 1,
                  searchWord: selectedKeys[columnNumberMap[dataIndex]],
                  searchName: columnsMap[dataIndex],
                  rowCount
                })
              }}
            >
              {intl.formatMessage({id: 'filter'})}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>,
      render: text =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
  }

  const columns = Object.keys(columnsMap).map((key) => {
    return {
      title: <IntlMessage id={key}/>,
      dataIndex: key,
      width: 180,
      key: key,
      ...getColumnSearchProps(key),
    }
  })

  return (
    <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
      <Button type="ghost"
              icon={<SearchOutlined/>}
              onClick={showDrawer} size={'small'}
      />
      <Drawer
        title={intl.formatMessage({id: title})}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={onClose}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Card bodyStyle={{'padding': '0px'}}>
          <Table rowSelection={rowSelection} columns={columns} dataSource={getDataListWithKey()} pagination={false}/>
        </Card>
        <div style={{paddingTop: 20, textAlign: 'right'}}>
          <Pagination current={currentPage} pageSize={rowCount} total={dataListTotal} onChange={onChange}
                      showSizeChanger={false}/>
        </div>
        <div
          style={{
            textAlign: 'center',
            marginTop: '50px'
          }}
        >
          <Button
            onClick={onClose}
            style={{marginRight: 8}}
          >
            <IntlMessage id={'cancel'}/>
          </Button>
          <Button onClick={onSubmit} type="primary">
            <IntlMessage id={'select'}/>
          </Button>
        </div>
      </Drawer>
    </div>
  );
}

export default SelectInDrawer;
