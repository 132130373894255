import React from 'react';
import {Button, Col, Drawer, Form, Input, notification, Row, Select, Tooltip} from 'antd';
import {PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {labels} from '../../Labels';
import {useSelector} from 'react-redux';
import {DEVICE_KIND_MAP, DEVICE_PROTOCOL_LIST} from '../../../constants/UplinkConstant';
import TestBedSensorTable from './TestBedSensorTable';
import TestBedActuatorTable from './TestBedActuatorTable';
import utils from '../../../utils';
import {useIntl} from 'react-intl';
import * as api from 'services';
import IntlMessage from '../../../components/util-components/IntlMessage';
import SelectInDrawer, {SELECT_IN_DRAWER_DATA_MAP} from 'components/uplink-components/SelectInDrawer';
import DeviceConnectInfoDrawer from '../../../components/uplink-components/DeviceConnectInfoDrawer';

const {Option} = Select;

const TestBedDeviceEditDrawer = ({title, deviceData, elm, elmIndex, onSearch}) => {
  const branchId = useSelector(state => state.user?.testBedBranchId);
  const [deviceKind, setDeviceKind] = React.useState();
  const [protocol, setProtocol] = React.useState();
  const [serial, setSerial] = React.useState();
  const [sensor, setSensor] = React.useState([]);
  const [actuator, setActuator] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const buttonSize = (title === labels.DEVICE_EDIT_ENG) ? 'small' : null;
  const [remoteTypeId, setRemoteTypeId] = React.useState(undefined);
  const [form] = Form.useForm();
  const intl = useIntl();
  const rowCount = 10;

  const getSelectedRemoteType = (selectedRemoteType) => {
    setRemoteTypeId(selectedRemoteType?.remote_type_id);
    form.setFieldsValue({remoteType: selectedRemoteType?.remote_type_name});
  }

  const showDrawer = (elm) => {
    setVisible(true);
    api.getRemoteTypes({rowCount}).catch();
    form.setFieldsValue({
      houseName: elm.house_name,
      protocol: elm.protocol,
      deviceTypeName: elm.device_type_name,
      deviceName: elm.device_name,
      deviceKind: elm.device_kind,
      serial: elm.serial,
      remoteTypeId: elm.remote_type_id,
      remoteType: elm.remote_type_name
    });
    setProtocol(elm.protocol);
    setSerial(elm.serial);
    setDeviceKind(elm.device_kind);
    setSensor(elm.jsensor);
    setActuator(elm.jcommand);
    setRemoteTypeId(elm.remote_type_id);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const onFinish = async (values) => {
    const matchedDeviceNameIndex = deviceData.findIndex((device) => {
      return device.device_name === values.deviceName;
    })
    if (matchedDeviceNameIndex !== -1 && matchedDeviceNameIndex !== elmIndex) {
      notification.warning({message: intl.formatMessage({id: 'error_command_type'})})
      return;
    }

    const matchedSerialNumberIndex = deviceData.findIndex((device) => {
      return device.serial === values.serial;
    })
    if (matchedSerialNumberIndex !== -1 && matchedSerialNumberIndex !== elmIndex) {
      notification.warning({message: intl.formatMessage({id: 'error_serial'})})
      return;
    }

    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    const data = {
      branchId: branchId,
      isDel: false,
      deviceId: elm.device_id,
      deviceTypeId: elm.device_type_id,
      deviceName: values.deviceName,
      deviceKind: values.deviceKind,
      apiKey: userInfo['api_key'],
      protocol: values.protocol,
      serial: values.serial,
      remoteTypeId: utils.isActuator(values.deviceKind) ? remoteTypeId : undefined,
      sensor: utils.isSensor(values.deviceKind) ? sensor : undefined,
      actuator: utils.isActuator(values.deviceKind) ? actuator : undefined,
    }
    await api.setTestBedDevice(data).then((res, errMessage) => {
      if (res) {
        onSearch();
        utils.showSuccess(intl.formatMessage({id: 'SUCCESS_MESSAGE'}));
      }
      setVisible(false);
    })
  }

  const handleDeviceKindChange = (value) => {
    setDeviceKind(value);
  }

  return (
    <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
      <Tooltip title="Edit">
        <Button type="primary"
                icon={title === labels.DEVICE_EDIT_ENG ? <SettingOutlined/> : <PlusOutlined/>}
                onClick={() => showDrawer(elm)} size={buttonSize}>
          {title === labels.DEVICE_EDIT_ENG ? undefined : title}
        </Button>
      </Tooltip>
      <Drawer
        title={intl.formatMessage({id: 'device_edit'})}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={() => onClose(elm)}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout="vertical" hideRequiredMark form={form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="houseName"
                label={intl.formatMessage({id: 'house_name'})}
              >
                <Input required={true} disabled={true} placeholder={intl.formatMessage({id: 'select_house'})}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="protocol" label={intl.formatMessage({id: 'device_protocol'})}
                         rules={[{required: true}]}>
                <Select placeholder={intl.formatMessage({id: 'select_device_protocol'})} onSelect={setProtocol}>
                  {DEVICE_PROTOCOL_LIST.map((protocol) => {
                    return <Option key={protocol} value={protocol}>{protocol}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="deviceTypeName"
                label={intl.formatMessage({id: 'device_type_name'})}
              >
                <Select disabled={true} placeholder={intl.formatMessage({id: 'select_device_type'})}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="deviceKind" label={intl.formatMessage({id: 'device_kind'})} initialValue={deviceKind}
                         rules={[{required: true}]}
              >
                <Select onChange={handleDeviceKindChange} placeholder={intl.formatMessage({id: 'select_device_kind'})}>
                  {Object.keys(DEVICE_KIND_MAP).map((kind) => {
                    return <Option key={kind} value={kind}>{intl.formatMessage({id: `${kind}`})}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="deviceName"
                label={intl.formatMessage({id: 'device_name'})}
              >
                <Input required={true} placeholder={intl.formatMessage({id: 'write_device_name'})}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="serial"
                label={intl.formatMessage({id: 'serial'})}
              >
                <Input required={true} placeholder={intl.formatMessage({id: 'write_serial'})}
                       onChange={(e) => setSerial(e?.target?.value)}/>
              </Form.Item>
            </Col>
          </Row>
          {utils.isActuator(deviceKind) &&
          <>
            <IntlMessage id={'remote_type'}/>
            <Row gutter={5} style={{marginTop: '5px'}}>
              <Col xs={10} lg={10} xl={10}>
                <Form.Item
                  style={{flexGrow: 1}}
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({id: 'select_remote_type'})
                    }
                  ]}
                  name="remoteType"
                >
                  <Input disabled={true} placeholder={intl.formatMessage({id: 'select_remote_type'})}/>
                </Form.Item>
              </Col>
              <Col xs={2} lg={2} xl={2}>
                <SelectInDrawer
                  selectedRowKey={remoteTypeId}
                  getSelectedRowData={getSelectedRemoteType}
                  componentDataMap={SELECT_IN_DRAWER_DATA_MAP.remoteType}/>
              </Col>
            </Row>
          </>
          }
          <DeviceConnectInfoDrawer protocol={protocol}
                                   serial={serial}
                                   sensors={utils.isSensor(deviceKind) ? sensor : undefined}
                                   commands={utils.isActuator(deviceKind) ? actuator : undefined}/>
          {utils.isSensor(deviceKind) &&
          <TestBedSensorTable sensorData={sensor} submittedValues={(value) => setSensor(value?.sensor)}/>}
          {utils.isActuator(deviceKind) &&
          <TestBedActuatorTable actuatorData={actuator}
                                submittedValues={(value) => setActuator(value?.actuator)}/>}
          <Form.Item className="drawer-form-buttons">
            <Button
              onClick={() => onClose(elm)}
              style={{marginRight: 8}}
            >
              {intl.formatMessage({id: 'cancel'})}
            </Button>
            <Button htmlType={'submit'} type="primary">
              {title === labels.DEVICE_EDIT_ENG ? intl.formatMessage({id: 'edit'}) : intl.formatMessage({id: 'add'})}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default TestBedDeviceEditDrawer;
