import React from 'react';

export const DEVICE_PROTOCOL_LIST = [
  'MQTT',
  'CoAP',
  'TCP',
  'HTTP'
]

export const DEVICE_PROTOCOL_MAP = {
  mqtt: 'MQTT',
  coap: 'CoAP',
  tcp: 'TCP',
  http: 'HTTP',
}

export const DEVICE_KIND_MAP = {
  sensor: 'sensor',
  actuator_or_switch: 'actuator_or_switch',
  all: 'all',
}

export const WIDGET_TYPE_OPTIONS_MAP = {
  card: 'CARD',
  graph: 'GRAPH',
  event_log: 'EVENTLOG',
  controller_status: 'CONTROLLER',
  not_working_device_list: 'NOT_WORKING_DEVICE_LIST',
}

export const X_VALUE_OPTIONS_MAP = {
  each_month: 'month',
  each_day: 'day',
  each_hour: 'hour',
}

export const GRAPH_TYPE_OPTIONS_MAP = {
  bar: 'bar',
  line: 'line'
}


export const COMPARE_VALUE_OPTIONS_MAP = {
  before_day: 'day',
  before_month: 'month',
  before_year: 'year',
}

export const GET_DEVICE_TYPE_ARRAY_API_INDEX_MAP = {
  id: 0,
  type: 1,
  kind: 2
};

export const USER_TYPE_NUMBER_MAP = {
  super_admin: 0,
  user: 1,
  sion_user: 2,
  testbed_user: 9,
}

export const BRANCH_TYPE_MAP = {
  home: 'HOME',
  testbed: 'TESTBED',
  sion: 'SION',
}

export const REGEX_ALL_CHARACTERS_EXCEPT_HANGUL = /^[-+=.,{}~?!@#$%^&*()\[\]\\/|'"`;:<>\w\s]+$/i
export const REGEX_SPECIAL_CHARACTERS = /[-+=.,{}~?!@#$%^&*()\[\]\\/|'"`;:<>]/g;

