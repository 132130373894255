import enLang from './entries/en_US';
import koLang from './entries/ko_KR';
import zhLang from './entries/zh_CN';
import frLang from './entries/fr_FR';
import jaLang from './entries/ja_JP'

const AppLocale = {
  en: enLang,
  ko: koLang,
  zh: zhLang,
  fr: frLang,
  ja: jaLang
};

export default AppLocale;
