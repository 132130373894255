const generateDataPostFuncString = (sensors, commands) => {
  // example result:
  // void post(float temperature, const char *Power)
  // {
  //   StaticJsonDocument<PACKET_SIZE> payloadJson;
  //   payloadJson["api_key"] = API_KEY;
  //   payloadJson["serial"] = DEVICE_SERIAL;
  //   payloadJson["data"]["temperature"] = temperature;
  //   payloadJson["data"]["Power"] = Power;
  //
  //   char payload[PACKET_SIZE];
  //   serializeJson(payloadJson, payload);
  //
  //   // send observtion request
  //   coap.observe();
  //   // message sent to server
  //   coap.post(payload);
  //
  //   Serial.println(F("[Message Sent]"));
  //   Serial.println(payload);
  // }

  let res = ''
  res += 'void post(';
  if (sensors !== undefined) {
    sensors.forEach((sensor, index, sensors) => {
      if (index !== sensors.length - 1) {
        res += `float ${sensor.sensorType}, `;
      } else {
        if (commands && commands?.length > 0) {
          res += `float ${sensor.sensorType}, `;
        } else {
          res += `float ${sensor.sensorType}`;
        }
      }
    })
  }
  if (commands !== undefined) {
    commands.forEach((command, index, commands) => {
      if (index !== commands.length - 1) {
        res += `const char* ${command.commandType}, `;
      } else {
        res += `const char* ${command.commandType}`;
      }
    })
  }
  res += `)\n  {\n`;
  res += `    StaticJsonDocument<PACKET_SIZE> payloadJson;
    payloadJson["api_key"] = API_KEY;
    payloadJson["serial"] = DEVICE_SERIAL;\n`

  if (sensors !== undefined) {
    sensors.forEach((sensor) => {
      res += `    payloadJson["data"]["${sensor.sensorType}"] = ${sensor.sensorType};\n`;
    })
  }
  if (commands !== undefined) {
    commands.forEach((command) => {
      res += `    payloadJson["data"]["${command.commandType}"] = ${command.commandType};\n`;
    })
  }

  res += `
    char payload[PACKET_SIZE];
    serializeJson(payloadJson, payload);

    // send observtion request
    coap.observe();
    // message sent to server
    coap.post(payload);

    Serial.println(F("[Message Sent]"));
    Serial.println(payload);        
  }`;

  return res;
}

export const coapHString = (sensors, commands) => {
  return `#ifndef UPLINK_COAP_H
#define UPLINK_COAP_H

#define PACKET_SIZE 256

#include <Arduino.h>
#include <ArduinoJson.h>
#include <WiFiClientSecure.h>

#include "coap_client.h"
#include "uplink_coap_settings.h"

struct UplinkCoap
{
  char resourcePath[100];

  CoapClient coap;

  void connect(callback messageReceived)
  {
    sprintf(resourcePath, "%s/%s%s", BASE_RESOURCE_PATH, API_KEY, DEVICE_SERIAL);

    WiFi.begin(WIFI_SSID, WIFI_PASS);
    Serial.print(F("checking wifi..."));
    while (WiFi.status() != WL_CONNECTED)
    {
      delay(500);
      Serial.print(".");
    }
    Serial.println(F("\\nWiFi connected"));
    Serial.println(F("IP address: "));
    Serial.println(WiFi.localIP());

    // setup callback function
    coap.response(messageReceived);

    // start coap client
    coap.start(COAP_SERVER, COAP_SERVER_PORT, resourcePath);
    coap.subscribe();
  }

  void loop()
  {
    delay(1000);
    coap.loop();
  }

  ${generateDataPostFuncString(sensors, commands)}
};
#endif`
}
