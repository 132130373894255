import React from 'react'
import {Card, Table} from 'antd';
import TestBedDeviceViewDrawer from '../../testbed/components/TestBedDeviceViewDrawer';
import {labels} from '../../Labels';
import utils from '../../../utils';
import DeviceViewFormInDrawer from '../../../components/uplink-components/DeviceViewFormInDrawer';
import IntlMessage from '../../../components/util-components/IntlMessage';
import {CloseOutlined} from '@ant-design/icons';
import {DEVICE_KIND_MAP} from '../../../constants/UplinkConstant';
import {APP_PREFIX_PATH} from '../../../configs/AppConfig';

export const NotWorkingDeviceTable = ({title, onDelete, dataSource}) => {
  const isTestBed = utils.isTestBed();
  const tableColumns = [
    {
      title: <IntlMessage id={'kind'}/>,
      dataIndex: 'device_kind',
      align: 'center',
      render: (_, record) => {
        const DEVICE_KIND_IMAGE_HEIGHT = 25;
        switch (record?.device_kind) {
          case DEVICE_KIND_MAP.sensor :
            return <img alt="sensor" height={DEVICE_KIND_IMAGE_HEIGHT}
                        src={`${APP_PREFIX_PATH}/img/devicekinds/sensor.svg`}/>;
          case DEVICE_KIND_MAP.actuator_or_switch:
            return <img alt="actuator" height={DEVICE_KIND_IMAGE_HEIGHT}
                        src={`${APP_PREFIX_PATH}/img/devicekinds/actuator.svg`}/>;
          case DEVICE_KIND_MAP.all :
            return <img alt="sensor_actuator" height={DEVICE_KIND_IMAGE_HEIGHT}
                        src={`${APP_PREFIX_PATH}/img/devicekinds/sensor_actuator.svg`}/>;
          default :
            return undefined;
        }
      },
      width: 50,
    },
    {
      title: <IntlMessage id={'name'}/>,
      dataIndex: 'device_name',
      width: 50,
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_, elm, index) => (
        (isTestBed) ?
          <TestBedDeviceViewDrawer title={labels.DEVICE_VIEW_ENG} elm={elm}/> :
          <DeviceViewFormInDrawer title={labels.DEVICE_VIEW} elm={elm}/>
      ),
      width: 50,
    }
  ];

  return (
    <Card title={title}
          extra={<a href="/#" className="text-gray font-size-lg" onClick={onDelete}><CloseOutlined/></a>}>
      <Table className={'w-100'} columns={tableColumns} dataSource={dataSource} rowKey="device_id" bordered
             pagination={false} size="small" scroll={{x: false, y: 272}}/>
    </Card>
  )
};

export default NotWorkingDeviceTable;
