import React from 'react';
import {Card, Col, Row} from 'antd';
import {ArrowDownOutlined, ArrowUpOutlined, CloseOutlined} from '@ant-design/icons';
import utils from '../../../utils';
import {useIntl} from 'react-intl';
import moment from 'moment';

const CardSensorData = ({title, onDelete, items}) => {
  const intl = useIntl();
  const getFormattedDate = (compare, compareDate) => {
    switch (compare) {
      case 'year':
        return moment(compareDate).format('YYYY');
      case 'month':
        return moment(compareDate).format('YYYY-MM');
      case 'day':
        return moment(compareDate).format('YYYY-MM-DD');
      default:
        return utils.formatDTS(compareDate);
    }
  }

  return (
    <Card title={title}
          extra={<a href="/#" className="text-gray font-size-lg"
                    onClick={onDelete}><CloseOutlined/></a>}>
      <Row gutter={16}>
        {items?.map((data, index) => {
          const title = data.item_name;
          const compareText = data.compare === 'year' ? intl.formatMessage({id: 'compare_to_last_year'}) : data.compare === 'month' ? intl.formatMessage({id: 'compare_to_last_month'}) : intl.formatMessage({id: 'compare_to_last_day'});
          const status = data.compare_val === null || data.current_val === null ? null : data.current_val - data.compare_val;
          return (
            <Col xs={24} sm={24} md={8} lg={8} xl={8} key={index}>
              <Card>
                {title && <h4 className="mb-0">{title}</h4>}
                <div className={`${title ? 'mt-3' : ''}`}>
                  <div>
                    <div className="d-flex align-items-center">
                      <h1 className="mb-0 font-weight-bold">{`${data.current_val} ${(data.item_unit) ?? ''}`}</h1>
                      {status ?
                        <span
                          className={`font-size-md font-weight-bold ml-3 ${status > 0 ? 'text-success' : 'text-danger'}`}>
                        {status}
                          {status > 0 ? <ArrowUpOutlined/> : <ArrowDownOutlined/>}
                      </span>
                        :
                        null}
                    </div>
                    {data?.compare_date && <div
                      className="text-gray-light mt-1">{`${compareText} (${getFormattedDate(data?.compare, data?.compare_date)})`}</div>}
                  </div>
                </div>
              </Card>
            </Col>
          );
        })}
      </Row>
    </Card>
  );
};

export default CardSensorData;
