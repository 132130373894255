import React from 'react';
import {Button, Col, Drawer, Form, Input, Row, notification} from 'antd';
import {PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {labels} from '../../Labels';
import {useIntl} from 'react-intl';
import {REGEX_ALL_CHARACTERS_EXCEPT_HANGUL, REGEX_SPECIAL_CHARACTERS} from '../../../constants/UplinkConstant';

const TestBedSensorDrawer = ({title, elm, elmIndex, sensorData, getSensorData}) => {
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();
  const intl = useIntl();

  const showDrawer = () => {
    setVisible(true);
    if (elm && title === labels.SENSOR_EDIT_ENG) {
      form.setFieldsValue({
        sensorType: elm.sensorType,
        sensorName: elm.sensorName,
        sensorUnit: elm.sensorUnit,
      });
    }
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const createSensorType = (sensorName = '') => {
    let sensorType = sensorName.trim().toLowerCase();
    sensorType = sensorType.replace(REGEX_SPECIAL_CHARACTERS, '');
    sensorType = sensorType.replace(/ /g, '_');
    return sensorType;
  }

  const onFinish = (values) => {
    const sensorType = createSensorType(values?.sensorName);
    const matchedIndex = sensorData.findIndex((sensor) => {
      return sensor.sensorType === sensorType;
    })

    if (matchedIndex !== -1 && matchedIndex !== elmIndex) {
      notification.warning({message: intl.formatMessage({id: 'error_sensor_key'})})
      return;
    }

    setVisible(false);
    getSensorData({
      sensorType: createSensorType(values.sensorName),
      sensorName: values?.sensorName,
      sensorUnit: values?.sensorUnit
    }, elmIndex);
    form.resetFields();
  }

  const buttonStyle = title === labels.SENSOR_EDIT_ENG ?
    {display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: '8px'} :
    {display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '12px', marginRight: '8px'};

  return (
    <div style={buttonStyle}>
      <Button className="text-align-center" type="primary"
              icon={title === labels.SENSOR_EDIT_ENG ? <SettingOutlined/> : <PlusOutlined/>}
              onClick={showDrawer}>
        {title === labels.SENSOR_EDIT_ENG ? undefined : intl.formatMessage({id: 'sensor_add'})}
      </Button>
      <Drawer
        title={title === labels.SENSOR_EDIT_ENG ? intl.formatMessage({id: 'SENSOR_EDIT'}) : intl.formatMessage({id: 'sensor_add'})}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={onClose}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout="vertical" hideRequiredMark onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="sensorName"
                label={intl.formatMessage({id: 'display_name'})}
                rules={[
                  {
                    required: true,
                    pattern: new RegExp(REGEX_ALL_CHARACTERS_EXCEPT_HANGUL),
                    transform: (value) => value.trim(),
                    message: intl.formatMessage({id: 'sensor_name_warning'}),
                  }
                ]}
              >
                <Input required={true} placeholder={intl.formatMessage({id: 'write_display_name'})}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="sensorUnit"
                label={intl.formatMessage({id: 'UNIT'})}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({id: 'write_unit'})
                  }
                ]}
              >
                <Input placeholder={intl.formatMessage({id: 'write_unit'})}/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="drawer-form-buttons">
            <Button
              onClick={onClose}
              style={{marginRight: 8}}
            >
              {intl.formatMessage({id: 'cancel'})}
            </Button>
            <Button type="primary" htmlType="submit">
              {title === labels.SENSOR_EDIT_ENG ? intl.formatMessage({id: 'edit'}) : intl.formatMessage({id: 'add'})}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default TestBedSensorDrawer;
