import utils from 'utils';
import {store} from '../App';
import API from './API';
import {
  ErrorLog,
  ErrorLogTotal,
  LogInLog,
  LogInLogTotal,
  EventLog,
  EventLogTotal,
} from '../redux/actions/WS';
import {
  TestBedEventLog,
  TestBedEventLogTotal
} from '../redux/actions/TestBed';

export const getLogInLog = async (data) => {
  const token = utils.getUserInfo()?.token;
  const action = 'GetLoginLogList';
  const reqData = {action, token, data};

  return API.send('log/R', reqData).then((res) => {
    store.dispatch(LogInLog(res?.data));
    store.dispatch(LogInLogTotal(res?.totalCount));
  });
}

export const getErrorLog = (data) => {
  const token = utils.getToken();
  const action = 'GetErrorLogList';
  const reqData = {action, token, data};

  return API.send('log/R', reqData).then((res) => {
    store.dispatch(ErrorLog(res?.data));
    store.dispatch(ErrorLogTotal(res?.totalCount));
  });
}

export const getEventLogList = (data) => {
  const token = utils.getToken();
  const action = 'GetEventLogList';
  const reqData = {action, token, data};

  return API.send(data?.isTestBed ? 'tbed/R' : 'log/R', reqData).then((res) => {
    if (data?.isTestBed) {
      store.dispatch(TestBedEventLog(res?.data));
      store.dispatch(TestBedEventLogTotal(res?.totalCount));
    } else {
      store.dispatch(EventLog(res?.data));
      store.dispatch(EventLogTotal(res?.totalCount));
    }
  });
}
