import React from 'react';
import {Drawer, Form, Button, Col, Row, Select, Table} from 'antd';
import {PlusOutlined, WifiOutlined} from '@ant-design/icons';
import {labels} from '../../views/Labels';
import {useSelector} from "react-redux";
import util from "../../utils";
import {typeOfTime} from "../../utils/helper";
import IntlMessage from "../util-components/IntlMessage";
import {useIntl} from "react-intl";
import * as api from 'services';

const {Option} = Select;

const DeviceViewFormInDrawer = ({title, elm, onSearch}) => {
  const [houseName, setHouseName] = React.useState();
  const [deviceName, setDeviceName] = React.useState();
  const [cmdTypeOptions, setCmdTypeOptions] = React.useState([]);
  const [cmdValueOptions, setCmdValueOptions] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [inputData, setInputData] = React.useState({isDel: false});
  const [tableData, setTableData] = React.useState([]);
  const [received, setReceived] = React.useState();
  const deviceViewList = useSelector(state => state.ws.deviceViewList);
  const deviceViewListTotal = useSelector(state => state.ws.deviceViewListTotal);
  const [sendCmdData, setSendCmdData] = React.useState({});
  const [form] = Form.useForm();
  const intl = useIntl();

  React.useEffect(() => {
    onSetJvalueTableData(deviceViewList);
  }, [deviceViewList, deviceViewListTotal]);

  React.useEffect(() => {
    if (deviceViewList) {
      setCmdTypeOptions(deviceViewList['jcommand'])
      setHouseName(deviceViewList['house_name']);
    }
    if (deviceViewList['received'] > 0) {
      setReceived(util.formatDTS(deviceViewList['received']));
    } else {
      setReceived(undefined);
    }
  }, [deviceViewList]);

  const onSetJvalueTableData = (deviceView) => {
    let jvalueTableData = [];

    const jvalue = deviceView.jvalue;
    if (jvalue) {
      for (let key in jvalue) {
        if (typeof jvalue[key] === 'object') {
          jvalueTableData.push({name: key, data: JSON.stringify(jvalue[key])});
        } else if (typeof jvalue[key] === 'boolean') {
          jvalueTableData.push({name: key, data: jvalue[key] ? 'true' : 'false'});
        } else {
          jvalueTableData.push({name: key, data: jvalue[key]})
        }
      }
    }
    setTableData(jvalueTableData);
  };

  const onSearchDeviceView = async (deviceId) => {
    await api.getDeviceViewList({deviceId});
  };

  const showDrawer = (elm) => {
    resetFields();
    setVisible(true);

    if (elm) {
      const {
        device_id: deviceId,
        device_kind: deviceKind,
        device_name: deviceName,
        device_type_id: deviceTypeId,
        device_type_name: deviceTypeName,
        house_id: houseId,
        house_name: houseName,
        isactivated,
        protocol,
        serial
      } = elm;
      setSendCmdData({...sendCmdData, deviceId: deviceId})
      onSearchDeviceView(deviceId).catch();
      setDeviceName(deviceName);
      setHouseName(houseName);
      setInputData({
        ...inputData,
        deviceId,
        deviceKind,
        deviceName,
        deviceTypeId,
        deviceTypeName,
        houseId,
        houseName,
        isactivated,
        protocol,
        serial
      });
    }
  };

  const onClose = () => {
    setVisible(false);
    resetFields();
  };

  const onSubmit = async () => {
    if (!sendCmdData.cmdType || !sendCmdData.cmdValue) {
      util.showWarning(intl.formatMessage({id: "command_and_value"}));
    } else {
      await api.setDeviceSendCmd(sendCmdData).catch()
      setTimeout(onSearch, 1000);
      onClose();
    }
  }

  const resetFields = () => {
    form.resetFields();
    setInputData(undefined);
    setSendCmdData({})
  };

  const handleInputData = e => {
    const name = e.target.id;
    const value = e.target.value;

    setInputData({...inputData, [name]: value});
  }

  const selectCmdType = (e) => {

    let cmd = cmdTypeOptions;

    for (let key of cmd) {
      if (key['commandType'] === e) {
        setCmdValueOptions(key['commandValues'])
      }
    }

    setSendCmdData({...sendCmdData, cmdType: e})
  }

  const selectCmdValue = (e) => {
    let valuesName = '';

    for (let key of cmdValueOptions) {
      if (key['value'] === e) {
        valuesName = key['name']
      }
    }

    setSendCmdData({...sendCmdData, cmdValue: e, cmdValueName: valuesName})
  }

  const tableColumns = [
    {
      title: <IntlMessage id={"name"}/>,
      dataIndex: 'name',
    },
    {
      title: <IntlMessage id={'value'}/>,
      dataIndex: 'data',
    }
  ];

  const viewStyle = elm.received > util.toTS(util.getCurrentTimeAgo(10, typeOfTime.MINUTES)) ? {} : {
    backgroundColor: 'red',
    borderColor: 'red'
  };

  return (
    <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
      <Button type="primary"
              // style={viewStyle}
              icon={title === labels.DEVICE_VIEW ? <WifiOutlined/> : <PlusOutlined/>}
              onClick={() => showDrawer(elm)} size={title === labels.DEVICE_VIEW ? 'small' : null}>
        {title === labels.DEVICE_VIEW ? undefined : title}
      </Button>
      <Drawer
        title={<IntlMessage id={"device_view"}/>}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={() => onClose(elm)}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout="vertical" hideRequiredMark form={form}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={`${houseName} (${deviceName})`}
                getValueFromEvent={e => handleInputData(e)}
              >
                <div style={{marginBottom: 10}}>
                  <div style={{marginRight: 10, fontWeight: 'bold'}}><IntlMessage id={"RECEIVING"}/>: {received}</div>
                </div>
                <Table
                  columns={tableColumns}
                  dataSource={tableData}
                  rowKey='name'
                  bordered
                  pagination={false}
                  scroll={{y: 430}}
                />
              </Form.Item>
            </Col>
          </Row>
          {cmdTypeOptions?.length > 0 ?
            <Row gutter={16}>
              <Col span={10}>
                <Form.Item
                  name="jvalue"
                  label=""
                  getValueFromEvent={e => selectCmdType(e)}
                >
                  <Select required={true} placeholder={intl.formatMessage({id: "select_command_name"})}>
                    {cmdTypeOptions?.map((type, key) => {
                      return (
                        <Option key={key} value={type.commandType} name={type.commandName}>{type.commandName}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  name="jvalue"
                  label=""
                  getValueFromEvent={e => selectCmdValue(e)}
                >
                  <Select required={true} placeholder={intl.formatMessage({id: "select_command_value"})}>
                    {cmdValueOptions?.map((type, key) => {
                      return (
                        <Option key={key} value={type.value} name={type.name}>{type.name}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={4}>
                <Button onClick={onSubmit} htmlType={"submit"} type="primary">
                  {title === labels.DEVICE_VIEW ? <IntlMessage id={"SEND"}/> : <IntlMessage id={'add'}/>}
                </Button>
              </Col>
            </Row> :
            undefined
          }
          <Form.Item className='drawer-form-buttons'>
            <Button
              onClick={() => onClose(elm)}
              style={{marginRight: 8}}
            >
              <IntlMessage id={"CLOSE"}/>
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );

}

export default DeviceViewFormInDrawer;
