import React, {useEffect, useState} from "react";
import {Drawer, Form, Button, Col, Row, Input, Switch, Select, Upload} from "antd";
import * as api from 'services';
import {EditOutlined, PlusOutlined, SettingOutlined} from "@ant-design/icons";
import {labels} from "../../views/Labels";
import utils from "../../utils";
import {pagination} from "../../utils/helper";
import {useSelector, useDispatch} from "react-redux";
import IntlMessage from "../util-components/IntlMessage";
import {useIntl} from "react-intl";
import {USER_TYPE_NUMBER_MAP} from "../../constants/UplinkConstant";
import {setLogo} from "../../redux/actions/User";

const UserFormInDrawer = ({title, elm, onSearchUser}) => {
  const {Option} = Select;
  const branchOptionList = useSelector((state) => state.ws.branchTypeOption);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isactivated, setIsactivated] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [inputData, setInputData] = React.useState({isDel: false});
  const [buttonSize, setButtonSize] = React.useState(
    title === labels.HOUSE_EDIT || title === labels.USER_EDIT || title === labels.ADMIN_EDIT ? "small" : null
  );
  const [form] = Form.useForm();
  const owner = utils.getUserInfo();
  const [branchOptions, setBranchOptions] = useState([]);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([])

  const onGetBranchOptions = async (searchInfo) => {
    const tableInfo = {...pagination, page: currentPage, ...searchInfo};
    setTimeout(() => {
      api.GetBranchOptions(tableInfo);
    }, 0);
  };

  useEffect(() => {
    let optionList = branchOptionList?.map((item) => {
      return {
        label: item[1],
        value: item[0],
      };
    });
    setBranchOptions(optionList);
  }, [branchOptionList]);

  const showDrawer = (elm) => {
    onGetBranchOptions().catch();
    resetFields();
    setVisible(true);
    if (elm) {
      const {branch_id, branch_name, cphone, email, isactivated, user_id, user_img, user_name} = elm;
      setFileList([{
        url: user_img
      }])
      setIsactivated(isactivated)
      setInputData({
        branchName: branch_name,
        email,
        userId: user_id,
        branchId: branch_id,
        userName: user_name,
        cphone,
        isactivated,
        isDel: false
      });
      form.setFieldsValue({
        branchName: branch_name,
        email,
        userId: user_id,
        branchId: branch_id,
        userName: user_name,
        cphone,
        isactivated
      });
    }
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
    setFileList([])
  };

  const onSubmit = async () => {
    const userInfo = JSON.parse(localStorage.getItem('user_info'))
    if (inputData) {
      inputData.branchId = (owner['auth'] !== USER_TYPE_NUMBER_MAP.super_admin) ? owner.branch_id : inputData.branchId;
      if (
        inputData["email"] !== undefined &&
        inputData["userName"] !== undefined &&
        inputData["cphone"] !== undefined &&
        inputData["branchId"] !== undefined
      ) {
        onClose();
        await api.userCUD(inputData).then((res) => {
          if (res) {
            if (res?.data?.id === userInfo.user_id) {
              if (res?.data?.img_url) {
                localStorage.setItem('user_info', JSON.stringify({...owner, user_img: res?.img_url}));
                dispatch(setLogo(res?.data?.img_url));
              }
            }
            onSearchUser();
            utils.showSuccess(intl.formatMessage({id: "success_save"}));
          }
        });
      } else {
        utils.showError(intl.formatMessage({id: "error_user_list"}))
      }

    }

  };

  const resetFields = () => {
    form.resetFields();
    setInputData(undefined);
  };

  const handleInputData = (e) => {
    const name = e.target.id;
    const value = e.target.value;
    setInputData({...inputData, [name]: value});

  };

  const handleSelectData = (value, name) => {
    setInputData({...inputData, [name]: value});
  };

  const handleUserActivativeSwitch = (value) => {
    setIsactivated(value);
    setInputData({...inputData, isactivated: value});
  };

  return (
    <div
      style={
        title !== labels.ADMIN_EDIT
          ? {
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "8px",
          }
          : undefined
      }
      style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}
    >
      {title === labels.ADMIN_EDIT ? (
        <span>
          <EditOutlined className="mr-3"/>
          <span className="font-weight-normal" onClick={() => showDrawer(elm)}>
            {title}
          </span>
        </span>
      ) : (
        <Button
          type="primary"
          icon={title === labels.USER_EDIT ? <SettingOutlined/> : <PlusOutlined/>}
          onClick={() => showDrawer(elm)}
          size={buttonSize}
        >
          {title === labels.USER_EDIT ? undefined : title}
        </Button>
      )}
      <Drawer
        title={title === labels.USER_EDIT ? intl.formatMessage({id: 'user_edit'}) : title}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={() => onClose(elm)}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout="vertical" hideRequiredMark form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="email" label={<IntlMessage id={"email"}/>}
                         rules={[
                           {
                             required: true,
                             message: intl.formatMessage({id: 'write_email'})
                           }
                         ]}>
                <Input onChange={handleInputData} type={"email"}
                       placeholder={intl.formatMessage({id: "write_email"})}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="passwd" label={<IntlMessage id={"password"}/>}
                         rules={[
                           {
                             required: true,
                             message: intl.formatMessage({id: 'write_password'})
                           }
                         ]}>
                <Input.Password onChange={handleInputData}
                                placeholder={intl.formatMessage({id: "write_password"})} autoComplete="new-password"/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="userName" label={<IntlMessage id={"user_name"}/>}
                         rules={[
                           {
                             required: true,
                             message: intl.formatMessage({id: 'write_user_name'})
                           }
                         ]}>
                <Input onChange={handleInputData} type={"text"}
                       placeholder={intl.formatMessage({id: "write_user_name"})}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="cphone" label={<IntlMessage id={"phone_num"}/>}
                         rules={[
                           {
                             required: true,
                             message: intl.formatMessage({id: 'write_phone'})
                           }
                         ]}>
                <Input onChange={handleInputData} type={"tel"}
                       placeholder={intl.formatMessage({id: "write_phone"})}/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="isactivated" label={<IntlMessage id={'activate'}/>}>
                <Switch onChange={handleUserActivativeSwitch} checked={isactivated}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={<IntlMessage id={"LOGO_IMG"}/>}>
                <Upload
                  listType="picture-card"
                  accept=".jpg, .png"
                  fileList={fileList}
                  onChange={({fileList}) => {
                    //유저가 자신이 설정해 놓은 이미지를 삭제한뒤 수정을 누른 경우 (디폴트 uplink 로고가 보여져야됨)
                    if (fileList.length === 0) {
                      setInputData({...inputData, userImage: 'DEL'});
                    }
                    setFileList(fileList)
                  }}
                  beforeUpload={(file) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                      const img = e.target.result;
                      const editedImgDataURL = img?.split("base64,");
                      setInputData({...inputData, userImage: editedImgDataURL[1]});
                    };
                    reader.readAsDataURL(file);
                    return false;
                  }}
                >
                  {fileList?.length >= 1 ? null : <div>
                    <PlusOutlined/>
                    <div style={{marginTop: 8}}>Upload</div>
                  </div>}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          {owner['auth'] === 0 &&
          <Row>
            <Col>
              <Form.Item name="branchId" label={<IntlMessage id={"branchList"}/>}
                         rules={[
                           {
                             required: true,
                             message: intl.formatMessage({id: 'select_branch_name'})
                           }
                         ]}>
                <Select onChange={(e) => handleSelectData(e, "branchId")}
                        placeholder={intl.formatMessage({id: "select_branch_name"})}
                >
                  {branchOptions?.map((type, index) => {
                    return (
                      <Option value={type.value} name={type.label} key={index}>{type.label}</Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>}
          <Form.Item className='drawer-form-buttons'>
            <Button onClick={() => onClose(elm)} style={{marginRight: 8}}> <IntlMessage id={"cancel"}/></Button>
            <Button onClick={onSubmit} htmlType={"submit"} type="primary">
              {title === labels.USER_EDIT || title === labels.ADMIN_EDIT ? <IntlMessage id={'edit'}/> :
                <IntlMessage id={'add'}/>}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default UserFormInDrawer;
