export const coapSettingHString = (apiKey, deviceSerial, baseResourcePath) => {
  return `#ifndef UPLINK_COAP_SETTINGS_H
#define UPLINK_COAP_SETTINGS_H

#define WIFI_SSID "YOUR_WIFI_SSID"
#define WIFI_PASS "YOUR_WIFI_PASSWORD"

#define API_KEY "${apiKey}"
#define DEVICE_SERIAL "${deviceSerial}"

#define COAP_SERVER "iotdevice.uplink.plus"
#define BASE_RESOURCE_PATH "${baseResourcePath}"
#define COAP_SERVER_PORT 5683

#endif`
}
