import React, {useEffect, useState} from "react";
import {
  Drawer,
  Form,
  Button,
  Col,
  Row,
  Input,
  Switch,
  Table,
  Tooltip,
  Popconfirm,
  Select,
  Upload,
  message,
  Dragger,
} from "antd";
import * as api from 'services';
import {DeleteOutlined, PlusOutlined, SettingOutlined} from "@ant-design/icons";
import {labels} from "../../views/Labels";
import util from "../../utils";
import {formatCountdown} from "antd/lib/statistic/utils";
import {pagination} from "../../utils/helper";
import {useSelector} from "react-redux";
import IntlMessage from "../util-components/IntlMessage";
import {useIntl} from "react-intl";
import utils from "../../utils";

function RemoteTypeFormInDrawer({title, elm, onSearch}) {
  const {Option} = Select;
  const commandTypeList = useSelector((state) => state.ws.remoteCommandTypeList);
  //Drawer 실행을 위한 state
  const [visible, setVisible] = React.useState(false);
  //Drawer form에 대한 값
  const [form] = Form.useForm();
  const [commandOption, setCommandOption] = useState([]);
  const intl = useIntl();
  //유저로 부터 받아온 값
  const [inputData, setInputData] = React.useState({isDel: false});
  const [isactivated, setIsactivated] = useState(false);
  const [command, setCommand] = useState(undefined);
  const [commandIndex, setCommandIndex] = useState(undefined)
  const [remoteValue, setRemoteValue] = useState(undefined);
  const [imageList, setImageList] = useState([]);
  //업로드 버튼 disable을 위한 state
  const [disable, setDisable] = useState(true)

  const onSearchCommandList = async () => {
    setTimeout(() => {
      api.getRemoteCommandList();
    }, 0);
  };

  useEffect(() => {

    let comTypeList = commandTypeList?.map((item) => {
      return {
        label: item.command_name,
        value: item.command_name,
      };
    });
    setCommandOption(comTypeList);
  }, [commandTypeList]);

  const onRemoteTypeListCUD = async (searchInfo) => {
    return await api.remoteTypeListCUD(searchInfo);
  };

  //Drawer 관련 함수
  const showDrawer = (elm) => {
    setVisible(true);
    onSearchCommandList().catch();
    if (elm) {
      const {image_list: imageList, isactivated, remark, remote_type_id: remoteTypeId, remote_type_name: remoteTypeName} = elm
      setImageList(imageList)
      setIsactivated(isactivated);
      setInputData({
        ...inputData,
        remoteTypeName,
        remark,
        imageList,
        isactivated,
        remoteTypeId
      })
      form.setFieldsValue({remoteTypeName, remark});
    }
  };

  //유저로 부터 값을 받아옴
  const handleInputData = e => {
    const name = e.target.id;
    const value = e.target.value;
    setInputData({...inputData, [name]: value});
  };
  const handleUserActivativeSwitch = value => {
    setIsactivated(value);
    const name = 'isactivated';
    setInputData({...inputData, [name]: value});
  }
  const getCommand = (index, data) => {

    setCommand(data.name);
    setCommandIndex(index)
    form.resetFields(['remoteValue']);
  };
  const getValue = (value) => {

    if (imageList.length > 0) {

      let arr = imageList?.filter((elm) => {
        return (elm.command.toUpperCase() === command.toUpperCase() && elm.value.toUpperCase() === value.toUpperCase())
      })

      if (arr?.length > 0) {
        util.showWarning(intl.formatMessage({id: "duplicate_command_command_value"}))
        setDisable(true)

      } else {
        setRemoteValue(value);
        setDisable(false)
      }

    } else {
      setDisable(false)
      setRemoteValue(value);
    }


  };
  const inputList = () => {

    if (!command && !remoteValue) {
      util.showWarning(intl.formatMessage({id: "command_and_value"}))
    } else {
      setDisable(true)
    }


  };
  const onSubmit = () => {

    let imageListEdit = imageList.map((elm) => {
      if (elm.file) {
        const editedImgDataURL = elm.file.split("base64,");
        return {
          command: elm.command,
          value: elm.value,
          path: elm.path,
          file: editedImgDataURL[1]
        }
      } else {
        return elm
      }
    })

    onRemoteTypeListCUD({
      ...inputData,
      isDel: false,
      imageList: imageListEdit
    }).then((res) => {
      if (res) {
        onSearch();
        util.showSuccess(intl.formatMessage({id: "success_save"}));
        onClose();
      }
    });

  };

  const onClose = () => {
    resetFields();
    setVisible(false);
  };

  const resetFields = () => {
    form.resetFields();
    setInputData(undefined)
    setIsactivated(false);
    setCommand(undefined)
    setCommandIndex(undefined)
    setImageList([]);
  };

  const deleteCommand = (index) => {
    const newListArr = [...imageList]
    newListArr.splice(index, 1)

    setImageList([...newListArr]);
  };

  const commandTableColumns = [
    {
      title: intl.formatMessage({id: 'command'}),
      dataIndex: "command",
      width: 150,
    },
    {
      title: intl.formatMessage({id: 'value'}),
      dataIndex: "value",
      width: 150,
    },
    {
      title: intl.formatMessage({id: 'UploadedImage'}),
      dataIndex: "path",
      width: 150,
      render: ((img, columns) => {

        if (!columns.file) {
          return <img src={img} alt="img" style={{height: "50px"}}/>
        } else {
          return <img src={columns.file} alt="img" style={{height: "50px"}}/>
        }
      })
    },
    {
      title: "",
      dataIndex: "actions",
      width: 120,
      render: (_, elm, index) => (
        <div className="text-right d-flex justify-content-end" style={{height: "5px", alignItems: "center"}}>
          <Tooltip title="Delete">
            <Popconfirm
              placement="leftTop"
              title={<IntlMessage id={"DELETE_TITLE"}/>}
              onConfirm={() => {
                deleteCommand(index);
              }}
              okText={<IntlMessage id={"YES"}/>}
              cancelText={<IntlMessage id={"NO"}/>}
            >
              <Button danger icon={<DeleteOutlined/>} size="small"/>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];


  return (
    <div style={{display: "flex", justifyContent: "flex-end", marginRight: "8px"}}>
      <Button
        type="primary"
        icon={title === labels.REMOTE_TYPE_EDIT ? <SettingOutlined/> : <PlusOutlined/>}
        onClick={() => showDrawer(elm)}
        size={title === labels.REMOTE_TYPE_EDIT ? "small" : null}
      >
        {title === labels.REMOTE_TYPE_EDIT ? undefined : intl.formatMessage({id: 'remote_type_add'})}
      </Button>
      <Drawer
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        visible={visible}
        onClose={() => onClose(elm)}
        bodyStyle={{paddingBottom: 80}}
        title={title === labels.REMOTE_TYPE_EDIT ? intl.formatMessage({id: 'remote_type_edit'}) : intl.formatMessage({id: 'remote_type_add'})}
      >
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="remoteTypeName" label={<IntlMessage id={"typeName"}/>}
                         rules={[
                           {
                             required: true,
                             message: intl.formatMessage({id: 'write_type_name'})
                           }
                         ]}>
                <Input
                  placeholder={intl.formatMessage({id: "write_type_name"})}
                  onChange={handleInputData}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="remark" label={<IntlMessage id={"remark"}/>}
                         rules={[
                           {
                             required: true,
                             message: intl.formatMessage({id: 'write_remark'})
                           }
                         ]}>
                <Input
                  placeholder={intl.formatMessage({id: "write_remark"})}
                  onChange={handleInputData}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="isactivated" label={<IntlMessage id={'activate'}/>}>
                <Switch onChange={handleUserActivativeSwitch} checked={isactivated}/>
              </Form.Item>
            </Col>
          </Row>
          {/* 취소 추가 위의 이미지 업로드 버튼 옵션 */}
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="remoteImg" label={<IntlMessage id={"UploadedImage"}/>}>
                <Table
                  columns={commandTableColumns}
                  dataSource={imageList}
                  rowKey={(e) => `${e.command}${e.value}`}
                  bordered
                  pagination={false}
                  scroll={{x: "max-content", y: 210}}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col
            style={{
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Form.Item name="command">
              <Select placeholder={<IntlMessage id={"command"}/>} onChange={(index, data) => getCommand(index, data)}
                      style={{width: 120}}>
                {commandOption?.map((type, index) => {
                  return (
                    <Option value={index} name={type.label} key={index}>
                      {type.label}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item name="remoteValue">
              <Select placeholder={<IntlMessage id={"value"}/>} onChange={getValue} style={{width: 120}}>
                {commandTypeList?.map((item, index) => {

                  if (index === commandIndex) {
                    return item.values.map((element, key) => {
                      return (
                        <Option value={element} key={key}>
                          {element}
                        </Option>
                      );
                    });
                  }

                })}
              </Select>
            </Form.Item>
            <Upload
              accept=".jpg, .png"
              showUploadList={false}
              disabled={disable}
              beforeUpload={(file) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                  const imgDataURL = e.target.result;

                  setImageList([
                    ...imageList,
                    {
                      command,
                      value: remoteValue,
                      path: file.name,
                      file: imgDataURL,
                    },
                  ]);
                  form.resetFields(['command', 'remoteValue']);
                  setCommand(undefined)
                  setCommandIndex(undefined)
                  setRemoteValue(undefined)
                };
                reader.readAsDataURL(file);
                return false;

              }}
            >
              {" "}
              <Button type="primary" onClick={inputList}>
                <IntlMessage id={"ADD LIST"}/>
              </Button>
            </Upload>
          </Col>

          <Form.Item
            style={{
              textAlign: "center",
              marginTop: "13px",
            }}
          >
            <Button style={{marginRight: 8}} onClick={onClose}>
              <IntlMessage id={"cancel"}/>
            </Button>
            <Button htmlType={"submit"} type="primary">
              {title === labels.REMOTE_TYPE_EDIT ? <IntlMessage id={'edit'}/> : <IntlMessage id={'add'}/>}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default RemoteTypeFormInDrawer;
