import React, {useRef, useEffect} from 'react'
import PropTypes from 'prop-types'
import {Card} from 'antd';
import ApexChart from 'react-apexcharts';
import {
  apexLineChartDefaultOption,
  apexBarChartDefaultOption,
  apexAreaChartDefaultOption
} from 'constants/ChartConstant';
import ReactResizeDetector from 'react-resize-detector';
import {DIR_RTL} from 'constants/ThemeConstant';
import {CloseOutlined} from '@ant-design/icons';
import moment from 'moment';
import utils from '../../../utils';
import {useIntl} from 'react-intl';

const getChartTypeDefaultOption = type => {
  switch (type) {
    case 'line':
      return apexLineChartDefaultOption
    case 'bar':
      return apexBarChartDefaultOption
    case 'area':
      return apexAreaChartDefaultOption
    default:
      return apexLineChartDefaultOption;
  }
}

const ChartSensorData = ({title, onDelete, items}) => {

  const {series, width, height, customOptions, graphType: type, direction, xValueStr} = items;
  let options = getChartTypeDefaultOption(type);
  const isMobile = window.innerWidth < 768;
  const intl = useIntl();

  const getFormattedDate = (xValueStr, timeStamp) => {
    if (typeof timeStamp === 'string') {
      return timeStamp;
    }
    switch (xValueStr) {
      case 'month':
        return moment(timeStamp).format('MM');
      case 'day':
        return moment(timeStamp).format('DD');
      case 'hour':
        return moment(timeStamp).format('HH');
      default:
        return utils.formatDTS(timeStamp);
    }
  }
  const xAxis = items?.xAxis?.map((timeStamp) => getFormattedDate(items?.xValueStr, timeStamp))
  const setLegendOffset = () => {
    if (chartRef.current) {
      const lengend = chartRef.current.querySelectorAll('div.apexcharts-legend')[0]
      lengend.style.marginRight = `${isMobile ? 0 : 100}px`
      lengend.style.paddingLeft = `${isMobile ? 0 : 100}px`
      if (direction === DIR_RTL) {
        lengend.style.right = 'auto'
        lengend.style.left = '0'
      }
      if (isMobile) {
        lengend.style.position = 'relative'
        lengend.style.top = 0
        lengend.style.justifyContent = 'start';
        lengend.style.padding = 0;
      }
    }
  };

  useEffect(() => {
    setLegendOffset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chartRef = useRef();
  options = {
    ...options,
    xaxis: {categories: xAxis, title: {text: intl.formatMessage({id: xValueStr ?? ' '}), style: {fontSize: '14px'}}}
  }
  if (customOptions) {
    options = {...options, ...customOptions}
  }

  const onResize = () => {
    setTimeout(() => {
      setLegendOffset()
    }, 600);
  };

  const renderChart = () => (
    <ReactResizeDetector onResize={onResize()}>
      <div style={direction === DIR_RTL ? {direction: 'ltr'} : {}} className="chartRef" ref={chartRef}>
        <ApexChart className="adsasd" options={options} type={type} series={series} width={width} height={height}/>
      </div>
    </ReactResizeDetector>
  );

  return (
    <Card title={title} extra={<a href="/#" className="text-gray font-size-lg" onClick={onDelete}><CloseOutlined/></a>}>
      {renderChart()}
    </Card>
  )
}

ChartSensorData.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]),
  series: PropTypes.array.isRequired,
  xAxis: PropTypes.array,
  customOptions: PropTypes.object,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  card: PropTypes.bool,
  type: PropTypes.string,
  extra: PropTypes.element,
  bodyClass: PropTypes.string
}

ChartSensorData.defaultProps = {
  series: [],
  height: 300,
  width: '100%',
  card: true,
  type: 'line'
};

export default ChartSensorData;
