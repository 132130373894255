import React from 'react';
import {Button} from 'antd';
import {useIntl} from 'react-intl';
import {mqttSettingHString} from 'assets/template/uplink_mqtt_settings_h';
import {mqttHString} from 'assets/template/uplink_mqtt_h';
import {DEVICE_PROTOCOL_MAP} from '../../constants/UplinkConstant';
import {coapClientHString} from '../../assets/template/coap_client';
import {coapHString} from '../../assets/template/uplink_coap_h';
import {coapSettingHString} from '../../assets/template/uplink_coap_settings_h';
import utils from '../../utils';

const DeviceConnectCodeDownButton = ({protocol, apiKey, serial, sensors, commands}) => {
  const intl = useIntl();

  const onDownloadButtonHandle = () => {
    let basePath = utils.isTestBed() ? "testbed" : "uplinkplus";
    switch (protocol) {
      case DEVICE_PROTOCOL_MAP.mqtt:
        download('uplink_mqtt.h', mqttHString(sensors, commands));
        download('uplink_mqtt_settings.h', mqttSettingHString(apiKey, serial, basePath));
        break;
      case DEVICE_PROTOCOL_MAP.coap:
        download('coap_client.h', coapClientHString());
        download('uplink_coap.h', coapHString(sensors, commands));
        download('uplink_coap_settings.h', coapSettingHString(apiKey, serial, basePath));
        break;
      default:
        break;
    }
  }

  const download = (filename, text) => {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  return (
    <Button type={'primary'} onClick={() => {
      onDownloadButtonHandle()
    }}>
      {intl.formatMessage({id: 'library_download'})}
    </Button>
  );
}

export default DeviceConnectCodeDownButton;
