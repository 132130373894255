import React from "react";
import {Menu, Dropdown, Tag} from "antd";
import {
	SettingOutlined,
	ShopOutlined,
	QuestionCircleOutlined,
	LogoutOutlined
} from '@ant-design/icons';
import {labels} from "../../views/Labels";
import UserFormInDrawer from "../uplink-components/UserFormInDrawer";
import IntlMessage from "../util-components/IntlMessage";
import utils from 'utils';

const menuItem = [
	// {
	// 	title: "Edit Profile",
	// 	icon: EditOutlined ,
	// 	path: "/"
	//   },

	{
		title: "Account Setting",
		icon: SettingOutlined,
		path: "/"
	},
	{
		title: "Billing",
		icon: ShopOutlined,
		path: "/"
	},
	{
		title: "Help Center",
		icon: QuestionCircleOutlined,
		path: "/"
	}
]

export const NavProfile = () => {
	const owner = JSON.parse(localStorage.getItem('user_info'))
	// React.useEffect(() => {
	// 	api.wsGetUserData();
	// }, [])

	const profileMenu = (
		<div className="nav-profile nav-dropdown">
			<div className="nav-profile-header">
				<div className="d-flex">
					{/*<Avatar size={45} src={profileImg} />*/}
					{owner && (<div className="pl-3">
						<h4 className="mb-0">{owner["user_name"]}</h4>
						<div className="text-muted" style={{marginBottom: '10px'}}>{owner["email"]}</div>
						<Tag className="text-capitalize"
						     color={owner["user_type"] === 0 ? 'gold' : 'blue'}>{owner["user_type"] === 0 ? labels.SUPER_ADMIN : labels.MANAGER}</Tag>
					</div>)}
				</div>
			</div>
			<div className="nav-profile-body">
				<Menu>
					{/*{menuItem.map((el, i) => {*/}
					{/*  return (*/}
					{/*    <Menu.Item key={i}>*/}
					{/*      <a href={el.path}>*/}
					{/*        <Icon className="mr-3" type={el.icon} />*/}
					{/*        <span className="font-weight-normal">{el.title}</span>*/}
					{/*      </a>*/}
					{/*    </Menu.Item>*/}
					{/*  );*/}
					{/*})}*/}
					<Menu.Item key={0}>
						<UserFormInDrawer title={labels.ADMIN_EDIT} elm={JSON.parse(localStorage.getItem('user_info'))}/>
						{/*<UserFormInDrawer title={labels.USER_EDIT} elm={owner}/>*/}
						{/*<span className="font-weight-normal">Account Setting</span>*/}
					</Menu.Item>
					<Menu.Item key={menuItem.length + 1} onClick={() => utils.logout()}>
            <span>
              <LogoutOutlined className="mr-3"/>
              <span className="font-weight-normal"><IntlMessage id={"logout"}/></span>
            </span>
					</Menu.Item>
				</Menu>
			</div>
		</div>
	);
	return (
		<Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
			<Menu className="d-flex align-item-center" mode="horizontal">
				<Menu.Item>
					{/*<Avatar src={profileImg} />*/}
					<SettingOutlined className="nav-icon mr-0"/>
				</Menu.Item>
			</Menu>
		</Dropdown>
	);
}

export default NavProfile;
