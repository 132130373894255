import React from 'react';
import {Button, Col, Drawer, Form, Input, Popconfirm, Radio, Row, Select, Table, Tooltip} from 'antd';
import utils from 'utils';
import {labels} from '../../Labels';
import {useDispatch, useSelector} from 'react-redux';
import {PlusOutlined, SaveOutlined} from '@ant-design/icons';
import {RecentTransactionData} from '../DefaultDashboardData';
import IntlMessage from '../../../components/util-components/IntlMessage';
import {useIntl} from 'react-intl';
import {
  COMPARE_VALUE_OPTIONS_MAP,
  GRAPH_TYPE_OPTIONS_MAP,
  USER_TYPE_NUMBER_MAP,
  WIDGET_TYPE_OPTIONS_MAP,
  X_VALUE_OPTIONS_MAP
} from 'constants/UplinkConstant';
import {setBranchId, setBranchType, setTestBedBranchId} from 'redux/actions/User';
import * as api from 'services';

const {Option} = Select;
const auth = utils.getAuth();

const DashboardFormInDrawer = ({onAdd, onSave, hasSampleData}) => {
  const isTestBed = utils.isTestBed();
  const [visible, setVisible] = React.useState(false);
  const [widgetTitle, setWidgetTitle] = React.useState();
  const [widgetType, setWidgetType] = React.useState(WIDGET_TYPE_OPTIONS_MAP.card);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [sensorTypeData, setSensorTypeData] = React.useState();
  const [controllerValue, setControllerValue] = React.useState();
  const [xValue, setXValue] = React.useState(X_VALUE_OPTIONS_MAP.each_month);
  const [xCount, setXCount] = React.useState(12);
  const [type, setType] = React.useState('line');
  const [compareValue, setCompareValue] = React.useState(COMPARE_VALUE_OPTIONS_MAP.before_day);
  const [controllerValueOptions, setControllerValueOptions] = React.useState([]);
  const branchId = useSelector(state => isTestBed ? state.user?.testBedBranchId : state.user?.branchId);
  const branchType = useSelector(state => isTestBed ? undefined : state.user?.branchType);
  const [branchOptions, setBranchOptions] = React.useState([]);
  const branchList = useSelector(state => isTestBed ? state.testBed?.testBedBranchList : state.ws?.branchList);
  const controllers = useSelector(state => state.ws?.controllers);
  const deviceTypeItems = useSelector(state =>
    (isTestBed) ? state.testBed?.testBedDeviceTypeItems : state.ws?.deviceTypeItems);
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (auth === USER_TYPE_NUMBER_MAP.super_admin) {
      onGetBranchList().catch();
    }
  }, []);

  React.useEffect(() => {
    if (controllers?.length > 0) {
      const controllerArr = [];
      controllers?.map((data) => {
        return (
          controllerArr.push({label: data[1], value: data[0]})
        );
      });
      setControllerValueOptions(controllerArr);
    }
  }, [controllers]);

  React.useEffect(() => {
    setSensorTypeData(deviceTypeItems);
  }, [deviceTypeItems])

  React.useEffect(() => {
    if (branchList?.length > 0) {
      const newBranchList = branchList.map((data) => {
        return (
          {label: data.branch_name, value: data.branch_id, type: data.branch_type}
        )
      });
      setBranchOptions(newBranchList);
      const matchedBranch = branchList.find((branch) => branch?.branch_id === branchId);
      if (matchedBranch === undefined) {
        dispatch(setBranchType(newBranchList[0]?.type))
        isTestBed
          ? dispatch(setTestBedBranchId(newBranchList[0]?.value))
          : dispatch(setBranchId(newBranchList[0]?.value))
      } else {
        dispatch(setBranchType(matchedBranch.branch_type))
      }
    }
  }, [branchList]);

  const onSearchSensorType = async () => {
    (isTestBed)
      ? await api.getTestBedDeviceTypeItems({branchId})
      : await api.getDeviceTypeItems({branchId});
  };

  const onGetControllers = async () => {
    (isTestBed)
      ? await api.getTestBedControllers({branchId})
      : await api.getControllers({branchId});
  };

  const onGetBranchList = async () => {
    await api.getBranchList(isTestBed ? {isTestbed: true} : {isTestbed: false});
  };

  const onSubmit = () => {
    if ((widgetType === WIDGET_TYPE_OPTIONS_MAP.card || widgetType === WIDGET_TYPE_OPTIONS_MAP.graph) && selectedRows.length <= 0) {
      return utils.showWarning(intl.formatMessage({id: 'select_item'}));
    }
    if (widgetType === WIDGET_TYPE_OPTIONS_MAP.controller_status && !controllerValue) {
      return utils.showWarning(intl.formatMessage({id: 'select_controller'}));
    }
    let newData = {}, paramData = {};
    switch (widgetType) {
      case WIDGET_TYPE_OPTIONS_MAP.card:
        paramData = selectedRows.map((card, index) => {
          return {cardId: index + 1, itemType: card.sensorType, itemName: card.sensorName, compare: compareValue}
        });
        newData = {key: WIDGET_TYPE_OPTIONS_MAP.card, widgetTitle: widgetTitle, items: {items: paramData}};
        break;
      case WIDGET_TYPE_OPTIONS_MAP.graph:
        const graphSensorValues = selectedRows.map((data) => {
          return [data.sensorName, data.sensorType];
        });
        paramData = {graphItems: graphSensorValues, xValueStr: xValue, xValueCount: xCount, graphType: type};
        newData = {key: WIDGET_TYPE_OPTIONS_MAP.graph, widgetTitle: widgetTitle, items: paramData};
        break;
      case WIDGET_TYPE_OPTIONS_MAP.event_log:
        newData = {
          key: WIDGET_TYPE_OPTIONS_MAP.event_log,
          widgetTitle: widgetTitle,
          items: {dataSource: RecentTransactionData}
        };
        break;
      case WIDGET_TYPE_OPTIONS_MAP.controller_status:
        paramData = {controllers: [controllerValue]};
        newData = {key: WIDGET_TYPE_OPTIONS_MAP.controller_status, widgetTitle: widgetTitle, items: paramData};
        break;
      case WIDGET_TYPE_OPTIONS_MAP.not_working_device_list:
        newData = {key: WIDGET_TYPE_OPTIONS_MAP.not_working_device_list, widgetTitle: widgetTitle};
        break;
      default:
    }
    onAdd(newData);
    onClose();
  };

  const showDrawer = () => {
    resetFields();
    onSearchSensorType().catch();
    onGetControllers(branchId).catch();
    setVisible(true);
  };

  const onClose = () => {
    resetFields();
    setVisible(false);
  };

  const resetFields = () => {
    form.resetFields();
    setWidgetTitle(undefined);
    setWidgetType(WIDGET_TYPE_OPTIONS_MAP.card);
    setSelectedRowKeys([]);
    setSelectedRows([]);
    setCompareValue(COMPARE_VALUE_OPTIONS_MAP.before_day);
    setXValue(X_VALUE_OPTIONS_MAP.each_month);
    setType(GRAPH_TYPE_OPTIONS_MAP.line)
    setXCount(12);
    setControllerValueOptions([]);
    setControllerValue(undefined);
  };

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    const TESTBED_MAX_SELECTION = 3;
    if (isTestBed && selectedRows.length > TESTBED_MAX_SELECTION) {
      utils.showWarning(intl.formatMessage(
        {id: 'testbed_sensor_value_limit_message'}, {max: TESTBED_MAX_SELECTION}));
      return;
    }
    setSelectedRows(selectedRows);
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleInputData = (e, type) => {
    switch (type) {
      case 'widgetTitle':
        setWidgetTitle(e.target.value);
        break;
      case 'xCount':
        setXCount(utils.toInt(e.target.value));
        break;
      case 'type':
        setType(e.target.value)
        break;
      default:
    }
  };

  const handleSelectData = (value, data, type) => {
    switch (type) {
      case 'widgetType':
        // if (isTestBed &&
        // (value === WIDGET_TYPE_OPTIONS_MAP.controller_status ||
        //   value === WIDGET_TYPE_OPTIONS_MAP.not_working_device_list)) {
        // utils.showWarning(intl.formatMessage({id: 'testbed_widget_type_selection_limit_message'}));
        // form.setFieldsValue({widgetType: widgetType});
        // } else {
        setWidgetType(value);
        // }
        break;
      case 'compareValue':
        setCompareValue(value);
        break;
      case 'xValue':
        setXValue(value);
        break;
      case 'controllerValue':
        setControllerValue(value);
        break;
      case 'branchId':
        const selectedBranchOption = branchOptions.find((branchOption) => branchOption.value === value);
        if (selectedBranchOption !== undefined) {
          dispatch(setBranchType(selectedBranchOption.type));
        }

        isTestBed
          ? dispatch(setTestBedBranchId(value))
          : dispatch(setBranchId(value))
        break;
      default:
    }
  };

  const widgetTableColumns = [
    {
      title: intl.formatMessage({id: 'item'}),
      dataIndex: 'sensorName',
      key: 'sensorName',
    },
  ];

  return (
    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
      {auth === USER_TYPE_NUMBER_MAP.super_admin && (
        <Select style={{marginRight:'2%'}} value={branchId} required={true} onChange={(value, data) => handleSelectData(value, data, 'branchId')}
                className="dashboard-branch-select">
          {branchOptions?.map((type) => (
            <Option key={type.value} value={type.value} name={type.label}>{type.label}</Option>
          ))}
        </Select>)}
      <Button type="primary" icon={<PlusOutlined/>} onClick={() => showDrawer()} size={'small'}>
        {intl.formatMessage({id: 'widgetAdd'})}
      </Button>
      <div style={{paddingRight: 5}}/>
      <Tooltip>
        <div style={{float: 'left'}}>
          <Popconfirm placement="leftTop" title={labels.SAVE_TITLE}
                      disabled={hasSampleData}
                      onClick={() => hasSampleData ? utils.showWarning(intl.formatMessage({id: 'cannot_save_sample_widgets'})) : {}}
                      onConfirm={onSave}
                      okText={<IntlMessage id={'YES'}/>}
                      cancelText={<IntlMessage id={'NO'}/>}>
            <Button type="primary" icon={<SaveOutlined/>} disabled={hasSampleData}
                    size={'small'}>{intl.formatMessage({id: 'save'})}</Button>
          </Popconfirm>
        </div>
      </Tooltip>
      <Drawer
        title={intl.formatMessage({id: 'widgetAdd'})}
        width={window.innerWidth > 900 ? 500 : window.innerWidth}
        onClose={() => onClose()}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout="horizontal" hideRequiredMark form={form}>
          <Row gutter={16} style={{justifyContent: 'center', alignItems: 'center', paddingBottom: 10}}>
            <Col span={24}>
              <Form.Item name="widgetTitle" label={<IntlMessage id={'title'}/>} initialValue={widgetTitle}>
                <Input onChange={e => handleInputData(e, 'widgetTitle')}
                       placeholder={intl.formatMessage({id: 'write_title'})}
                       style={{width: 300}}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16} style={{justifyContent: 'center', alignItems: 'center', paddingBottom: 20}}>
            <Col span={24}>
              <Form.Item name="widgetType" label={<IntlMessage id={'shape'}/>} initialValue={widgetType}>
                <Select value={widgetType} onChange={(value, data) => handleSelectData(value, data, 'widgetType')}
                        required={true}
                        placeholder={intl.formatMessage({id: 'select_type'})} style={{width: 300}}>
                  {Object.entries(WIDGET_TYPE_OPTIONS_MAP)?.map(([label, value]) => (
                    <Option key={String(value)} value={String(value)} name={value}><IntlMessage id={label}/></Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {(widgetType === WIDGET_TYPE_OPTIONS_MAP.card || widgetType === WIDGET_TYPE_OPTIONS_MAP.graph) && (
            <Row gutter={16} style={{paddingBottom: 30}}>
              <Col span={24}>
                <Table
                  rowSelection={rowSelection}
                  columns={widgetTableColumns}
                  rowKey={'sensorName'}
                  dataSource={sensorTypeData}
                  bordered
                  pagination={false}
                  scroll={{x: 'max-content', y: 300}}
                />
              </Col>
            </Row>)}
          {widgetType === WIDGET_TYPE_OPTIONS_MAP.card && (
            <Row gutter={16} style={{justifyContent: 'center', alignItems: 'center', paddingBottom: 10}}>
              <Col span={24}>
                <Form.Item name="compareValue" label={<IntlMessage id={'compare_value'}/>}
                           initialValue={compareValue}>
                  <Select onChange={(value, data) => handleSelectData(value, data, 'compareValue')}
                          required={true}
                          placeholder={intl.formatMessage({id: 'select_compare'})}
                          style={{width: 300}}>
                    {Object.entries(COMPARE_VALUE_OPTIONS_MAP)?.map(([label, value]) => (
                      <Option key={String(value)} value={String(value)} name={value}>
                        <IntlMessage id={label}/>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>)}
          {widgetType === WIDGET_TYPE_OPTIONS_MAP.graph && (
            <>
              <IntlMessage id={'x_value'}/>
              <Row gutter={16} style={{marginTop: '10px'}}>
                <Col span={14}>
                  <Form.Item name="xValue" initialValue={xValue}>
                    <Select onChange={(value, data) => handleSelectData(value, data, 'xValue')}
                            required={true}
                            placeholder={intl.formatMessage({id: 'select_x'})}
                    >
                      {Object.entries(X_VALUE_OPTIONS_MAP)?.map(([label, value]) => (
                        <Option key={String(value)} value={String(value)} name={value}>
                          <IntlMessage id={label}/>
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item initialValue={xCount} getValueFromEvent={e => handleInputData(e, 'xCount')}>
                    <Input defaultValue={xCount} required={true} onChange={e => handleInputData(e, 'xCount')}
                           placeholder={intl.formatMessage({id: 'select_x_num'})}
                           style={{width: 75}}/> <IntlMessage id={'count'}/>
                  </Form.Item>
                </Col>
              </Row>
              <IntlMessage id={'graph_type'}/>
              <Row style={{marginTop: '10px'}}>
                <Form.Item>
                  <Radio.Group value={type} onChange={e => handleInputData(e, 'type')}>
                    <Radio value={GRAPH_TYPE_OPTIONS_MAP.line}>{intl.formatMessage({id: 'line'})}</Radio>
                    <Radio value={GRAPH_TYPE_OPTIONS_MAP.bar}>{intl.formatMessage({id: 'bar'})}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Row>
            </>
          )}
          {widgetType === WIDGET_TYPE_OPTIONS_MAP.controller_status && (
            <Row gutter={16} style={{justifyContent: 'center', alignItems: 'center', paddingBottom: 10}}>
              <Col span={24}>
                <Form.Item name="controllerValue" label={<IntlMessage id={'CONTROLLER'}/>}
                           initialValue={controllerValue}>
                  <Select onChange={(value, data) => handleSelectData(value, data, 'controllerValue')}
                          required={true}
                          placeholder={intl.formatMessage({id: 'select_controller'})}
                          style={{width: 300}}>
                    {controllerValueOptions?.map((type) => (
                      <Option key={type.value} value={type.value} name={type.value}>{type.label}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>)}
          <Form.Item className="drawer-form-buttons">
            <Button onClick={() => onClose()} style={{marginRight: 8}}><IntlMessage id={'cancel'}/></Button>
            <Button onClick={onSubmit} htmlType={'submit'} type="primary"><IntlMessage id={'add'}/></Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default DashboardFormInDrawer;
