export const labels = {
  HOUSE_ADD: '하우스 추가',
  HOUSE_EDIT: '하우스 수정',
  HOUSE_SELECT_ADD: '하우스 선택',
  HOUSE_SELECT_EDIT: '하우스 선택',
  USER_LIST: '유저 리스트',
  USER_ADD: '유저 추가',
  USER_EDIT: '유저 수정',
  USER_SELECT: '유저 선택',
  SMART_ROUTINE_LIST: '스마트 루틴 리스트',
  SMART_ALARM_LIST: '스마트 알람 리스트',
  SMART_ROUTINE_ADD: '스마트 루틴 추가',
  SMART_ROUTINE_ADD_ENG: 'Add Smart Routine',
  SMART_ROUTINE_EDIT: '스마트 루틴 수정',
  SMART_ROUTINE_EDIT_ENG: 'Edit Smart Routine',
  SMART_ALARM_ADD: '스마트 알람 추가',
  SMART_ALARM_EDIT: '스마트 알람 수정',
  ALARM_ADD: '알람 추가',
  ALARM_EDIT: '알람 수정',
  SMART_ROUTINE_JTRIGGER_ADD: '상황 추가',
  SMART_ROUTINE_JTRIGGER_ADD_ENG: 'Add Trigger',
  SMART_ROUTINE_JTRIGGER_EDIT: '상황 수정',
  SMART_ROUTINE_JTRIGGER_EDIT_ENG: 'Edit Trigger',
  SMART_ROUTINE_JACTION_ADD: '액션 추가',
  SMART_ROUTINE_JACTION_ADD_ENG: 'Add Action',
  SMART_ROUTINE_JACTION_EDIT: '액션 수정',
  SMART_ROUTINE_JACTION_EDIT_ENG: 'Edit Action',
  BRANCH_ADD: '브랜치 추가',
  BRANCH_EDIT: '브랜치 수정',
  BRANCH_GROUP_ADD: '그룹 추가',
  BRANCH_GROUP_EDIT: '그룹 수정',
  ADMIN_EDIT: '나의 정보 수정',
  DEVICE_TYPE_ADD: '디바이스 타입 추가',
  DEVICE_TYPE_EDIT: '디바이스 타입 수정',
  DEVICE_ADD: '디바이스 추가',
  DEVICE_ADD_WIZARD: 'Add Device Wizard',
  DEVICE_EDIT: '디바이스 수정',
  DEVICE_EDIT_ENG: 'Edit Device',
  DEVICE_VIEW: '디바이스 데이터 보기',
  DEVICE_VIEW_ENG: 'View Device Data',
  DEVICE_SELECT: '디바이스 선택',
  DEVICE_SELECT_ENG: 'Select Device',
  REMOTE_TYPE_ADD: '리모트 타입 추가',
  REMOTE_TYPE_EDIT: '리모트 타입 수정',
  REMOTE_TYPE_ADD_IMG: '이미지 추가',
  SENSOR_ADD: '센싱 추가',
  SENSOR_EDIT: '센싱 수정',
  COMMAND_ADD: '명령어 추가',
  COMMAND_EDIT: '명령어 수정',
  SENSOR_ADD_ENG: 'Add Sensor',
  SENSOR_EDIT_ENG: 'Edit Sensor',
  COMMAND_ADD_ENG: 'Add Command',
  COMMAND_EDIT_ENG: 'Edit Command',
  DELETE_TITLE: '정말 삭제하시겠습니까?',
  YES: '예',
  NO: '아니오',
  SUPER_ADMIN: 'SUPER ADMIN',
  MANAGER: 'MANAGER',
  POWER_USER: 'POWER USER',
  USER: 'USER',
  SUCCESS_MESSAGE: '성공적으로 저장되었습니다.',
  SYSTEM_ERROR_MESSAGE: '시스템 에러가 발생하였습니다.',
  ERROR999: 'ERROR#999',
  ACTIVATE: 'Activate',
  DEACTIVATE: 'Deactivate',
  DID_ADD: 'DID 추가',
  DID_EDIT: 'DID 수정',
  DASHBOARD: 'DASHBOARD',
  DRAG: 'DRAG',
  ADD: 'ADD',
  DELETE: 'DELETE',
  SAVE_TITLE: '정말 저장하시겠습니까?',
  LOGIN_ERROR:'LOGIN'
}
