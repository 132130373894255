import {
  BranchesOutlined,
  ContainerOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  HomeOutlined,
  LoginOutlined,
  MobileOutlined,
  PlusCircleOutlined,
  ScheduleOutlined,
  TeamOutlined,
  ToolOutlined,
  WarningOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import {APP_PREFIX_PATH} from 'configs/AppConfig'
import {USER_TYPE_NUMBER_MAP} from '../constants/UplinkConstant';
import utils from 'utils';

const owner = utils.getUserInfo();

// const dashBoardNavTree = [{
//   key: 'dashboards',
//   path: `${APP_PREFIX_PATH}/dashboards`,
//   title: 'sidenav.dashboard',
//   icon: DashboardOutlined,
//   breadcrumb: false,
//   submenu: [
//     {
//       key: 'dashboards-default',
//       path: `${APP_PREFIX_PATH}/dashboard/default`,
//       title: 'sidenav.dashboard.default',
//       icon: DashboardOutlined,
//       breadcrumb: false,
//       submenu: []
//     },
//   ]
// }];

const listNavTree = [{
  key: 'list',
  path: `${APP_PREFIX_PATH}/list`,
  title: 'sidenav.list',
  icon: PlusCircleOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'list-dashboard',
      path: `${APP_PREFIX_PATH}/list/dashboard`,
      title: 'sidenav.list.dashboard',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'list-data',
      path: `${APP_PREFIX_PATH}/list/data`,
      title: 'sidenav.list.data',
      icon: DatabaseOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'list-deviceType',
      path: `${APP_PREFIX_PATH}/list/devicetype`,
      title: 'sidenav.list.deviceType',
      icon: ToolOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'list-device',
      path: `${APP_PREFIX_PATH}/list/device`,
      title: 'sidenav.list.device',
      icon: MobileOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'list-smartroutine',
      path: `${APP_PREFIX_PATH}/list/smartroutine`,
      title: 'sidenav.list.smartroutine',
      icon: ScheduleOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'list-user',
      path: `${APP_PREFIX_PATH}/list/user`,
      title: 'sidenav.list.user',
      icon: TeamOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'list-eventLog',
      path: `${APP_PREFIX_PATH}/list/eventLog`,
      title: 'sidenav.list.eventLog',
      icon: ContainerOutlined,
      breadcrumb: false,
      submenu: [],
    }
  ]
}];

const testBedNavTree = [{
  key: 'testbed',
  path: `${APP_PREFIX_PATH}/testbed`,
  title: 'sidenav.testbed',
  icon: PlusCircleOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'testbed-dashboard',
      path: `${APP_PREFIX_PATH}/testbed/dashboard`,
      title: 'sidenav.testbed.dashboard',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'testbed-data',
      path: `${APP_PREFIX_PATH}/testbed/data`,
      title: 'sidenav.testbed.data',
      icon: DatabaseOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'testbed-device',
      path: `${APP_PREFIX_PATH}/testbed/device`,
      title: 'sidenav.testbed.device',
      icon: MobileOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'testbed-smartroutine',
      path: `${APP_PREFIX_PATH}/testbed/smartroutine`,
      title: 'sidenav.testbed.smartroutine',
      icon: ScheduleOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'testbed-eventLog',
      path: `${APP_PREFIX_PATH}/testbed/eventLog`,
      title: 'sidenav.testbed.eventLog',
      icon: ContainerOutlined,
      breadcrumb: false,
      submenu: [],
    }
  ]
}];

const settingNavTree = [{
  key: 'setting',
  path: `${APP_PREFIX_PATH}/setting`,
  title: 'sidenav.setting',
  icon: PlusCircleOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'setting-branch',
      path: `${APP_PREFIX_PATH}/setting/branch`,
      title: 'sidenav.setting.branch',
      icon: BranchesOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'setting-house',
      path: `${APP_PREFIX_PATH}/setting/house`,
      title: 'sidenav.setting.house',
      icon: HomeOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'setting-remoteType',
      path: `${APP_PREFIX_PATH}/setting/remotetype`,
      title: 'sidenav.setting.remoteType',
      icon: WifiOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: 'setting-logInLog',
      path: `${APP_PREFIX_PATH}/setting/logInLog`,
      title: 'sidenav.setting.LogInLog',
      icon: LoginOutlined,
      breadcrumb: false,
      submenu: [],
    },
    {
      key: 'setting-errorLog',
      path: `${APP_PREFIX_PATH}/setting/errorLog`,
      title: 'sidenav.setting.ErrorLog',
      icon: WarningOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}];

let navigationConfig;
if (owner) {
  switch (owner['auth']) {
    case USER_TYPE_NUMBER_MAP.super_admin:
      navigationConfig = [
        ...listNavTree,
        ...testBedNavTree,
        ...settingNavTree,
      ]
      break;
    case USER_TYPE_NUMBER_MAP.user:
      navigationConfig = [
        ...listNavTree,
      ];
      break;
    case USER_TYPE_NUMBER_MAP.sion_user:
      navigationConfig = [
        ...listNavTree,
      ];
      break;
    case USER_TYPE_NUMBER_MAP.testbed_user:
      navigationConfig = [
        ...testBedNavTree,
      ];
      break;
    default:
      navigationConfig = [
        ...testBedNavTree,
      ]
  }
} else {
  navigationConfig = [
    ...testBedNavTree,
  ]
}

export default navigationConfig;
