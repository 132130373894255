export const VisitorChartData = {
	cardSeries: [
		{title: '온도', type: 'temperature', value: '24°C', status:'-11.4', subtitle: 'Compare to last year (2019)'},
		{title: '습도', type: 'humidity', value: '60', status:'70', subtitle: 'Compare to last year (2019)'},
		{title: 'PM 10', type: 'pm10', value: '27', status:'33', subtitle: 'Compare to last year (2019)'},
		{title: 'PM 25', type: 'pm25', value: '46', status:'45', subtitle: 'Compare to last year (2019)'},
		{title: 'CO2', type: 'co2', value: '33', status:'76', subtitle: 'Compare to last year (2019)'},
	],
	series: [
		{
			name: "PM 10",
			value: "pm10",
			data: [52, 38, 24, 33, 26, 21, 20, 6, 8, 15, 10, 45]
		},
		{
			name: "PM 25",
			value: "pm25",
			data: [41, 62, 42, 13, 18, 29, 37, 36, 51, 32, 35, 35]
		},
		{
			name: "CO2",
			value: "co2",
			data: [31, 42, 22, 23, 18, 59, 17, 26, 31, 22, 55, 65]
		},
		{
			name: "Temperature",
			value: "temperature",
			data: [31, 30, 28, 27, 29, 30, 30, 33, 29, 28, 27, 30]
		},
		{
			name: "Humidity",
			value: "humidity",
			data: [52, 50, 59, 50, 63, 65, 60, 58, 66, 65, 60, 55]
		},
	],
	categories:[
		'01 Jan',
		'02 Jan',
		'03 Jan',
		'04 Jan',
		'05 Jan',
		'06 Jan',
		'07 Jan',
		'08 Jan',
		'09 Jan',
		'10 Jan',
		'11 Jan',
		'12 Jan'
	]
}

export const AnnualStatisticData = [
	{
		title: '온도',
		value:'24°C',
		status: -11.4,
		subtitle: `Compare to last year (2019)`
	},
	{
		title: '습도',
		value:'60%',
		status: 8.2,
		subtitle: `Compare to last year (2019)`
	},
	{
		title: '조도',
		value:'100LX',
		status: 0.7,
		subtitle: `Compare to last year (2019)`
	}
]

export const ActiveMembersData = [{
	name: 'Members',
	data: [25, 15, 41, 25, 44, 12, 36, 19, 54]
}]

export const NewMembersData = [{
	img: "/img/avatars/thumb-2.jpg",
	title: "Software Engineer",
	name: "Terrance Moreno",
},
{
	img: "/img/avatars/thumb-3.jpg",
	title: "UI/UX Designer",
	name: "Ron Vargas",
},
{
	img: "/img/avatars/thumb-4.jpg",
	title: "HR Executive",
	name: "Luke Cook",
},
{
	img: "/img/avatars/thumb-5.jpg",
	title: "Frontend Developer",
	name: "Joyce Freeman",
},
{
	img: "/img/avatars/thumb-6.jpg",
	title: "Compliance Manager",
	name: "Samantha Phillips",
}]

export const RecentTransactionData = [
	{
		id: '#5331',
		name: 'Clayton Bates',
		date: '8 May 2020',
		amount: '$137.00',
		status: 'Approved',
		avatarColor: '#04d182'
	},
	{
		id: '#5332',
		name: 'Gabriel Frazier',
		date: '6 May 2020',
		amount: '$322.00',
		status: 'Approved',
		avatarColor: '#fa8c16'
	},
	{
		id: '#5333',
		name: 'Debra Hamilton',
		date: '1 May 2020',
		amount: '$543.00',
		status: 'Pending',
		avatarColor: '#1890ff'
	},
	{
		id: '#5334',
		name: 'Stacey Ward',
		date: '28 April 2020',
		amount: '$876.00',
		status: 'Rejected',
		avatarColor: '#ffc542'
	},
	{
		id: '#5335',
		name: 'Troy Alexander',
		date: '28 April 2020',
		amount: '$241.00',
		status: 'Approved',
		avatarColor: '#ff6b72'
	},
];
