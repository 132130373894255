import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {store} from '../../App';
import {filterBadgeCounter, toggleHeaderDatepicker} from '../../redux/actions';
import DataTableView from '../../components/uplink-components/DataTableView';
import {pagination} from '../../utils/helper';
import IntlMessage from '../../components/util-components/IntlMessage';
import * as api from 'services';
import utils from '../../utils';

function EventLog(props) {
  const isTestBed = utils.isTestBed();
  const branchId = useSelector(state => isTestBed ? state.user?.testBedBranchId : state.user?.branchId);
  const eventLog = useSelector(state => isTestBed ? state.testBed?.testBedEventLog : state.ws?.eventLog);
  const eventLogTotal = useSelector(state => isTestBed ? state.testBed?.testBedEventLogTotal : state.ws?.eventLogTotal)
  const [searchData, setSearchData] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const currentPageRef = React.useRef(1);
  const [fromDate, setFromDate] = React.useState(utils.toStartDateTS(utils.getCurrentDate()));
  const [toDate, setToDate] = React.useState(utils.toEndDateTS(utils.getCurrentDate()));
  const prevKey = React.useRef()

  if (prevKey.current !== props.location.key) {
    prevKey.current = props.location.key;
    setIsRefresh(true);
  }

  const onSearch = async (searchInfo) => {
    setSearchData(searchInfo);
    const tableInfo = {...pagination, page: currentPage, branchId, isTestBed, ...searchInfo};
    await api.getEventLogList(tableInfo);
  };

  useEffect(() => {
    if (isRefresh && branchId !== undefined) {
      const fromDt = searchData?.fromDate ? searchData.fromDate : fromDate;
      const toDt = searchData?.toDate ? searchData.toDate : toDate;
      onSearch({page: 1, searchWord: searchData?.searchWord, fromDate: fromDt, toDate: toDt}).catch();
    }
    if (!isRefresh && branchId === undefined) {
      setIsRefresh(true);
    }
  }, [branchId, isRefresh]);

  useEffect(() => {
    pagination['searchWord'] = '';
    store.dispatch(toggleHeaderDatepicker(false));
    store.dispatch(filterBadgeCounter(0));
  }, []);

  //refresh를 위한 함수
  const setRefresh = (data) => {
    const {isRefresh, page} = data;
    setSearchData(data);
    setIsRefresh(isRefresh);
    setCurrentPage(page);
  };

  const getCurrentPage = (page) => {
    currentPageRef.current = page;
    setCurrentPage(page);
  };

  const tableColumns = [
    {
      title: <IntlMessage id={'create_date'}/>,
      dataIndex: 'created',
      width: 200,
      sorter: true,
      render: (_, record) => (
        <div className="d-flex">
          {utils.formatDTS(record['created'])}
        </div>
      ),
    },
    {
      title: <IntlMessage id={'device_name'}/>,
      dataIndex: 'device_name',
      width: 200,
      sorter: true,
    },
    {
      title: <IntlMessage id={'isauto'}/>,
      dataIndex: 'isauto',
      render: (_, record) => (
        String(record?.isauto)
      ),
      width: 80,
    },
    {
      title: <IntlMessage id={'command'}/>,
      dataIndex: 'cmd_type',
      width: 200,
      sorter: true,
    },
    {
      title: <IntlMessage id={'from_value'}/>,
      dataIndex: 'from_value',
      width: 130,
      sorter: true,
    },
    {
      title: <IntlMessage id={'to_value'}/>,
      dataIndex: 'to_value',
      width: 130,
      sorter: true,
    },
    {
      title: <IntlMessage id={'remark'}/>,
      dataIndex: 'remark',
      width: 200,
      sorter: true,
    },
  ];
  return (
    <div>
      <DataTableView
        columns={tableColumns}
        dataSource={eventLog}
        rowKey="created"
        totalCount={eventLogTotal}
        onSearch={onSearch}
        getCurrentPage={getCurrentPage}
        showSearchWord={true}
        showSearchDate={true}
        isRefresh={isRefresh}
        setRefresh={setRefresh}
        showBranch={true}
      />
    </div>
  );
}

export default EventLog;
