import antdKoKR from 'antd/es/locale/ko_KR';
import koMsg from '../locales/ko_KR.json';

const KoLang = {
  antd: antdKoKR,
  locale: 'ko-KR',
  messages: {
    ...koMsg
  },
};
export default KoLang;
