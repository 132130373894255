import React from 'react';
import {Button, Col, Drawer, Form, Input, Row, Select, Switch} from 'antd';
import {PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {labels} from '../../views/Labels';
import {useSelector} from 'react-redux';
import utils from '../../utils';
import IntlMessage from '../util-components/IntlMessage';
import {useIntl} from 'react-intl';
import {DEVICE_PROTOCOL_LIST, GET_DEVICE_TYPE_ARRAY_API_INDEX_MAP} from 'constants/UplinkConstant';
import * as api from 'services';
import SelectInDrawer, {SELECT_IN_DRAWER_DATA_MAP} from './SelectInDrawer';

const {Option} = Select;

const DeviceFormInDrawer = ({title, elm, onSearch, page}) => {
  const isTestBed = utils.isTestBed();
  const [deviceTypeOptions, setDeviceTypeOptions] = React.useState([]);
  const [isactivated, setIsactivated] = React.useState(false);
  const [houseName, setHouseName] = React.useState();
  const [houseId, setHouseId] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [inputData, setInputData] = React.useState({isDel: false});
  const [isController, setIsController] = React.useState(false);
  const [remoteTypeId, setRemoteTypeId] = React.useState();
  const deviceTypeList = useSelector(state => state.ws.deviceTypeList);
  const branchId = useSelector(state => isTestBed ? state.user?.testBedBranchId : state.user?.branchId);
  const [form] = Form.useForm();
  const intl = useIntl();

  const onSearchDeviceType = async () => {
    await api.getDeviceTypeArray({branchId: branchId});
  };


  React.useEffect(() => {
    let typeList = []
    if (deviceTypeList) {
      for (let key of deviceTypeList) {
        typeList.push({label: key[1], value: key[0]});
      }
    }
    setDeviceTypeOptions(typeList);
  }, [deviceTypeList])

  const showDrawer = (elm) => {
    onSearchDeviceType().catch();
    setVisible(true);
    if (elm) {
      const {
        device_id: deviceId,
        house_id: houseId,
        house_name: houseName,
        device_type_id: deviceTypeId,
        device_type_name: deviceTypeName,
        device_kind: deviceKind,
        device_name: deviceName,
        serial,
        isactivated,
        remote_type_id: remoteTypeId,
        protocol,
        remote_type_name:remoteType
      } = elm;
      setHouseId(houseId);
      setHouseName(houseName);
      setIsactivated(isactivated);
      setIsController(utils.isActuator(deviceKind));
      setRemoteTypeId(remoteTypeId);
      setInputData({
        ...inputData,
        deviceId,
        houseId,
        deviceTypeId,
        deviceName,
        serial,
        isactivated,
        deviceKind,
        remoteTypeId,
        protocol
      });
      form.setFieldsValue({
        houseName,
        deviceTypeName,
        deviceName,
        serial,
        isactivated,
        remoteType,
        protocol
      });
    }
  };

  const onClose = () => {
    setVisible(false);
    setIsController(false)
    resetFields();
  };


  const onSubmit = async () => {
    if (inputData) {
      if (inputData['houseId'] === undefined) {
        return utils.showWarning(intl.formatMessage({id: 'select_s_house'}));
      }
      if (inputData['deviceTypeId'] === undefined) {
        return utils.showWarning(intl.formatMessage({id: 'select_device_type'}));
      }
      if (isController && (inputData['remoteTypeId'] === undefined)) {
        return utils.showWarning(intl.formatMessage({id: 'select_remote_type'}));
      }
      if (inputData['deviceName'] !== undefined && inputData['serial'] !== undefined) {
        await api.deviceCUD({...inputData, branchId}).then(res => {
          if (res) {
            setVisible(false);
            onSearch({page});
            resetFields();
            utils.showSuccess(intl.formatMessage({id: 'SUCCESS_MESSAGE'}));
          }
        });
      }
    }
  }

  const resetFields = () => {
    form.resetFields();
    setHouseId(undefined);
    setHouseName(undefined);
    setIsactivated(false);
    setRemoteTypeId(undefined);
    setInputData(undefined);
    setIsController(false)
  };

  const getSelectedHouseData = (selectedHouse) => {
    const hId = selectedHouse?.house_id ?? houseId;
    const hName = selectedHouse?.house_name ?? houseName;
    setHouseId(hId);
    setHouseName(hName);
    setInputData({...inputData, houseId: hId});
    form.setFieldsValue({houseName: hName});
  };

  const handleInputData = e => {
    const name = e.target.id;
    const value = e.target.value;

    setInputData({...inputData, [name]: value});
  }

  const onDeviceTypeIdChange = (value) => {
    let selectedDevice = [];
    selectedDevice = deviceTypeList.find((item) => {
      return item[GET_DEVICE_TYPE_ARRAY_API_INDEX_MAP.id] === value
    })

    if (utils.isActuator(selectedDevice[GET_DEVICE_TYPE_ARRAY_API_INDEX_MAP.kind])) {
      form.resetFields(['remoteType'])
      setIsController(true)
    } else {
      form.resetFields(['remoteType'])
      setIsController(false);
    }

    setInputData({
      ...inputData,
      deviceTypeId: value,
      deviceKind: selectedDevice[GET_DEVICE_TYPE_ARRAY_API_INDEX_MAP.kind],
      remoteTypeId: undefined
    });
  }

  const handleUserActivativeSwitch = value => {
    setIsactivated(value);
    setInputData({...inputData, isactivated: value});
  }

  const getSelectedRemoteType = (selectedRemoteType) => {
    setRemoteTypeId(selectedRemoteType?.remote_type_id);
    form.setFieldsValue({remoteType: selectedRemoteType?.remote_type_name});
    setInputData({...inputData, remoteTypeId: selectedRemoteType?.remote_type_id});
  }


  const onProtocolChange = (protocol) => {
    setInputData({...inputData, protocol});
  }


  return (
    <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
      <Button
        type='primary'
        icon={title === labels.DEVICE_EDIT ? <SettingOutlined/> : <PlusOutlined/>}
        onClick={() => showDrawer(elm)} size={title === labels.DEVICE_EDIT ? 'small' : null}
      >
        {title === labels.DEVICE_EDIT ? undefined : intl.formatMessage({id: 'device_add'})}
      </Button>
      <Drawer
        title={title === labels.DEVICE_EDIT ? intl.formatMessage({id: 'device_edit'}) : intl.formatMessage({id: 'device_add'})}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={() => onClose(elm)}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout='vertical' hideRequiredMark form={form}>
          <Row gutter={10}>
            <Col span={10}>
              <Form.Item name='houseName' label={<IntlMessage id={'houseName'}/>}
                         rules={[
                           {
                             required: true,
                             message: intl.formatMessage({id: 'select_house_name'})
                           }
                         ]}>
                <Input onChange={handleInputData} disabled={true}
                       placeholder={intl.formatMessage({id: 'select_house_name'})}/>
              </Form.Item>
            </Col>
            <Col span={2} style={{marginTop: '28px'}}>
              <SelectInDrawer
                branchId={branchId}
                selectedRowKey={houseId}
                getSelectedRowData={getSelectedHouseData}
                componentDataMap={SELECT_IN_DRAWER_DATA_MAP.house}/>
            </Col>
            <Col span={12}>
              <Form.Item name="protocol" label={intl.formatMessage({id: 'device_protocol'})}
                         rules={[
                           {
                             required: true,
                             message: intl.formatMessage({id: 'select_protocol'})
                           }
                         ]}>
                <Select placeholder={intl.formatMessage({id: 'select_device_protocol'})} onChange={onProtocolChange}
                >
                  {DEVICE_PROTOCOL_LIST.map((protocol, index) => {
                    return <Option value={protocol} key={index}>{protocol}</Option>
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {isController && (
            <Row gutter={4}>
              <Col span={10}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: intl.formatMessage({id: 'select_remote_type'})
                    }
                  ]}
                  label={<IntlMessage id={'remote_type'}/>}
                  name='remoteType'
                >
                  <Input disabled={true}
                         placeholder={intl.formatMessage({id: 'select_remote_type'})}/>
                </Form.Item>
              </Col>
              <Col span={2} style={{marginTop: '28px'}}>
                <SelectInDrawer
                  selectedRowKey={remoteTypeId}
                  getSelectedRowData={getSelectedRemoteType}
                  componentDataMap={SELECT_IN_DRAWER_DATA_MAP.remoteType}/>
              </Col>
            </Row>
          )}

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name='deviceTypeName' label={<IntlMessage id={'DeviceType'}/>} rules={[
                {
                  required: true,
                  message: intl.formatMessage({id: 'select_device_type'})
                }
              ]}>
                <Select onChange={onDeviceTypeIdChange}
                        placeholder={intl.formatMessage({id: 'select_device_type'})}>
                  {deviceTypeOptions?.map((type, index) => {
                    return (
                      <Option key={index} value={type.value} name={type.label}>{type.label}</Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='deviceName' label={<IntlMessage id={'device_name'}/>} rules={[
                {
                  required: true,
                  message: intl.formatMessage({id: 'write_device_name'})
                }
              ]}>
                <Input onChange={handleInputData}
                       placeholder={intl.formatMessage({id: 'write_device_name'})}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name='serial' label={<IntlMessage id={'serial'}/>} rules={[
                {
                  required: true,
                  message: intl.formatMessage({id: 'write_serial'})
                }
              ]}>
                <Input onChange={handleInputData}
                       placeholder={intl.formatMessage({id: 'write_serial'})}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='isactivated' label={<IntlMessage id={'activate'}/>}>
                <Switch onChange={handleUserActivativeSwitch} checked={isactivated}/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className='drawer-form-buttons'>
            <Button onClick={() => onClose(elm)} style={{marginRight: 8}}><IntlMessage id={'cancel'}/></Button>
            <Button onClick={onSubmit} htmlType={'submit'} type='primary'>
              {title === labels.DEVICE_EDIT ? <IntlMessage id={'edit'}/> : <IntlMessage id={'add'}/>}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default DeviceFormInDrawer;
