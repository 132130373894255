import React from 'react';
import {Button, Drawer, Form, Typography} from 'antd';
import {useIntl} from 'react-intl';
import utils from '../../utils';
import DeviceConnectInfo from './DeviceConnectInfo';

const {Link} = Typography;

const DeviceConnectInfoDrawer = ({protocol, serial, sensors, commands}) => {
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();
  const intl = useIntl();
  const apiKey = utils.getUserApiKey();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  return (
    <div style={{zIndex: 10, position: 'relative', top: '23px'}} className="d-flex justify-content-end">
      <Link style={{marginRight: '10px', textDecoration: 'underline', fontSize: '14.5px'}}
            onClick={showDrawer}>{intl.formatMessage({id: 'device_connect_code'})}</Link>
      <Drawer
        title={intl.formatMessage({id: 'device_connect_code'})}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={onClose}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout="vertical" hideRequiredMark form={form}>
          <div>
            <DeviceConnectInfo protocol={protocol} apiKey={apiKey} serial={serial} sensors={sensors}
                               commands={commands}/>
          </div>
          <Form.Item className="drawer-form-buttons">
            <Button
              onClick={onClose}
              style={{marginRight: 8}}
            >
              {intl.formatMessage({id: 'close'})}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default DeviceConnectInfoDrawer;
