export const TESTBED_DEVICE_DATA_LIST = 'TESTBED_DEVICE_DATA_LIST';
export const TESTBED_DEVICE_TYPE_LIST = 'TESTBED_DEVICE_TYPE_LIST'
export const TESTBED_DEVICE_LIST = 'TESTBED_DEVICE_LIST';
export const TESTBED_DEVICE_TYPE_LIST_TOTAL = 'TESTBED_DEVICE_TYPE_LIST_TOTAL'
export const TESTBED_DEVICE_LIST_TOTAL = 'TESTBED_DEVICE_LIST_TOTAL';
export const TESTBED_DEVICE_DATA_LIST_TOTAL = 'TESTBED_DEVICE_DATA_LIST_TOTAL';
export const TESTBED_SMART_ROUTINE_LIST = 'TESTBED_SMART_ROUTINE_LIST';
export const TESTBED_SMART_ROUTINE_LIST_TOTAL = 'TESTBED_SMART_ROUTINE_LIST_TOTAL';
export const TESTBED_DEVICE_TYPE_ITEMS = 'TESTBED_DEVICE_TYPE_ITEMS';
export const TESTBED_DEVICE_VIEW_LIST = 'TESTBED_DEVICE_VIEW_LIST';
export const TESTBED_DEVICE_VIEW_LIST_TOTAL = 'TESTBED_DEVICE_VIEW_LIST_TOTAL';
export const TESTBED_BRANCH_LIST = 'TESTBED_BRANCH_LIST';
export const TESTBED_BRANCH_LIST_TOTAL = 'TESTBED_BRANCH_LIST_TOTAL';
export const TESTBED_EVENT_LOG = 'TESTBED_EVENT_LOG';
export const TESTBED_EVENT_LOG_TOTAL = 'TESTBED_EVENT_LOG_TOTAL';
