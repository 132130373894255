import React from 'react'
import {Card, Table} from 'antd';
import utils from '../../../utils';
import {CloseOutlined} from '@ant-design/icons';
import {useIntl} from "react-intl";

export const EventLogTable = ({title, onDelete, dataSource}) => {
  const intl = useIntl();

  const tableColumns = [
    {
      title: intl.formatMessage({id: 'create_date'}),
      dataIndex: 'created',
      render: (_, record) => (
        utils.formatShortDTS(record['created'])
      ),
      width: 111,
    },
    {
      title: intl.formatMessage({id: 'device_name'}),
      dataIndex: 'device_name',
      width: 111,
    },
    {
      title: intl.formatMessage({id: 'isauto'}),
      dataIndex: 'isauto',
      render: (_, record) => (
        String(record?.isauto)
      ),
      width: 50,
    },
    {
      title: intl.formatMessage({id: 'command'}),
      dataIndex: 'cmd_type',
      width: 100,
    },
    {
      title: intl.formatMessage({id: 'from_value'}),
      dataIndex: 'from_value',
      width: 90,
    },
    {
      title: intl.formatMessage({id: 'to_value'}),
      dataIndex: 'to_value',
      width: 90,
    },
    {
      title: intl.formatMessage({id: 'remark'}),
      dataIndex: 'remark',
      width: 100,
    },
  ];

  return (
    <Card title={title}
          extra={<a href="/#" className="text-gray font-size-lg" onClick={onDelete}><CloseOutlined/></a>}>
      <Table columns={tableColumns} dataSource={dataSource} rowKey="created" bordered
             pagination={false} size="small" scroll={{x: false, y: 228}}/>
    </Card>
  )
};

export default EventLogTable;
