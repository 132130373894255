import {
  USER_DATA,
  HOUSE_LIST,
  USER_LIST,
  HOUSE_USER_LIST,
  MANAGER_HOUSE_LIST,
  DEVICE_TYPE_LIST,
  DEVICE_LIST,
  HOUSE_LIST_TOTAL,
  USER_LIST_TOTAL,
  HOUSE_USER_LIST_TOTAL,
  MANAGER_HOUSE_LIST_TOTAL,
  DEVICE_TYPE_LIST_TOTAL,
  DEVICE_LIST_TOTAL,
  LOGIN_LOG_LIST_TOTAL,
  ERROR_LOG_LIST_TOTAL,
  LOGIN_LOG_LIST,
  ERROR_LOG_LIST,
  SMART_ROUTINE_LIST,
  SMART_ROUTINE_LIST_TOTAL,
  SMART_ALARM_LIST,
  SMART_ALARM_LIST_TOTAL,
  ICON_LIST,
  ICON_LIST_TOTAL,
  BRANCH_LIST,
  BRANCH_LIST_TOTAL,
  BRANCH_TYPE_LIST,
  DEVICE_VIEW_LIST,
  DEVICE_VIEW_LIST_TOTAL,
  DEVICE_DATA_LIST,
  DEVICE_DATA_LIST_TOTAL,
  DID_INFO_LIST,
  DID_INFO_LIST_TOTAL,
  CONTROLLERS,
  LOG_IN_LOG,
  LOG_IN_LOG_TOTAL,
  ERROR_LOG,
  ERROR_LOG_TOTAL,
  REMOTE_TYPE_LIST,
  REMOTE_COMMAND_TYPE_LIST,
  REMOTE_TYPE_TOTAL,
  REMOTE_TYPE_OPTION,
  BRANCH_TYPE_OPTION,
  DEVICE_TYPE_ITEMS,
  SELECT_REMOTE_TYPE_LIST,
  SELECT_REMOTE_TYPE_LIST_TOTAL,
  EVENT_LOG,
  EVENT_LOG_TOTAL,
} from '../constants/WS';
import _ from 'lodash';

export const HouseList = (data) => {
  return {
    type: HOUSE_LIST,
    houseData: data
  }
};

export const ManagerHouseList = (data) => {
  return {
    type: MANAGER_HOUSE_LIST,
    managerhouseData: data
  }
};

export const UserData = (data) => {
  return {
    type: USER_DATA,
    userData: data
  }
};

export const UserList = (data) => {
  return {
    type: USER_LIST,
    userData: data
  }
};

export const HouseUserList = (data) => {
  return {
    type: HOUSE_USER_LIST,
    houseUserData: data
  }
};

export const DeviceTypeList = (data) => {
  return {
    type: DEVICE_TYPE_LIST,
    deviceTypeData: data
  }
};

export const DeviceList = (data) => {
  return {
    type: DEVICE_LIST,
    deviceData: data
  }
};

export const LoginLogList = (data) => {
  return {
    type: LOGIN_LOG_LIST,
    loginLogData: data
  }
};

export const ErrorLogList = (data) => {
  return {
    type: ERROR_LOG_LIST,
    errorLogData: data
  }
};

export const HouseListTotal = (totalCount) => {
  return {
    type: HOUSE_LIST_TOTAL,
    houseListTotal: totalCount
  }
};

export const UserListTotal = (totalCount) => {
  return {
    type: USER_LIST_TOTAL,
    userListTotal: totalCount
  }
};

export const HouseUserListTotal = (totalCount) => {
  return {
    type: HOUSE_USER_LIST_TOTAL,
    houseUserListTotal: totalCount
  }
};

export const ManagerHouseListTotal = (totalCount) => {
  return {
    type: MANAGER_HOUSE_LIST_TOTAL,
    managerHouseListTotal: totalCount
  }
};

export const DeviceTypeListTotal = (totalCount) => {
  return {
    type: DEVICE_TYPE_LIST_TOTAL,
    deviceTypeListTotal: totalCount
  }
};

export const DeviceListTotal = (totalCount) => {
  return {
    type: DEVICE_LIST_TOTAL,
    deviceListTotal: totalCount
  }
};

export const LoginLogListTotal = (totalCount) => {
  return {
    type: LOGIN_LOG_LIST_TOTAL,
    loginLogListTotal: totalCount
  }
};

export const ErrorLogListTotal = (totalCount) => {
  return {
    type: ERROR_LOG_LIST_TOTAL,
    errorLogListTotal: totalCount
  }
};

export const SmartRoutineList = (data) => {
  return {
    type: SMART_ROUTINE_LIST,
    smartRoutineList: data
  }
};

export const SmartRoutineListTotal = (totalCount) => {
  return {
    type: SMART_ROUTINE_LIST_TOTAL,
    smartRoutineListTotal: totalCount
  }
};

export const SmartAlarmList = (data) => {
  return {
    type: SMART_ALARM_LIST,
    smartAlarmList: data
  }
};

export const SmartAlarmListTotal = (totalCount) => {
  return {
    type: SMART_ALARM_LIST_TOTAL,
    smartAlarmListTotal: totalCount
  }
};

export const IconList = (data) => {
  return {
    type: ICON_LIST,
    iconList: data
  }
};

export const IconListTotal = (totalCount) => {
  return {
    type: ICON_LIST_TOTAL,
    iconListTotal: totalCount
  }
};

export const BranchList = (data) => {
  return {
    type: BRANCH_LIST,
    branchList: data
  }
};

export const BranchListTotal = (totalCount) => {
  return {
    type: BRANCH_LIST_TOTAL,
    branchListTotal: totalCount
  }
};

export const BranchTypeList = (data) => {
  return {
    type: BRANCH_TYPE_LIST,
    branchTypeList: data
  }
};

export const DeviceViewList = (data) => {
  return {
    type: DEVICE_VIEW_LIST,
    deviceViewList: data
  }
};

export const DeviceViewListTotal = (totalCount) => {
  return {
    type: DEVICE_VIEW_LIST_TOTAL,
    deviceViewListTotal: totalCount
  }
};

export const DeviceDataList = (data) => {
  const newData = data?.map((deviceData) => {
    return {..._.omit(deviceData, ['device_data']), ...deviceData?.device_data};
  })
  return {
    type: DEVICE_DATA_LIST,
    deviceDataList: newData
  }
};

export const DeviceDataListTotal = (totalCount) => {
  return {
    type: DEVICE_DATA_LIST_TOTAL,
    deviceDataListTotal: totalCount
  }
};

export const DeviceTypeItems = (data) => {
  return {
    type: DEVICE_TYPE_ITEMS,
    deviceTypeItems: data
  }
};


export const DidInfoList = (data) => {
  return {
    type: DID_INFO_LIST,
    didInfoList: data
  }
};

export const DidInfoListTotal = (data) => {
  return {
    type: DID_INFO_LIST_TOTAL,
    didInfoListTotal: data
  }
};

export const Controllers = (data) => {
  return {
    type: CONTROLLERS,
    controllers: data
  }
};

//LOGINLOG의 list 액션
export const LogInLog = (data) => {
  return {
    type: LOG_IN_LOG,
    logInLog: data,
  };
};

export const LogInLogTotal = (data) => {
  return {
    type: LOG_IN_LOG_TOTAL,
    logInLogTotal: data,
  };
}

//ERRORLOG의 list 액션
export const ErrorLog = (data) => {
  return {
    type: ERROR_LOG,
    errorLog: data,
  };
};

export const ErrorLogTotal = (data) => {
  return {
    type: ERROR_LOG_TOTAL,
    errorLogTotal: data,
  }
}

export const EventLog = (data) => {
  return {
    type: EVENT_LOG,
    eventLog: data,
  };
};

export const EventLogTotal = (data) => {
  return {
    type: EVENT_LOG_TOTAL,
    eventLogTotal: data,
  }
}

//RemoteType의 List를 액션
export const RemoteType = (data) => {
  return {
    type: REMOTE_TYPE_LIST,
    remoteTypeList: data,
  };
};

export const RemoteTypeTotal = (data) => {
  return {
    type: REMOTE_TYPE_TOTAL,
    remoteTypeTotal: data,
  }
}

export const CommandType = (data) => {
  return {
    type: REMOTE_COMMAND_TYPE_LIST,
    remoteCommandTypeList: data,
  };
};

//Device type drawer에 리모트타입 옵션을 주기위한 액션
export const RemoteTypeOption = (data) => {
  return {
    type: REMOTE_TYPE_OPTION,
    remoteTypeOption: data,
  };
};

//User 추가 drawer에 브랜치타입 옵션을 주기위한 액션
export const BranchTypeOption = (data) => {
  return {
    type: BRANCH_TYPE_OPTION,
    branchTypeOption: data,
  };
};

export const SelectRemoteTypeList = (data) => {
  return {
    type: SELECT_REMOTE_TYPE_LIST,
    selectRemoteTypeList: data,
  }
}

export const SelectRemoteTypeListTotal = (data) => {
  return {
    type: SELECT_REMOTE_TYPE_LIST_TOTAL,
    selectRemoteTypeListTotal: data,
  }
}
