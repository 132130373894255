import React from 'react';
import {Button, Col, Drawer, Form, Input, Popconfirm, Row, Switch, Table, Tooltip} from 'antd';
import {DeleteOutlined, PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {labels} from '../../views/Labels';
import DeviceTypeSensorSelectorDrawer from './DeviceTypeSensorSelectorDrawer';
import DeviceTypeCommandSelectorDrawer from './DeviceTypeCommandSelectorDrawer';
import IntlMessage from '../util-components/IntlMessage';
import {useIntl} from 'react-intl';
import * as api from 'services';
import {useSelector} from 'react-redux';
import utils from '../../utils';
import _ from 'lodash';

const DeviceTypeFormInDrawer = ({title, elm, onSearch}) => {
  const isTestBed = utils.isTestBed();
  const [isactivated, setIsactivated] = React.useState(false);
  const [iszigbee, setIszigbee] = React.useState(false);
  const [sensors, setSensors] = React.useState([]);
  const [commands, setCommands] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [inputData, setInputData] = React.useState({isDel: false});
  const [buttonSize, setButtonSize] = React.useState(title === labels.DEVICE_TYPE_EDIT ? 'small' : null);
  const branchId = useSelector(state => isTestBed ? state.user?.testBedBranchId : state.user?.branchId);
  const [form] = Form.useForm();
  const intl = useIntl();

  const showDrawer = (elm) => {
    resetFields();
    setVisible(true);
    let jsensorInfo = [], jcommandInfo = [];
    if (elm) {
      const {
        device_type_id: deviceTypeId,
        device_type_name: deviceTypeName,
        maker,
        isactivated,
        iszigbee,
        jsensor = {},
        jcommand = {}
      } = elm;
      if (jsensor.length > 0) {
        jsensorInfo = jsensor?.map((sensor, i) => {
          return (
            {...sensor, id: i + 1}
          );
        });
      }
      if (jcommand.length > 0) {
        jcommandInfo = jcommand?.map((command, i) => {
          return (
            {...command, id: i + 1}
          );
        });
      }
      setIsactivated(isactivated);
      setIszigbee(iszigbee)
      setSensors(jsensorInfo);
      setCommands(jcommandInfo);
      setInputData({
        ...inputData,
        deviceTypeId,
        deviceTypeName,
        maker,
        isactivated,
        iszigbee,
        jsensor,
        jcommand,
        branchId
      });
      form.setFieldsValue({deviceTypeName, maker, isactivated, iszigbee});
    }
  };

  const onClose = () => {
    setVisible(false);
    resetFields();
  };

  const onSubmit = async () => {
    if (inputData) {
      if (inputData['jsensor'] === undefined && inputData['jcommand'] === undefined) {
        return utils.showWarning(intl.formatMessage({id: 'input_sensor_or_command'}))
      } else {
        setVisible(false);
        await api.deviceTypeCUD({...inputData, branchId}).then(res => {
          if (res) {
            onSearch();
            resetFields();
            utils.showSuccess(intl.formatMessage({id: 'success_save'}));
          }
        });
      }

    }
  }

  const resetFields = () => {
    form.resetFields();
    setIsactivated(false);
    setIszigbee(false);
    setSensors([]);
    setCommands([]);
    setInputData(undefined);
  };

  const handleInputData = e => {
    const name = e.target.id;
    const value = e.target.value;
    setInputData({...inputData, [name]: value});
  }

  const handleUserActivativeSwitch = value => {
    setIsactivated(value);
    const name = 'isactivated';
    setInputData({...inputData, [name]: value});
  }

  const handleIszigbeeActivativeSwitch = value => {
    setIszigbee(value);
    const name = 'iszigbee';
    setInputData({...inputData, [name]: value});
  }

  const deleteSensor = elm => {
    let sensorsData = sensors;
    let index = -1;

    for (let key of sensorsData) {
      index++;
      if (key.id === elm.id) {
        sensorsData.splice(index, 1);
      }
    }
    const newSensorsData = sensorsData.map((sensor, i) => {
      return (
        {...sensor, id: i + 1}
      );
    });
    setSensors(newSensorsData);
    setInputData({...inputData, jsensor: newSensorsData});
  }

  const deleteCommand = elm => {
    let commandData = []
    commandData = commands.filter((item) => {
      return item.id !== elm.id
    })
    setCommands(commandData)
    setInputData({...inputData, jcommand: commandData});
  }

  const getSensorData = (data, index) => {

    if (data) {
      if (index !== undefined) {
        sensors[index] = data
        setSensors([...sensors]);
        setInputData({...inputData, jsensor: [...sensors]});
      } else {
        const dataInfo = {id: sensors?.length + 1 ?? 1, ...data};
        setSensors([...sensors, dataInfo]);
        setInputData({...inputData, jsensor: [...sensors, data]});
      }
    }
  };

  const getCommandData = (data, index) => {
    if (data) {
      if (index !== undefined) {
        commands[index] = data
        setCommands([...commands]);
        setInputData({...inputData, jcommand: [...commands]});
      } else {
        const dataInfo = {
          id: commands?.length + 1 ?? 1,
          commandType: data.commandType,
          commandName: data.commandName,
          commandValues: data.commandValues
        };
        setCommands([...commands, dataInfo]);
        setInputData({...inputData, jcommand: [...commands, dataInfo]});
      }
    }
  };

  const sensorTableColumns = [
    {
      title: intl.formatMessage({id: 'key'}),
      dataIndex: 'sensorType',
      width: 130,
    },
    {
      title: intl.formatMessage({id: 'display_name'}),
      dataIndex: 'sensorName',
      width: 130,
    },
    {
      title: intl.formatMessage({id: 'UNIT'}),
      dataIndex: 'sensorUnit',
      width: 130,
    },
    {
      title: intl.formatMessage({id: 'sensing_type'}),
      dataIndex: 'sensorGline',
      width: 180,
      render: (type) => (
        <div className="d-flex">
          {(_.isEmpty(type?.[0]) && _.isEmpty(type?.[1]) && _.isEmpty(type?.[2]))
            ? ''
            : `${type?.[0]}, ${type?.[1]}, ${type?.[2]}`}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 120,
      render: (_, elm, index) => (
        <div className="text-right d-flex justify-content-end">
          <Tooltip title="View">
            <DeviceTypeSensorSelectorDrawer title={labels.SENSOR_EDIT} elm={elm}
                                            elmIndex={index} sensorData={sensors} getSensorData={getSensorData}/>
          </Tooltip>
          <Popconfirm placement="leftTop" title={<IntlMessage id={'DELETE_TITLE'}/>} onConfirm={() => {
            deleteSensor(elm)
          }} okText={<IntlMessage id={'YES'}/>} cancelText={<IntlMessage id={'NO'}/>}>
            <Button danger icon={<DeleteOutlined/>} size="small"/>
          </Popconfirm>

        </div>
      )
    }
  ];

  const commandTableColumns = [
    {
      title: intl.formatMessage({id: 'command'}),
      dataIndex: 'commandType',
      width: 150,
    },
    {
      title: intl.formatMessage({id: 'description'}),
      dataIndex: 'commandName',
      width: 150,
    },
    {
      title: intl.formatMessage({id: 'values'}),
      dataIndex: 'commandValues',
      width: 300,
      render: (_, record) => (
        <div className="d-flex">
          {record['commandValues']?.map((com, i) => {
            const isComma = record['commandValues'].length - 1 === i ? '' : ',';
            return (
              <div>"{com.value}": "{com.name}" {isComma}</div>
            );
          })}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 120,
      render: (_, elm, index) => (
        <div className="text-right d-flex justify-content-end">
          <Tooltip title="View">
            <DeviceTypeCommandSelectorDrawer title={labels.COMMAND_EDIT} elm={elm} key={index}
                                             elmIndex={index} actuatorData={commands} getActuatorData={getCommandData}/>

          </Tooltip>
          <Popconfirm placement="leftTop" title={<IntlMessage id={'DELETE_TITLE'}/>} onConfirm={() => {
            deleteCommand(elm)
          }} okText={<IntlMessage id={'YES'}/>} cancelText={<IntlMessage id={'NO'}/>}>
            <Button danger icon={<DeleteOutlined/>} size="small"/>
          </Popconfirm>
        </div>
      )
    }
  ];

  return (
    <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
      <Button type="primary"
              icon={title === labels.DEVICE_TYPE_EDIT || title === labels.SENSOR_EDIT || title === labels.COMMAND_EDIT ?
                <SettingOutlined/> : <PlusOutlined/>}
              onClick={() => showDrawer(elm)} size={buttonSize}>
        {title === labels.DEVICE_TYPE_EDIT || title === labels.SENSOR_EDIT || title === labels.COMMAND_EDIT ? undefined :
          intl.formatMessage({id: 'device_type_add'})}
      </Button>
      <Drawer
        title={title === labels.DEVICE_TYPE_EDIT ? <IntlMessage id={'device_type_edit'}/> :
          <IntlMessage id={'device_type_add'}/>}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={() => onClose(elm)}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout="vertical" hideRequiredMark form={form} onFinish={onSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="deviceTypeName" label={<IntlMessage id={'typeName'}/>} rules={[
                {
                  required: true,
                  message: <IntlMessage id={'write_device_name'}/>,
                }
              ]}>
                <Input onChange={handleInputData} placeholder={intl.formatMessage({id: 'write_type_name'})}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="maker" label={<IntlMessage id={'maker'}/>} rules={[
                {
                  required: true,
                  message: <IntlMessage id={'write_maker'}/>,
                }
              ]}>
                <Input onChange={handleInputData} placeholder={intl.formatMessage({id: 'write_maker'})}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="iszigbee" label={<IntlMessage id={'iszigbee'}/>}>
                <Switch onChange={handleIszigbeeActivativeSwitch} checked={iszigbee}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="isactivated" label={<IntlMessage id={'activate'}/>}>
                <Switch onChange={handleUserActivativeSwitch} checked={isactivated}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={intl.formatMessage({id: 'sensors'})}>
                <Table columns={sensorTableColumns} dataSource={sensors} rowKey="id" bordered pagination={false}
                       scroll={{x: 'max-content', y: 210}}/>
                <DeviceTypeSensorSelectorDrawer title={labels.SENSOR_ADD} sensorData={sensors}
                                                getSensorData={getSensorData}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={<IntlMessage id={'commands'}/>}>
                <Table columns={commandTableColumns} dataSource={commands} rowKey="id" bordered pagination={false}
                       scroll={{x: 'max-content', y: 210}}/>
                <DeviceTypeCommandSelectorDrawer title={labels.COMMAND_ADD} elm={elm}
                                                 actuatorData={commands} getActuatorData={getCommandData}/>

              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="drawer-form-buttons">
            <Button onClick={() => onClose(elm)} style={{marginRight: 8}}><IntlMessage id={'cancel'}/></Button>
            <Button htmlType={'submit'} type="primary">
              {title === labels.DEVICE_TYPE_EDIT ? <IntlMessage id={'edit'}/> : <IntlMessage id={'add'}/>}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );

}

export default DeviceTypeFormInDrawer;
