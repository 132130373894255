import React from "react";
import {Drawer, Form, Button, Col, Row, Input, Switch, Select} from 'antd';
import {labels} from '../../views/Labels';
import {SettingOutlined, PlusOutlined} from '@ant-design/icons';
import util from "../../utils";
import {useSelector} from "react-redux";
import IntlMessage from "../util-components/IntlMessage";
import {useIntl} from "react-intl";
import * as api from 'services';

const {TextArea} = Input;
const {Option} = Select;

const BranchFormInDrawer = ({title, elm, onSearch, showSuccess}) => {
  const [branchType, setBranchType] = React.useState();
  const [isactivated, setIsactivated] = React.useState(false);
  const [branchTypeOptions, setBranchTypeOptions] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [inputData, setInputData] = React.useState({isDel: false});
  const branchTypeList = useSelector(state => state.ws.branchTypeList);
  const [buttonSize, setButtonSize] = React.useState(title === labels.BRANCH_EDIT ? 'small' : null);
  const [form] = Form.useForm();
  const intl = useIntl();

  const getBranchType = async () => {
    await api.getBranchTypes();
  }

  const showDrawer = (elm) => {
    resetFields();
    getBranchType().catch();
    setVisible(true);
    if (elm) {
      const {branch_id: branchId, branch_name: branchName, branch_type: branchType, info, isactivated} = elm;
      setBranchType(branchType);
      setIsactivated(isactivated);
      setInputData({...inputData, branchId, branchName, branchType, info, isactivated});
      form.setFieldsValue({branchName, branchType, info, isactivated});
    }
  };

  React.useEffect(() => {
    if (branchTypeList) {
      let typeList = [];
      for (let key of branchTypeList) {
        typeList.push({label: key, value: key});
      }
      setBranchTypeOptions(typeList);
    }
  }, [branchTypeList]);

  const onClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const onSubmit = async () => {
    if (inputData) {
      if (branchType === undefined) {
        return util.showWarning(intl.formatMessage({id: "select_branch_type"}));
      }
      if (inputData["branchName"] !== undefined) {
        setVisible(false);
        await api.branchCUD(inputData).then((res, errMessage) => {
          if (res) {
            onSearch();
            resetFields();
            util.showSuccess(intl.formatMessage({id: 'success_save'}));
          }
          else{
            util.showWarning(errMessage)
          }
        });
      }
    }
  };

  const resetFields = () => {
    form.resetFields();
    setBranchType(undefined);
    setIsactivated(false)
    setInputData(undefined);
  };

  const handleInputData = e => {
    const name = e.target.id;
    const value = e.target.value;

    setInputData({...inputData, [name]: value});
  };

  const handleSelectData = (value, name) => {
    setBranchType(value);
    setInputData({...inputData, [name]: value});
  }

  const handleUserActivativeSwitch = (value) => {
    setIsactivated(value);
    setInputData({...inputData, isactivated: value});
  };

  return (
    <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
      <Button type="primary"
              icon={title === labels.BRANCH_EDIT ? <SettingOutlined/> : <PlusOutlined/>}
              onClick={() => showDrawer(elm)} size={buttonSize}>
        {title === labels.BRANCH_EDIT ? undefined : title}
      </Button>
      <Drawer
        title={title}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={() => onClose(elm)}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout="vertical" hideRequiredMark form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="branchName" label={<IntlMessage id={"branch_name"}/>}>
                <Input required={true} onChange={handleInputData}
                       placeholder={intl.formatMessage({id: "write_branch_name"})}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="branchType" label={<IntlMessage id={"branchType"}/>}>
                <Select required={true} onChange={e => handleSelectData(e, 'branchType')}
                        placeholder={intl.formatMessage({id: "select_branch_type"})}>
                  {branchTypeOptions?.map((type, key) => {
                    return (
                      <Option value={type.value} name={type.label} key={key}>{type.label}</Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12} style={{paddingLeft: 20}}>
              <Form.Item name="isactivated" label={<IntlMessage id={'activate'}/>}>
                <Switch onChange={handleUserActivativeSwitch} checked={isactivated}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="info" label={<IntlMessage id={"information"}/>}>
                <TextArea onChange={handleInputData} rows={10}/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className='drawer-form-buttons'>
            <Button onClick={() => onClose(elm)} style={{marginRight: 8}}>{intl.formatMessage({id: 'cancel'})}</Button>
            <Button onClick={onSubmit} htmlType={"submit"} type="primary">
              {title === labels.BRANCH_EDIT ? <IntlMessage id={'edit'}/> : <IntlMessage id={'add'}/>}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );

}

export default BranchFormInDrawer;
