import React from 'react'
import {Tag, Tooltip, Button, Popconfirm} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import UserFormInDrawer from "../../components/uplink-components/UserFormInDrawer";
import {useSelector} from "react-redux";
import {labels} from '../Labels';
import {store} from "../../App";
import {filterBadgeCounter, toggleHeaderDatepicker} from "../../redux/actions";
import DataTableView from "../../components/uplink-components/DataTableView";
import {pagination} from "../../utils/helper";
import IntlMessage from "../../components/util-components/IntlMessage";
import utils from "utils";
import * as api from 'services';

export const UserList = () => {
  const [users, setUsers] = React.useState([]);
  const [userListTotal, setUserListTotal] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [owner, setOwner] = React.useState(utils.getUserInfo()); // 현재 사용자
  const userList = useSelector(state => state.ws?.userList);
  const userListT = useSelector(state => state.ws?.userListTotal);

  const onSearch = async (searchInfo) => {
    const tableInfo = {...pagination, page: currentPage, ...searchInfo};
    setTimeout(() => {
      api.getUserList(tableInfo);
    }, 0);
  };

  React.useEffect(() => {
    pagination["searchWord"] = '';
    store.dispatch(toggleHeaderDatepicker(false));
    store.dispatch(filterBadgeCounter(0));
    onSearch().catch();
  }, []);

  React.useEffect(() => {
    let resetUserList = [];
    if (owner) {
      if (userList) {
        for (let key of userList) {
          if (owner["auth"] <= key["user_type"]) {
            resetUserList.push(key);
          }
        }
      }
    }
    setUsers(resetUserList);
    setUserListTotal(userListT);
  }, [userList, userListT]);

  const getCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const deleteUser = async (elm) => {
    await api.userCUD({isDel: true, userId: elm['user_id']}).then(res => {
      onSearch({page: currentPage}).catch();
    });
  };

  const tableColumns = [
    {
      title: <IntlMessage id={"user"}/>,
      dataIndex: 'user_name',
      width: 350,
      sorter: true,
    },
    {
      title: <IntlMessage id={"email"}/>,
      dataIndex: 'email',
      width: 350,
      sorter: true,
    },
    {
      title: <IntlMessage id={"PhoneNum"}/>,
      dataIndex: 'cphone',
      width: 250,
      sorter: true,
    },
    {
      title: <IntlMessage id={'activate'}/>,
      dataIndex: 'isactivated',
      width: 100,
      render: status => (
        <Tag className="text-capitalize" color={status === 'Y' || status === true ? 'cyan' : 'red'}>
          {(status === 'Y' || status === true) ? labels.ACTIVATE : labels.DEACTIVATE}
        </Tag>
      ),
      sorter: true,
    },
    {
      title: '',
      width: 100,
      dataIndex: 'actions',
      render: (_, elm) => (
        <div className="text-right d-flex justify-content-end" style={{height: '5px', alignItems: 'center'}}>
          <Tooltip title="View">
            <UserFormInDrawer title={labels.USER_EDIT} elm={elm} onSearchUser={onSearch}/>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm placement="leftTop" title={<IntlMessage id={"DELETE_TITLE"}/>}
                        disabled={elm.user_id === utils.getUserInfo().user_id}
                        onConfirm={() => {
                          deleteUser(elm).catch();
                        }}
                        okText={<IntlMessage id={"YES"}/>} cancelText={<IntlMessage id={"NO"}/>}>
              <Button danger icon={<DeleteOutlined/>} disabled={elm.user_id === utils.getUserInfo().user_id} size="small"/>
            </Popconfirm>
          </Tooltip>
        </div>
      )
    }
  ];
  return (
    <div>
      <DataTableView
        columns={tableColumns}
        dataSource={users}
        rowKey='user_id'
        totalCount={userListTotal}
        onSearch={onSearch}
        getCurrentPage={getCurrentPage}
        showSearchWord={true}
        title={labels.USER_ADD}
      />
    </div>
  );
};

export default UserList;
