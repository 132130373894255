import React from 'react'
import {Button, Popconfirm, Tag, Tooltip} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {labels} from '../Labels';
import {useSelector} from 'react-redux';
import {store} from '../../App';
import {filterBadgeCounter, toggleHeaderDatepicker} from '../../redux/actions';
import DataTableView from '../../components/uplink-components/DataTableView';
import {pagination} from '../../utils/helper';
import utils from '../../utils';
import AddDeviceWizard from 'views/testbed/components/AddDeviceWizard';
import TestBedDeviceEditDrawer from './components/TestBedDeviceEditDrawer';
import TestBedDeviceViewDrawer from './components/TestBedDeviceViewDrawer';
import IntlMessage from '../../components/util-components/IntlMessage';
import {useIntl} from 'react-intl';
import * as api from 'services';

export const TestBedDevice = (props) => {
  const [deviceList, setDeviceList] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchData, setSearchData] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const branchId = useSelector(state => state.user?.testBedBranchId);
  const currentPageRef = React.useRef(1);
  const testBedDeviceList = useSelector(state => state.testBed?.testBedDeviceList);
  const testBedDeviceListTotal = useSelector(state => state.testBed?.testBedDeviceListTotal);
  const [onAddDeviceWizard, setOnAddDeviceWizard] = React.useState(false);
  const intl = useIntl();
  const prevKey = React.useRef()

  if (prevKey.current !== props.location.key) {
    prevKey.current = props.location.key;
    setIsRefresh(true);
  }

  const onSearch = async (searchInfo) => {
    setSearchData(searchInfo);
    const tableInfo = {...pagination, page: currentPage, branchId, ...searchInfo};
    await api.getTestBedDeviceList(tableInfo);
  };

  React.useEffect(() => {
    if (isRefresh && branchId !== undefined) {
      setOnAddDeviceWizard(false);
      onSearch({page: 1, searchWord: searchData?.searchWord}).catch();
    }
    if (!isRefresh && branchId === undefined) {
      setIsRefresh(true);
    }
  }, [branchId, isRefresh]);

  React.useEffect(() => {
    pagination['searchWord'] = '';
    store.dispatch(toggleHeaderDatepicker(false));
    store.dispatch(filterBadgeCounter(0));
  }, []);

  React.useEffect(() => {
    const deviceList = testBedDeviceList;
    let list = [];
    if (deviceList) {
      for (let key of deviceList) {
        list.push(key);
      }
    }
    setDeviceList(list);
    setTotalCount(testBedDeviceListTotal);
  }, [testBedDeviceList, testBedDeviceListTotal]);

  const setRefresh = (data) => {
    const {isRefresh, page} = data
    setSearchData(data);
    setIsRefresh(isRefresh);
    setCurrentPage(page);
  };

  const getCurrentPage = (page) => {
    currentPageRef.current = page;
    setCurrentPage(page);
  };

  const deleteDevice = async (elm) => {
    const data = {
      branchId: branchId,
      isDel: true,
      deviceId: elm.device_id,
      deviceTypeId: elm.device_type_id,
    }
    await api.setTestBedDevice(data).then(() => onSearch({page: currentPage}));
  };

  const tableColumns = [
    {
      title: <IntlMessage id={'create_date'}/>,
      dataIndex: 'created',
      width: 200,
      render: (_, record) => (
        <div className="d-flex">
          {utils.formatDTS(record['created'])}
        </div>
      ),
      sorter: true,
    },
    {
      title: <IntlMessage id={'device_name'}/>,
      dataIndex: 'device_name',
      width: 220,
      sorter: true,
    },
    {
      title: <IntlMessage id={'serial'}/>,
      dataIndex: 'serial',
      width: 150,
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 100,
      render: (_, elm, index) => (
        <div className="text-right d-flex justify-content-end" style={{height: '5px', alignItems: 'center'}}>
          <TestBedDeviceViewDrawer title={labels.DEVICE_VIEW_ENG} elm={elm} onSearch={onSearch}/>
          <TestBedDeviceEditDrawer title={labels.DEVICE_EDIT_ENG} deviceData={deviceList} elm={elm} elmIndex={index}
                                   onSearch={onSearch}/>
          <Tooltip title="Delete">
            <div style={{float: 'left'}}>
              <Popconfirm placement="leftTop" title={intl.formatMessage({id: 'error_testbed_device'})}
                          onConfirm={() => {
                            deleteDevice(elm).catch();
                          }} okText={<IntlMessage id={'YES'}/>} cancelText={<IntlMessage id={'NO'}/>}>
                <Button danger icon={<DeleteOutlined/>} size="small"/>
              </Popconfirm>
            </div>
          </Tooltip>
        </div>
      )
    }
  ];
  return (
    <div>
      {
        onAddDeviceWizard ?
          <AddDeviceWizard setOnAddDeviceWizard={setOnAddDeviceWizard} deviceData={deviceList} onSearch={onSearch}/> :
          <DataTableView
            columns={tableColumns}
            dataSource={deviceList}
            rowKey="created"
            totalCount={totalCount}
            onSearch={onSearch}
            getCurrentPage={getCurrentPage}
            showSearchWord={true}
            isRefresh={isRefresh}
            setRefresh={setRefresh}
            title={labels.DEVICE_ADD_WIZARD}
            showBranch={true}
            setOnAddDeviceWizard={setOnAddDeviceWizard}
            showSearchDate={false}
          />
      }
    </div>
  );
};

export default TestBedDevice
