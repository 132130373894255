import React from 'react';
import AddDeviceFirstStep from './AddDeviceFirstStep';
import AddDeviceSecondStep from './AddDeviceSecondStep';
import AddDeviceThirdStep from './AddDeviceThirdStep';
import {Steps} from 'antd';
import {FileTextOutlined} from '@ant-design/icons';
import utils from '../../../utils';
import {useIntl} from "react-intl";
import * as api from 'services';
import {useSelector} from 'react-redux';

const {Step} = Steps;

export const AddDeviceWizard = (props) => {
  const [currentStep, setCurrentStep] = React.useState(0);
  const [firstStepFields, setFirstStepFields] = React.useState({
    protocol: undefined,
    deviceKind: undefined,
    deviceName: '',
    serial: '',
    remoteTypeId: undefined,
  });
  const [secondStepFields, setSecondStepFields] = React.useState({sensor: [], actuator: []});
  const branchId = useSelector(state => state.user?.testBedBranchId);
  const intl = useIntl();

  const handleNextButton = () => {
    setCurrentStep(currentStep + 1);
  }

  const handlePreviousButton = () => {
    setCurrentStep(currentStep - 1);
  }

  const handleCancelButton = () => {
    props.setOnAddDeviceWizard(false);
  }

  const handleDoneButton = () => {
    props.setOnAddDeviceWizard(false);
  }

  const handleFinishButton = async () => {
    const userInfo = JSON.parse(localStorage.getItem('user_info'));
    const data = {
      branchId: branchId,
      isDel: false,
      deviceName: firstStepFields.deviceName,
      deviceKind: firstStepFields.deviceKind,
      apiKey: userInfo['api_key'],
      protocol: firstStepFields.protocol,
      serial: firstStepFields.serial,
      remoteTypeId: utils.isActuator(firstStepFields.deviceKind) ? firstStepFields.remoteTypeId : undefined,
      sensor: utils.isSensor(firstStepFields.deviceKind) ? secondStepFields.sensor : undefined,
      actuator: utils.isActuator(firstStepFields.deviceKind) ? secondStepFields.actuator : undefined,
    }
    await api.setTestBedDevice(data).then((res, errMessage) => {
      if (res) {
        props.onSearch({page: 1});
        utils.showSuccess(intl.formatMessage({id: 'SUCCESS_MESSAGE'}));
      }
    })
    setCurrentStep(currentStep + 1);
  }

  const getStepOneValue = (values) => {
    setFirstStepFields({
      ...firstStepFields,
      ...values
    })
  }

  const getStepTwoValue = (values) => {
    setSecondStepFields({
      ...secondStepFields,
      ...values
    })
  }

  const steps = [
    {
      title: intl.formatMessage({id: 'device_info'}),
      content: <AddDeviceFirstStep
        fields={firstStepFields}
        deviceData={props.deviceData}
        handleNextButton={handleNextButton}
        handleCancelButton={handleCancelButton}
        submittedValues={getStepOneValue}/>
    },
    {
      title: intl.formatMessage({id: 'device_setting'}),
      content: <AddDeviceSecondStep
        fields={secondStepFields}
        handlePreviousButton={handlePreviousButton}
        handleFinishButton={handleFinishButton}
        handleCancelButton={handleCancelButton}
        submittedValues={getStepTwoValue}
        deviceKind={firstStepFields.deviceKind}/>
    },
    {
      title: intl.formatMessage({id: 'device_connect'}),
      icon: <FileTextOutlined/>,
      content: <AddDeviceThirdStep
        fields={{...firstStepFields, ...secondStepFields}}
        handleDoneButton={handleDoneButton}/>
    }
  ];

  return (
    <div className="d-flex flex-column align-items-center ">
      <Steps responsive={true} className="bg-white rounded py-2 px-3" style={{maxWidth: '1000px'}}
             current={currentStep}>
        {steps.map(item => (
          <Step key={item.title} title={item.title} icon={item.icon}/>
        ))}
      </Steps>

      <div className="steps-content d-flex mt-4 bg-white rounded p-2 col-md-12"
           style={{maxWidth: '1000px'}}>{steps[currentStep].content}</div>
    </div>
  );
};

export default AddDeviceWizard;
