import React from 'react';
import {Card, Image, Tag} from 'antd';
import {CloseOutlined} from '@ant-design/icons';
import utils from '../../../utils';
import {useIntl} from 'react-intl';
import {labels} from '../../Labels';

const CardControllerData = ({title, onDelete, items}) => {
  const intl = useIntl();

  return (
    <Card title={intl.formatMessage({id: 'controller_status'})}
          bodyStyle={{padding: '0px 10px'}}
          extra={<a href="/#" className="text-gray font-size-lg" onClick={onDelete}><CloseOutlined/></a>}>
      <div className={'d-flex'} style={{justifyContent: 'space-around'}}>
        <div className={'d-flex flex-column'} style={{marginTop: '25px'}}>
          <h4 className="mb-0" style={{fontWeight: 'bold'}}>{title}</h4>
          <Tag style={{padding: '0px 5px', margin: '0px'}} color={items?.currentVal === 'ON' ? 'cyan' : 'lightgray'}>
            {utils.formatDTS(items?.updated)}
          </Tag>
        </div>
        <Image width={150} height={150} src={items?.iconUrl} preview={false}/>

      </div>
    </Card>
  );
};

export default CardControllerData;
