import React from 'react'
import {Tag, Tooltip, Button, Popconfirm} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {labels} from '../Labels';
import DeviceFormInDrawer from '../../components/uplink-components/DeviceFormInDrawer';
import {store} from '../../App';
import {filterBadgeCounter, toggleHeaderDatepicker} from '../../redux/actions';
import DataTableView from '../../components/uplink-components/DataTableView';
import {pagination} from '../../utils/helper';
import DeviceViewFormInDrawer from '../../components/uplink-components/DeviceViewFormInDrawer';
import {useSelector} from 'react-redux';
import * as api from 'services';
import utils from '../../utils';
import {useIntl} from "react-intl";

export const Device = (props) => {
  const isTestBed = utils.isTestBed();
  const [deviceList, setDeviceList] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchData, setSearchData] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const branchId = useSelector(state => isTestBed ? state.user?.testBedBranchId : state.user?.branchId);
  const currentPageRef = React.useRef(1);
  const [isMounted, setIsMounted] = React.useState(false);
  const wsData = useSelector(state => state.ws);
  const prevKey = React.useRef()
  const intl = useIntl();

  if (prevKey.current !== props.location.key) {
    prevKey.current = props.location.key;
    setIsRefresh(true);
  }

  const onSearch = async (searchInfo) => {
    setSearchData(searchInfo);
    const tableInfo = {...pagination, page: currentPage, branchId, ...searchInfo};
    return await api.getDeviceList(tableInfo);
  };

  React.useEffect(() => {
    if (isRefresh && branchId !== undefined) {
      onSearch({page: 1, searchWord: searchData?.searchWord}).then((res) => {
        if (res) {
          setDeviceList(res.data);
          setTotalCount(res.totalCount);
        }
      });
    }
    if (!isRefresh && branchId === undefined) {
      setIsRefresh(true);
    }
  }, [branchId, isRefresh]);

  React.useEffect(() => {
    if (isMounted) {
      const deviceList = wsData.deviceList;
      let list = [];
      if (deviceList) {
        for (let key of deviceList) {
          list.push(key);
        }
      }
      setDeviceList(list);
      setTotalCount(wsData.deviceListTotal);

    } else {

      setIsMounted(true);
    }
  }, [wsData.deviceList, wsData.deviceListTotal]);

  React.useEffect(() => {
    pagination['searchWord'] = '';
    store.dispatch(toggleHeaderDatepicker(false));
    store.dispatch(filterBadgeCounter(0));
  }, []);

  const setRefresh = (data) => {
    const {isRefresh, page} = data
    setSearchData(data);
    setIsRefresh(isRefresh);
    setCurrentPage(page);
  };

  const getCurrentPage = (page) => {
    currentPageRef.current = page;
    setCurrentPage(page);
  };

  const deleteDevice = async elm => {
    await api.deviceCUD({deviceId: elm['device_id'], isDel: true}).then(res => {
      onSearch({page: currentPage}).catch();
    });
  };

  const tableColumns = [
    {
      title: intl.formatMessage({id: 'branch_name'}),
      dataIndex: 'branch_name',
      width: 200,
      sorter: true,
    },
    {
      title: intl.formatMessage({id: 'houseName'}),
      dataIndex: 'house_name',
      width: 280,
      sorter: true,
    },
    {
      title: intl.formatMessage({id: 'device_name'}),
      dataIndex: 'device_name',
      width: 220,
      sorter: true,
    },
    {
      title: intl.formatMessage({id: 'DeviceType'}),
      dataIndex: 'device_type_name',
      width: 200,
      sorter: true,
    },
    {
      title: intl.formatMessage({id: 'serial'}),
      dataIndex: 'serial',
      width: 150,
    },
    {
      title: intl.formatMessage({id: 'activate'}),
      dataIndex: 'isactivated',
      width: 100,
      render: status => (
        <Tag className="text-capitalize" color={status === 'Y' || status === true ? 'cyan' : 'red'}>
          {(status === 'Y' || status === true) ? labels.ACTIVATE : labels.DEACTIVATE}
        </Tag>
      ),
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 100,
      render: (_, elm) => (
        <div className="text-right d-flex justify-content-end" style={{height: '5px', alignItems: 'center'}}>
          <Tooltip title="View">
            <DeviceViewFormInDrawer title={labels.DEVICE_VIEW} elm={elm} houseList={''} onSearch={onSearch}/>
          </Tooltip>
          <Tooltip title="View">
            <DeviceFormInDrawer title={labels.DEVICE_EDIT} elm={elm} houseList={''} onSearch={onSearch}
                                page={currentPageRef.current}/>
          </Tooltip>
          <Tooltip title="Delete">
            <div style={{float: 'left'}}>
              <Popconfirm placement="leftTop" title={intl.formatMessage({id: 'DELETE_TITLE'})} onConfirm={() => {
                deleteDevice(elm).catch();
              }} okText={intl.formatMessage({id: 'YES'})} cancelText={intl.formatMessage({id: 'NO'})}>
                <Button danger icon={<DeleteOutlined/>} size="small"/>
              </Popconfirm>
            </div>
          </Tooltip>
        </div>
      )
    }
  ];
  return (
    <div>
      <DataTableView
        columns={tableColumns}
        dataSource={deviceList}
        rowKey="serial"
        totalCount={totalCount}
        onSearch={onSearch}
        getCurrentPage={getCurrentPage}
        showSearchWord={true}
        isRefresh={isRefresh}
        setRefresh={setRefresh}
        title={labels.DEVICE_ADD}
        showBranch={true}
      />
    </div>
  );
};

export default Device
