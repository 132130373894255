import utils from 'utils'
import {store} from '../App';
import {
  TestBedDeviceDataList,
  TestBedDeviceDataListTotal,
  TestBedDeviceList,
  TestBedDeviceListTotal,
  TestBedDeviceTypeItems,
  TestBedDeviceTypeList,
  TestBedDeviceTypeListTotal,
  TestBedDeviceViewList,
  TestBedDeviceViewListTotal,
  TestBedSmartRoutineList,
  TestBedSmartRoutineListTotal
} from '../redux/actions/TestBed';
import {Controllers} from '../redux/actions/WS';
import API from './API';

export const getTestBedDeviceTypeList = async (data) => {
  const token = utils.getToken();
  const action = 'GetTestBedDeviceTypeList';
  const reqData = {action, token, data};
  return API.send('tbed/R', reqData).then(res => {
    store.dispatch(TestBedDeviceTypeList(res?.data));
    store.dispatch(TestBedDeviceTypeListTotal(res?.totalCount));
  })
}

export const getTestBedDeviceList = async (data) => {
  const token = utils.getToken();
  const action = 'GetTestBedDeviceList';
  const reqData = {action, token, data};
  API.send('tbed/R', reqData).then(res => {
    store.dispatch(TestBedDeviceList(res?.data));
    store.dispatch(TestBedDeviceListTotal(res?.totalCount));
  })
}

export const getTestBedSmartRoutineList = async (data) => {
  const token = utils.getToken();
  const action = 'GetTestBedSmartRoutineList';
  const reqData = {action, token, data};
  return API.send('tbed/R', reqData).then(res => {
    store.dispatch(TestBedSmartRoutineList(res?.data));
    store.dispatch(TestBedSmartRoutineListTotal(res?.totalCount));
    return res;
  })
}

export const testBedSmartRoutineCUD = async (data) => {
  const token = utils.getToken();
  const action = 'SetTestBedSmartRoutine';
  const reqData = {action, token, data};
  return API.send('tbed/CUD', reqData).then(res => {
    return res;
  })
}

export const getTestBedDeviceDataList = async (data) => {
  const token = utils.getToken();
  const action = 'GetTestBedDeviceDataList';
  const reqData = {action, token, data};
  API.send('tbed/R', reqData).then(res => {
    store.dispatch(TestBedDeviceDataList(res?.data?.dataList));
    store.dispatch(TestBedDeviceTypeItems(res?.data?.items));
    store.dispatch(TestBedDeviceDataListTotal(res?.totalCount));
  })
}

export const getTestBedControllers = async (data) => {
  const token = utils.getToken();
  const action = 'GetControllers';
  const reqData = {action, token, data};
  API.send('tbed/R', reqData).then(res => {
    store.dispatch(Controllers(res?.data));
  })
}

export const setTestBedDevice = async (data) => {
  const token = utils.getToken();
  const action = 'SetTestBedDevice';
  const reqData = {action, token, data};
  return API.send('tbed/CUD', reqData);
}

export const getTestBedDeviceTypeItems = async (data) => {
  const token = utils.getToken();
  const action = 'GetTestBedDeviceTypeItems';
  const reqData = {action, token, data};
  return API.send('tbed/R', reqData).then(res => {
    store.dispatch(TestBedDeviceTypeItems(res?.data));
  })
}

export const getTestBedDeviceViewList = async (data) => {
  const token = utils.getToken();
  const action = 'GetTestBedDeviceView';
  const reqData = {action, token, data};
  return API.send('tbed/R', reqData).then(res => {
    store.dispatch(TestBedDeviceViewList(res?.data));
    store.dispatch(TestBedDeviceViewListTotal(res?.totalCount));
  })
}

export const setTestBedDeviceSendCmd = async (data) => {
  const token = utils.getToken();
  const action = 'SendCmd';
  const reqData = {action, token, data};
  return API.send('tbed/CUD', reqData);
}
