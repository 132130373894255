import React, {useEffect, useState} from "react";
import {Drawer, Form, Button, Col, Row, Input, Switch, Select} from 'antd';
import {labels} from '../../views/Labels';
import {SettingOutlined, PlusOutlined} from '@ant-design/icons';
import {useSelector} from "react-redux";
import util from "../../utils";
import IntlMessage from "../util-components/IntlMessage";
import {useIntl} from "react-intl";
import * as api from 'services';

const {TextArea} = Input;
const {Option} = Select;

const HouseFormInDrawer = ({title, elm, onSearch}) => {
  const [isactivated, setIsactivated] = React.useState(false);
  const [branchOptions, setBranchOptions] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [inputData, setInputData] = React.useState({isDel: false});
  const wsData = useSelector(state => state.ws);
  const [buttonSize, setButtonSize] = React.useState(title === labels.HOUSE_EDIT ? 'small' : null);
  const [form] = Form.useForm();
  const intl = useIntl();

  const onSearchBranch = async () => {
    await api.GetBranchOptions();
  };

  useEffect(() => {
    const branchOptionList = wsData.branchTypeOption;
    let optionList = branchOptionList?.map((item) => {
      return {
        label: item[1],
        value: item[0],
      };
    });
    setBranchOptions(optionList);
  }, [wsData]);

  const showDrawer = (elm) => {
    onSearchBranch().catch();
    resetFields();
    setVisible(true);
    if (elm) {
      const {branch_id, branch_name, house_id, house_name, isactivated, remark} = elm
      setInputData({
        houseId: house_id,
        houseName: house_name,
        branchId: branch_id,
        branchName: branch_name,
        info: remark,
        isactivated
      });
      setIsactivated(isactivated);
      form.setFieldsValue({
        houseId: house_id,
        houseName: house_name,
        branchId: branch_id,
        branchName: branch_name,
        info: remark
      });
    }
  };

  const onClose = () => {
    setVisible(false);
    resetFields();
  };

  const onSubmit = async () => {
    if (inputData) {
      if (inputData['branchName'] === undefined) {
        return util.showWarning(intl.formatMessage({id: "select_branch_name"}));
      }
      if (inputData["houseName"] !== undefined) {
        let dataInfo;
        dataInfo = {...inputData};
        setVisible(false);
        await api.houseCUD(dataInfo).then(res => {
          if (res) {
            onSearch();
            resetFields();
            util.showSuccess(intl.formatMessage({id: 'success_save'}));
          }
        });
      }
    }
  };

  const resetFields = () => {
    form.resetFields();
  };

  const handleInputData = e => {
    const name = e.target.id;
    const value = e.target.value;
    setInputData({...inputData, [name]: value});
  };

  const onBranchChange = (value, e) => {
    setInputData({...inputData, branchId: value, branchName: e.children});
  };

  const handleUserActivativeSwitch = value => {
    setIsactivated(value);
    setInputData({...inputData, isactivated: value});
  };

  return (
    <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
      <Button type="primary"
              icon={title === labels.HOUSE_EDIT ? <SettingOutlined/> : <PlusOutlined/>}
              onClick={() => showDrawer(elm)} size={buttonSize}>
        {title === labels.HOUSE_EDIT ? undefined : title}
      </Button>
      <Drawer
        title={title}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={() => onClose(elm)}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="houseName" label={<IntlMessage id={"houseName"}/>}>
                <Input required={true} onChange={handleInputData}
                       placeholder={intl.formatMessage({id: "write_house_name"})}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="branchId" label={<IntlMessage id={"branchList"}/>}>
                <Select required={true} onChange={onBranchChange}
                        placeholder={intl.formatMessage({id: "select_branch_name"})}>
                  {branchOptions?.map((type, key) => {
                    return (
                      <Option value={type.value} name={type.value} key={key}>{type.label}</Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12} style={{paddingLeft: 20}}>
              <Form.Item name="isactivated" label={<IntlMessage id={'activate'}/>}>
                <Switch onChange={handleUserActivativeSwitch} checked={isactivated}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="info" label={<IntlMessage id={"information"}/>}>
                <TextArea rows={10} onChange={handleInputData}/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className='drawer-form-buttons'>
            <Button onClick={() => onClose(elm)} style={{marginRight: 8}}><IntlMessage id={"cancel"}/></Button>
            <Button htmlType="submit" type="primary">
              {title === labels.HOUSE_EDIT ? <IntlMessage id={'edit'}/> : <IntlMessage id={'add'}/>}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );

}

export default HouseFormInDrawer;
