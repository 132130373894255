import utils from 'utils';
import API from './API';
import _ from 'lodash';

export const getItemInfo = async (data) => {
  return API.sendList('dashboard/R', {action: 'GetItemInfo', token: utils.getToken(), data});
}

export const getTestBedItemInfo = async (data) => {
  return API.sendList('tbed/R', {action: 'GetItemInfo', token: utils.getToken(), data});
}

export const getGraphInfo = async (data) => {
  return API.sendList('dashboard/R', {action: 'GetGraphInfo', token: utils.getToken(), data});
}

export const getTestBedGraphInfo = async (data) => {
  return API.sendList('tbed/R', {action: 'GetGraphInfo', token: utils.getToken(), data});
}

export const getControllerInfo = async (data) => {
  return API.sendList('dashboard/R', {action: 'GetControllerInfo', token: utils.getToken(), data});
}

export const getTestBedControllerInfo = async (data) => {
  return API.sendList('tbed/R', {action: 'GetControllerInfo', token: utils.getToken(), data});
}

export const getUserUI = async (data) => {
  return API.sendList(data?.isTestBed ? 'tbed/R' : 'user/R', {action: 'GetUserUI', token: utils.getToken(),
    data: _.omit(data, ['isTestBed'])});
}

export const getErrorDevice = async (data) => {
  return API.sendList('dashboard/R', {action: 'GetErrorDevice', token: utils.getToken(), data});
}

export const getTestBedErrorDevice = async (data) => {
  return API.sendList('tbed/R', {action: 'GetErrorDevice', token: utils.getToken(), data});
}

export const getEventLog = async (data) => {
  return API.sendList('dashboard/R', {action: 'GetEventLog', token: utils.getToken(), data});
}

export const getTestBedEventLog = async (data) => {
  return API.sendList('tbed/R', {action: 'GetEventLog', token: utils.getToken(), data});
}
