import React from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import {BRANCH_TYPE_MAP, USER_TYPE_NUMBER_MAP} from '../../../constants/UplinkConstant';
import {Card, Select, Table} from 'antd';
import utils from '../../../utils';
import * as api from '../../../services';
import {setBranchId, setBranchType} from '../../../redux/actions/User';

const {Option} = Select;
const auth = utils.getAuth();

const SION_DEVICE_KEY_MAP = {
  'temperature': 'temperature',
  'humidity': 'humidity',
  'main_door': 'main_door',
  'door': 'door',
  'living_room_light': 'living_room_light',
}

export const SionDashboardTable = ({locationKey}) => {
  const branchId = useSelector(state => state.user?.branchId);
  const branchList = useSelector(state => state.ws?.branchList);
  const [branchOptions, setBranchOptions] = React.useState([]);
  const [isRefresh, setIsRefresh] = React.useState(true);
  const sionDashboardData = useSelector(state => state.sion?.sionDashboardHouseList);
  const [tableData, setTableData] = React.useState([]);
  const intl = useIntl();
  const dispatch = useDispatch();
  const prevKey = React.useRef();

  if (prevKey.current !== locationKey) {
    prevKey.current = locationKey;
    setIsRefresh(true);
  }

  React.useEffect(() => {
    if (isRefresh) {
      getSionDashboardData().catch();
      setIsRefresh(false);
    }
  }, [isRefresh]);

  const onGetBranchList = async () => {
    await api.getBranchList({isTestbed: false});
  };

  React.useEffect(() => {
    if (auth === USER_TYPE_NUMBER_MAP.super_admin) {
      onGetBranchList().catch();
    }
  }, []);

  const getSionDeviceData = (deviceList = []) => {
    let sionDeviceData = {};
    let gLines = {};
    deviceList.forEach((device) => {
        switch (device.device_name) {
          case '현관문':
            sionDeviceData = {...sionDeviceData, main_door: device?.jvalue.contact}
            break;
          case '도어':
            sionDeviceData = {...sionDeviceData, door: device?.jvalue.contact}
            break;
          case '거실등':
            sionDeviceData = {...sionDeviceData, living_room_light: device?.jvalue.state === "ON"}
            break;
          default:
            sionDeviceData = {...sionDeviceData, ...device?.jvalue};
            gLines = {
              ...gLines,
              [SION_DEVICE_KEY_MAP[device?.jsensor[0]?.sensorType]]: device?.jsensor[0]?.sensorGline || []
            };
            break;
        }
      }
    )

    return {sionDeviceData, gLines};
  };

  React.useEffect(() => {
    const tableDataList = [];
    sionDashboardData.forEach((data) => {
      const {sionDeviceData, gLines} = getSionDeviceData(data?.device_list);
      const tableRowData = {houseId: data?.house_id, houseName: data?.house_name, ...sionDeviceData, gLines};
      tableDataList.push(tableRowData);
    })
    setTableData(tableDataList);
  }, [sionDashboardData]);

  React.useEffect(() => {
    if (branchList?.length > 0) {
      const newBranchList = branchList.map((data) => {
        return (
          {label: data.branch_name, value: data.branch_id, type: data.branch_type}
        )
      });
      setBranchOptions(newBranchList);
      const matchedBranch = branchList.find((branch) => branch?.branch_id === branchId);
      if (matchedBranch === undefined) {
        dispatch(setBranchType(newBranchList[0]?.type))
        dispatch(setBranchId(newBranchList[0]?.value))
      } else {
        dispatch(setBranchType(matchedBranch.branch_type))
      }
    }
  }, [branchList]);

  const getSionDashboardData = async (searchInfo) => {
    const data = {branchId, ...searchInfo};
    api.getSionDashboardHouseList(data).catch();
  };

  const handleSelectBranch = (value) => {
    const selectedBranchOption = branchOptions.find((branchOption) => branchOption.value === value);
    if (selectedBranchOption !== undefined) {
      dispatch(setBranchType(selectedBranchOption.type));
    }
    dispatch(setBranchId(value));
    if (selectedBranchOption.type === BRANCH_TYPE_MAP.sion) {
      getSionDashboardData({branchId: value}).catch();
    }
  };

  const renderDeviceValue = (deviceKey, value, gLine) => {
    switch (deviceKey) {
      case SION_DEVICE_KEY_MAP['temperature']:
        if (value) {
          return <span>{`${value}°C`}</span>
        } else {
          return '';
        }
      case SION_DEVICE_KEY_MAP['humidity']:
        if (value) {
          return <span>{`${value}%`}</span>
        } else {
          return '';
        }
      case SION_DEVICE_KEY_MAP['main_door']:
        return (value !== undefined) ?
          <div className={value ? 'text-danger' : 'text-success'}>
            {value ? intl.formatMessage({id: 'closed'}) : intl.formatMessage({id: 'opened'})}
          </div> : ''
      case SION_DEVICE_KEY_MAP['door']:
        return (value !== undefined) ?
          <div className={value ? 'text-danger' : 'text-success'}>
            {value ? intl.formatMessage({id: 'closed'}) : intl.formatMessage({id: 'opened'})}
          </div> : ''
      case SION_DEVICE_KEY_MAP['living_room_light']:
        return (value !== undefined) ?
          <div className={value ? 'text-success' : 'text-gray'}>
            {value ? intl.formatMessage({id: 'on'}) : intl.formatMessage({id: 'off'})}
          </div> : ''
      default:
        return '';
    }
  }

  const tableColumns = [
    {
      title: intl.formatMessage({id: 'house_name'}),
      dataIndex: 'houseName',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.houseName.localeCompare(b.houseName),
    },
    ...Object.values(SION_DEVICE_KEY_MAP).map((deviceKey) => {
      return {
        title: intl.formatMessage({id: deviceKey}),
        dataIndex: deviceKey,
        sorter: (a, b) => a[deviceKey] - b[deviceKey],
        render: (value, rowData) => {
          return renderDeviceValue(deviceKey, value, rowData?.gLines[deviceKey]);
        }
      }
    })
  ];

  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        {auth === USER_TYPE_NUMBER_MAP.super_admin && (
          <Select value={branchId} required={true}
                  onChange={handleSelectBranch}
                  className="dashboard-branch-select">
            {branchOptions?.map((type) => (
              <Option key={type.value} value={type.value} name={type.label}>{type.label}</Option>
            ))}
          </Select>)}
      </div>
      <div style={{width: '100%', height: '100%', marginTop: '5px'}}>
        <Card bodyStyle={{padding: '0px'}}>
          <div className={`table-responsive`}>
            <Table
              columns={tableColumns}
              dataSource={tableData}
              rowKey="houseName"
              pagination={false}
              scroll={{x: 'max-content', y: 600}}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default SionDashboardTable
