import {
  HOUSE_LIST,
  USER_LIST,
  HOUSE_USER_LIST,
  MANAGER_HOUSE_LIST,
  DEVICE_TYPE_LIST,
  DEVICE_LIST,
  HOUSE_LIST_TOTAL,
  USER_LIST_TOTAL,
  HOUSE_USER_LIST_TOTAL,
  MANAGER_HOUSE_LIST_TOTAL,
  DEVICE_TYPE_LIST_TOTAL,
  DEVICE_LIST_TOTAL,
  LOGIN_LOG_LIST_TOTAL,
  ERROR_LOG_LIST_TOTAL,
  LOGIN_LOG_LIST,
  ERROR_LOG_LIST,
  SMART_ROUTINE_LIST,
  SMART_ROUTINE_LIST_TOTAL,
  SMART_ALARM_LIST,
  SMART_ALARM_LIST_TOTAL,
  ICON_LIST,
  ICON_LIST_TOTAL,
  BRANCH_LIST,
  BRANCH_LIST_TOTAL,
  BRANCH_GROUP_LIST,
  BRANCH_GROUP_LIST_TOTAL,
  BRANCH_TYPE_LIST,
  DEVICE_VIEW_LIST,
  DEVICE_VIEW_LIST_TOTAL,
  DEVICE_DATA_LIST,
  DEVICE_DATA_LIST_TOTAL,
  DID_INFO_LIST,
  DID_INFO_LIST_TOTAL,
  CONTROLLERS,
  LOG_IN_LOG,
  LOG_IN_LOG_TOTAL,
  ERROR_LOG,
  ERROR_LOG_TOTAL,
  REMOTE_TYPE_LIST,
  REMOTE_TYPE_TOTAL,
  REMOTE_COMMAND_TYPE_LIST,
  REMOTE_TYPE_OPTION,
  BRANCH_TYPE_OPTION,
  DEVICE_TYPE_ITEMS,
  SELECT_REMOTE_TYPE_LIST,
  SELECT_REMOTE_TYPE_LIST_TOTAL,
  EVENT_LOG,
  EVENT_LOG_TOTAL,
} from '../constants/WS';

const initState = {
  houseList: [],
  userList: [],
  houseUserList: [],
  managerhouselist: [],
  deviceTypeList: [],
  deviceList: [],
  deviceDataList: [],
  loginLogData: [],
  errorLogData: [],
  smartRoutineList: [],
  smartAlarmList: [],
  iconList: [],
  branchList: [],
  branchGroupList: [],
  branchTypeList: [],
  deviceViewList: [],
  dataList: [],
  didInfoList: [],
  selectRemoteTypeList: [],
  selectRemoteTypeListTotal: [],
  controllers: [],
  totalCount: 0,
  eventLog: [],
  eventLogTotal: undefined,
}

const ws = (state = initState, action) => {
  switch (action.type) {
    case HOUSE_LIST:
      return {
        ...state,
        houseList: action.houseData
      }
    case MANAGER_HOUSE_LIST:
      return {
        ...state,
        managerhouselist: action.managerhouseData
      }
    case USER_LIST:
      return {
        ...state,
        userList: action.userData
      }
    case HOUSE_USER_LIST:
      return {
        ...state,
        houseUserList: action.houseUserData
      }
    case DEVICE_TYPE_LIST:
      return {
        ...state,
        deviceTypeList: action.deviceTypeData
      }
    case DEVICE_LIST:
      return {
        ...state,
        deviceList: action.deviceData
      }
    case LOGIN_LOG_LIST:
      return {
        ...state,
        loginLogData: action.loginLogData
      }
    case ERROR_LOG_LIST:
      return {
        ...state,
        errorLogData: action.errorLogData
      }
    case HOUSE_LIST_TOTAL:
      return {
        ...state,
        houseListTotal: action.houseListTotal
      }
    case USER_LIST_TOTAL:
      return {
        ...state,
        userListTotal: action.userListTotal
      }
    case HOUSE_USER_LIST_TOTAL:
      return {
        ...state,
        houseUserListTotal: action.houseUserListTotal
      }
    case MANAGER_HOUSE_LIST_TOTAL:
      return {
        ...state,
        managerHouseListTotal: action.managerHouseListTotal
      }
    case DEVICE_TYPE_LIST_TOTAL:
      return {
        ...state,
        deviceTypeListTotal: action.deviceTypeListTotal
      }
    case DEVICE_LIST_TOTAL:
      return {
        ...state,
        deviceListTotal: action.deviceListTotal
      }
    case LOGIN_LOG_LIST_TOTAL:
      return {
        ...state,
        loginLogListTotal: action.loginLogListTotal
      }
    case ERROR_LOG_LIST_TOTAL:
      return {
        ...state,
        errorLogListTotal: action.errorLogListTotal
      }
    case SMART_ROUTINE_LIST:
      return {
        ...state,
        smartRoutineList: action.smartRoutineList
      }
    case SMART_ROUTINE_LIST_TOTAL:
      return {
        ...state,
        smartRoutineListTotal: action.smartRoutineListTotal
      }
    case SMART_ALARM_LIST:
      return {
        ...state,
        smartAlarmList: action.smartAlarmList
      }
    case SMART_ALARM_LIST_TOTAL:
      return {
        ...state,
        smartAlarmListTotal: action.smartAlarmListTotal
      }
    case ICON_LIST:
      return {
        ...state,
        iconList: action.iconList
      }
    case ICON_LIST_TOTAL:
      return {
        ...state,
        iconListTotal: action.iconListTotal
      }
    case BRANCH_LIST:
      return {
        ...state,
        branchList: action.branchList
      }
    case BRANCH_LIST_TOTAL:
      return {
        ...state,
        branchListTotal: action.branchListTotal
      }
    case BRANCH_GROUP_LIST:
      return {
        ...state,
        branchGroupList: action.branchGroupList
      }
    case BRANCH_GROUP_LIST_TOTAL:
      return {
        ...state,
        branchGroupListTotal: action.branchGroupListTotal
      }
    case BRANCH_TYPE_LIST:
      return {
        ...state,
        branchTypeList: action.branchTypeList
      }
    case DEVICE_VIEW_LIST:
      return {
        ...state,
        deviceViewList: action.deviceViewList
      }
    case DEVICE_VIEW_LIST_TOTAL:
      return {
        ...state,
        deviceViewListTotal: action.deviceViewListTotal
      }
    case DEVICE_DATA_LIST:
      return {
        ...state,
        deviceDataList: action.deviceDataList
      }
    case DEVICE_DATA_LIST_TOTAL:
      return {
        ...state,
        deviceDataListTotal: action.deviceDataListTotal
      }
    case DEVICE_TYPE_ITEMS:
      return {
        ...state,
        deviceTypeItems: action.deviceTypeItems
      }
    case DID_INFO_LIST:
      return {
        ...state,
        didInfoList: action.didInfoList
      }
    case DID_INFO_LIST_TOTAL:
      return {
        ...state,
        didInfoListTotal: action.didInfoListTotal
      }
    case CONTROLLERS:
      return {
        ...state,
        controllers: action.controllers
      }
    case LOG_IN_LOG:
      return {
        ...state,
        logInLog: action.logInLog,
      };
    case LOG_IN_LOG_TOTAL:
      return {
        ...state,
        logInLogTotal: action.logInLogTotal,
      };
    case ERROR_LOG:
      return {
        ...state,
        errorLog: action.errorLog,
      };
    case ERROR_LOG_TOTAL:
      return {
        ...state,
        errorLogTotal: action.errorLogTotal
      };
    case EVENT_LOG:
      return {
        ...state,
        eventLog: action.eventLog,
      };
    case EVENT_LOG_TOTAL:
      return {
        ...state,
        eventLogTotal: action.eventLogTotal
      };
    case REMOTE_TYPE_LIST:
      return {
        ...state,
        remoteTypeList: action.remoteTypeList
      };
    case REMOTE_TYPE_TOTAL:
      return {
        ...state,
        remoteTypeTotal: action.remoteTypeTotal
      }
    case REMOTE_COMMAND_TYPE_LIST:
      return {
        ...state,
        remoteCommandTypeList: action.remoteCommandTypeList,
      };
    case REMOTE_TYPE_OPTION:
      return {
        ...state,
        remoteTypeOption: action.remoteTypeOption,
      };
    case BRANCH_TYPE_OPTION:
      return {
        ...state,
        branchTypeOption: action.branchTypeOption,
      };
    case SELECT_REMOTE_TYPE_LIST:
      return {
        ...state,
        selectRemoteTypeList: action.selectRemoteTypeList,
      }
    case SELECT_REMOTE_TYPE_LIST_TOTAL:
      return {
        ...state,
        selectRemoteTypeListTotal: action.selectRemoteTypeListTotal,
      }
    default:
      return state;
  }
}

export default ws;
