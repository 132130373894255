import React from "react";
import {LogoutOutlined} from '@ant-design/icons';
import IntlMessage from "../util-components/IntlMessage";
import utils from 'utils';

export const NavLogout = () => {
	const onLogout = () => {
	  utils.logout().catch();
  };
	return (
    <div style={{cursor: 'pointer', paddingRight: 20}} onClick={onLogout}>
      <LogoutOutlined className="mr-2"/><span className="font-weight-normal"><IntlMessage id={"logout"}/></span>
    </div>
	);
}

export default NavLogout;
