import React from 'react'
import {Button, Col, Form, Row, Table, Tooltip} from 'antd';
import TestBedSensorDrawer from './TestBedSensorDrawer';
import {labels} from '../../Labels';
import {DeleteOutlined} from '@ant-design/icons';
import {useIntl} from "react-intl";

export const TestBedSensorTable = (props) => {
  const intl = useIntl();
  const deleteSensor = (index) => {
    const clonedSensor = [...props.sensorData];
    clonedSensor.splice(index, 1);
    props.submittedValues({sensor: clonedSensor});
  }

  const getSensorData = (data, index) => {
    const clonedSensor = [...props.sensorData];
    if (index !== undefined) {
      clonedSensor[index] = data;
    } else {
      clonedSensor.push(data);
    }
    props.submittedValues({sensor: clonedSensor});
  };

  const sensorTableColumns = [
    {
      title:  intl.formatMessage({id: 'key'}),
      dataIndex: 'sensorType',
      width: 130,
    },
    {
      title: intl.formatMessage({id: 'display_name'}), // title:"Display Name"이라고 되어 있었슴
      dataIndex: 'sensorName',
      width: 130,
    },
    {
      title:intl.formatMessage({id: 'UNIT'}),
      dataIndex: 'sensorUnit',
      width: 130,
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 120,
      render: (_, elm, index) => {
        return (
          <div className="text-right d-flex justify-content-center align-items-center">
            <Tooltip title="View">
              <TestBedSensorDrawer
                title={labels.SENSOR_EDIT_ENG}
                elm={elm}
                elmIndex={index}
                sensorData={props.sensorData}
                getSensorData={getSensorData}/>
            </Tooltip>
            <Tooltip title="Delete">
              <Button danger icon={<DeleteOutlined/>} onClick={() => {
                deleteSensor(index)
              }} size="small"/>
            </Tooltip>
          </div>
        )
      }
    }
  ];

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          label={intl.formatMessage({id:'sensors'})}
          initialValue={props.sensorData}
        >
          <Table columns={sensorTableColumns} dataSource={props.sensorData} rowKey="sensorType" bordered
                 pagination={false} size="small" scroll={{x: 'max-content', y: 210}}/>
          <TestBedSensorDrawer title={labels.SENSOR_ADD_ENG}
                               sensorData={props.sensorData}
                               getSensorData={getSensorData}/>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default TestBedSensorTable;
