import React from 'react';
import {Drawer, Form, Button, Col, Row, Input, Switch, Table, Tooltip, Tag, Popconfirm, notification} from 'antd';
import {DeleteOutlined, PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {labels} from '../../Labels';
import {scopeOptions} from '../../../utils/helper';
import TestBedSmartRoutineJtriggerDrawer from './TestBedSmartRoutineJtriggerDrawer';
import TestBedSmartRoutineJactionDrawer from './TestBedSmartRoutineJactionDrawer';
import utils from '../../../utils';
import {useIntl} from "react-intl";
import IntlMessage from "../../../components/util-components/IntlMessage";
import * as api from 'services';
import {useSelector} from "react-redux";

const TestBedSmartRoutineDrawer = ({title, smartRoutineData, elm, elmIndex, onSearch}) => {
  const branchId = useSelector(state => state.user?.testBedBranchId);
  const [isactivated, setIsactivated] = React.useState(false);
  const [jtrigger, setJtrigger] = React.useState([]);
  const [jaction, setJaction] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const buttonSize = (title === labels.SMART_ROUTINE_EDIT_ENG) ? 'small' : null;
  const [form] = Form.useForm();
  const intl = useIntl();

  const showDrawer = (elm) => {
    setVisible(true);
    if (elm) {
      setIsactivated(elm.isactivated);
      setJtrigger(elm.jtrigger);
      setJaction(elm.jaction);
      form.setFieldsValue({routineName: elm.routine_name});
    }
  };

  const onClose = () => {
    setVisible(false);
    resetFields();
  };

  const onFinish = async (values) => {
    const matchedIndex = smartRoutineData.findIndex((smartRoutine) => {
      return smartRoutine.routine_name === values.routineName;
    })

    if (matchedIndex !== -1 && matchedIndex !== elmIndex) {
      notification.warning({message: intl.formatMessage({id: 'error_smart_routine_name'})})
      return;
    }

    if (jtrigger.length === 0) {
      return utils.showWarning(intl.formatMessage({id: 'add_trigger'}));
    } else if (jaction.length === 0) {
      return utils.showWarning(intl.formatMessage({id: 'add_action'}));
    } else {
      setVisible(false);
      const data = {
        isDel: false,
        routineId: elm?.routine_id,
        routineName: values?.routineName,
        isactivated: isactivated,
        jtrigger: jtrigger,
        jaction: jaction,
        branchId
      };
      await api.testBedSmartRoutineCUD(data).then(res => {
        if (res) {
          onSearch();
          resetFields();
          utils.showSuccess(
            intl.formatMessage({id: 'success_save'}));
        }
      });
    }


  }

  const resetFields = () => {
    form.resetFields();
    setIsactivated(false);
    setJtrigger([]);
    setJaction([]);
  };

  const handleUserActivativeSwitch = value => {
    setIsactivated(value);
  };

  const deleteJtrigger = (index) => {
    const clonedJtrigger = [...jtrigger];
    clonedJtrigger.splice(index, 1);
    setJtrigger(clonedJtrigger);
  };

  const deleteJaction = (index) => {
    const clonedJaction = [...jaction];
    clonedJaction.splice(index, 1);
    setJaction(clonedJaction);
  };

  const getJtriggerData = (data, index) => {
    const clonedJtrigger = [...jtrigger];
    if (index !== undefined) {
      clonedJtrigger[index] = data;
    } else {
      clonedJtrigger.push(data);
    }
    setJtrigger(clonedJtrigger);
  };

  const getJactionData = (data, index) => {
    const clonedJaction = [...jaction];
    if (index !== undefined) {
      clonedJaction[index] = data;
    } else {
      clonedJaction.push(data);
    }
    setJaction(clonedJaction);
  };

  const triggerTableColumns = [
    {
      title: <IntlMessage id={'device_name'}/>,
      dataIndex: 'deviceName',
      width: 160,
    },
    {
      title: <IntlMessage id={'sensor'}/>,
      dataIndex: 'sensorName',
      width: 130,
    },
    {
      title: <IntlMessage id={"value"}/>,
      dataIndex: 'value',
      width: 100,
    },
    {
      title: <IntlMessage id={"SCOPE"}/>,
      dataIndex: 'scope',
      width: 80,
      render: (_, record) => (
        <div className="d-flex">{scopeOptions.filter(x => record['scope'] === x.value).map(c => c.label)}</div>
      ),
    },
    {
      title: <IntlMessage id={"time_range"}/>,
      dataIndex: 'time',
      width: 130,
      render: (_, record) => (
        <div className="d-flex">{record['fromTime']} ~ {record['toTime']}</div>
      )
    },
    {
      title: <IntlMessage id={'activate'}/>,
      dataIndex: 'isactivated',
      width: 100,
      render: status => (
        <Tag className="text-capitalize" color={status === 'Y' || status === true ? 'cyan' : 'red'}>
          {(status === 'Y' || status === true) ? labels.ACTIVATE : labels.DEACTIVATE}
        </Tag>
      )
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 120,
      render: (_, elm, index) => (
        <div className="text-right d-flex justify-content-end" style={{height: '5px', alignItems: 'center'}}>
          <Tooltip title="View">
            <TestBedSmartRoutineJtriggerDrawer title={labels.SMART_ROUTINE_JTRIGGER_EDIT_ENG}
                                               elm={elm}
                                               elmIndex={index}
                                               getJtriggerData={getJtriggerData}/>
          </Tooltip>
          <Tooltip title="Delete">
            <div style={{float: 'left'}}>
              <Popconfirm placement="leftTop" title={intl.formatMessage({id: 'DELETE_TITLE'})} onConfirm={() => {
                deleteJtrigger(index)
              }} okText={<IntlMessage id={'YES'}/>} cancelText={<IntlMessage id={'NO'}/>}>
                <Button danger icon={<DeleteOutlined/>} size="small"/>
              </Popconfirm>
            </div>
          </Tooltip>
        </div>
      )
    }
  ];

  const actionTableColumns = [
    {
      title: intl.formatMessage({id: "device_name"}),
      dataIndex: 'deviceName',
      width: 160,
    },
    {
      title: intl.formatMessage({id: "command"}),
      dataIndex: 'commandName',
      width: 140,
    },
    {
      title: intl.formatMessage({id: "value"}),
      dataIndex: 'commandValueName',
      width: 140,
    },
    {
      title: intl.formatMessage({id: 'activate'}),
      dataIndex: 'isactivated',
      width: 100,
      render: status => (
        <Tag className="text-capitalize" color={status === 'Y' || status === true ? 'cyan' : 'red'}>
          {(status === 'Y' || status === true) ? labels.ACTIVATE : labels.DEACTIVATE}
        </Tag>
      ),
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 120,
      render: (_, elm, index) => (
        <div className="text-right d-flex justify-content-end">
          <Tooltip title="View">
            <TestBedSmartRoutineJactionDrawer title={labels.SMART_ROUTINE_JACTION_EDIT_ENG} elm={elm} elmIndex={index}
                                              getJactionData={getJactionData}/>
          </Tooltip>
          <Tooltip title="Delete">
            <div style={{float: 'left'}}>
              <Popconfirm placement="leftTop" title={intl.formatMessage({id: 'DELETE_TITLE'})} onConfirm={() => {
                deleteJaction(index)
              }} okText={<IntlMessage id={'YES'}/>} cancelText={<IntlMessage id={'NO'}/>}>
                <Button danger icon={<DeleteOutlined/>} size="small"/>
              </Popconfirm>
            </div>
          </Tooltip>
        </div>
      )
    }
  ];

  return (
    <div style={{display: 'flex', justifyContent: 'flex-end', marginRight: '8px'}}>
      <Button type="primary"
              icon={title === labels.SMART_ROUTINE_EDIT_ENG ?
                <SettingOutlined/> : <PlusOutlined/>}
              onClick={() => showDrawer(elm)} size={buttonSize}>
        {title === labels.SMART_ROUTINE_EDIT_ENG ? undefined : intl.formatMessage({id: 'smart_routine_add'})}
      </Button>
      <Drawer
        title={title === labels.SMART_ROUTINE_EDIT_ENG ? intl.formatMessage({id: 'smart_routine_edit'}) : intl.formatMessage({id: 'smart_routine_add'})}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={() => onClose(elm)}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout="vertical" hideRequiredMark form={form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={12} style={{paddingLeft: '10px'}}>
              <Form.Item
                name="routineName"
                label={intl.formatMessage({id: "smart_routine_name"})}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({id: 'write_routine'})
                  }
                ]}>
                <Input placeholder={intl.formatMessage({id: "write_smart_routine"})}/>
              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '10px'}}>
              <Form.Item
                name="isactivated"
                label={intl.formatMessage({id: 'activate'})}
                initialValue={isactivated}
                getValueFromEvent={e => handleUserActivativeSwitch(e)}
              >
                <Switch checked={isactivated}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({id: 'smart_routine_jtrigger'})}
                initialValue={jtrigger}
              >
                <Table columns={triggerTableColumns} dataSource={jtrigger} rowKey="deviceId" bordered pagination={false}
                       scroll={{x: 'max-content', y: 210}}/>
                <TestBedSmartRoutineJtriggerDrawer title={labels.SMART_ROUTINE_JTRIGGER_ADD_ENG}
                                                   getJtriggerData={getJtriggerData}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={intl.formatMessage({id: 'smart_routine_jaction'})}
                initialValue={jaction}
              >
                <Table columns={actionTableColumns} dataSource={jaction} rowKey="deviceId" bordered pagination={false}
                       scroll={{x: 'max-content', y: 210}}/>
                <TestBedSmartRoutineJactionDrawer title={labels.SMART_ROUTINE_JACTION_ADD_ENG}
                                                  getJactionData={getJactionData}/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className='drawer-form-buttons'>
            <Button
              onClick={() => onClose(elm)}
              style={{marginRight: 8}}
            >
              {intl.formatMessage({id: 'cancel'})}
            </Button>
            <Button onClick={() => {
            }} htmlType={'submit'} type="primary">
              {title === labels.SMART_ROUTINE_EDIT_ENG ? intl.formatMessage({id: 'edit'}) : intl.formatMessage({id: 'add'})}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default TestBedSmartRoutineDrawer;
