import utils from 'utils';
import {store} from '../App';
import {
  CommandType,
  Controllers,
  DeviceDataList,
  DeviceDataListTotal,
  DeviceList,
  DeviceListTotal,
  DeviceTypeItems,
  DeviceTypeList,
  DeviceTypeListTotal,
  DeviceViewList,
  DeviceViewListTotal,
  RemoteType,
  RemoteTypeOption,
  RemoteTypeTotal,
  SmartRoutineList,
  SmartRoutineListTotal,
  SelectRemoteTypeList,
  SelectRemoteTypeListTotal,
} from '../redux/actions/WS';
import API from './API';

export const getDeviceTypeList = async (data) => {
  const token = utils.getToken();
  const action = 'GetDeviceTypeList';
  const reqData = {action, token, data};
  API.send('device/R', reqData).then(res => {
    store.dispatch(DeviceTypeList(res?.data));
    store.dispatch(DeviceTypeListTotal(res?.totalCount));
  })
}

export const getDeviceTypeArray = async (data) => {
  const token = utils.getToken();
  const action = 'GetDeviceTypeArray';
  const reqData = {action, token, data};
  API.send('device/R', reqData).then(res => {
    store.dispatch(DeviceTypeList(res?.data));
    store.dispatch(DeviceTypeListTotal(res?.totalCount));
  })
}

export const deviceTypeCUD = async (data) => {
  const token = utils.getToken();
  const action = 'SetDeviceType';
  const reqData = {action, token, data};
  return API.send('device/CUD', reqData).then(res => {
    return res;
  })
}

export const getDeviceList = async (data) => {
  const token = utils.getToken();
  const action = 'GetDeviceList';
  const reqData = {action, token, data};
  return API.send('device/R', reqData).then(res => {
    store.dispatch(DeviceList(res?.data));
    store.dispatch(DeviceListTotal(res?.totalCount));
    return res;
  })
}

export const deviceCUD = async (data) => {
  const token = utils.getToken();
  const action = 'SetDevice';
  const reqData = {action, token, data};
  return API.send('device/CUD', reqData).then(res => {
    return res;
  })
}

export const getDeviceViewList = async (data) => {
  const token = utils.getToken();
  const action = 'GetDeviceView';
  const reqData = {action, token, data};
  API.send('device/R', reqData).then(res => {
    store.dispatch(DeviceViewList(res?.data));
    store.dispatch(DeviceViewListTotal(res?.totalCount));
  })
}

export const getDeviceDataList = async (data) => {
  const token = utils.getToken();
  const action = 'GetDeviceDataList';
  const reqData = {action, token, data};
  API.send('device/R', reqData).then(res => {
    store.dispatch(DeviceDataList(res?.data.dataList));
    store.dispatch(DeviceTypeItems(res?.data.items));
    store.dispatch(DeviceDataListTotal(res?.totalCount));
  })
}

export const getDeviceTypeItems = async (data) => {
  const token = utils.getToken();
  const action = 'GetDeviceTypeItems';
  const reqData = {action, token, data};
  return API.send('device/R', reqData).then(res => {
    store.dispatch(DeviceTypeItems(res?.data));
  })
}

export const setDeviceSendCmd = async (data) => {
  const token = utils.getToken();
  const action = 'SendCmd';
  const reqData = {action, token, data};
  API.send('device/CUD', reqData).then(res => {

    // store.dispatch(DeviceDataList(res?.data));
    // store.dispatch(DeviceDataListTotal(res?.totalCount));
  })
}

export const getRemoteTypes = async (data) => {
  const token = utils.getToken();
  const action = 'GetRemoteTypes';
  const reqData = {action, token, data};
  API.send('device/R', reqData).then(res => {
    store.dispatch(SelectRemoteTypeList(res?.data));
    store.dispatch(SelectRemoteTypeListTotal(res?.totalCount));
  })
}

export const getControllers = async (data) => {
  const token = utils.getToken();
  const action = 'GetControllers';
  const reqData = {action, token, data};
  API.send('device/R', reqData).then(res => {
    store.dispatch(Controllers(res?.data));
  })
}

export const getRemoteTypeList = async (data) => {
  const token = utils.getToken();
  const action = 'GetRemoteTypeList';
  const reqData = {action, token, data};
  return API.send('device/R', reqData).then((res) => {
    store.dispatch(RemoteType(res?.data));
    store.dispatch(RemoteTypeTotal(res?.totalCount));
  });
}

// RemoteType 리스트를 출려하기 위한 set
export const remoteTypeListCUD = async (data) => {
  const token = utils.getToken();
  const action = 'SetRemoteType';
  const reqData = {action, token, data};
  return API.send('device/CUD', reqData).then((res) => {
    return res;
  });
}

export const getRemoteCommandList = async (data) => {
  const token = utils.getToken();
  const action = 'GetCommandList';
  const reqData = {action, token, data};
  return API.send('device/R', reqData).then((res) => {
    store.dispatch(CommandType(res?.data));
  });
}

//리모트 타입 옵션을 위한 get
export const GetRemoteTypes = async (data) => {
  const token = utils.getToken();
  const action = 'GetRemoteTypes';
  const reqData = {action, token, data};
  return API.send('device/R', reqData).then((res) => {
    store.dispatch(RemoteTypeOption(res?.data));
  });
}
export const getSmartRoutineList = async (data) => {

  const token = utils.getToken();
  const action = 'GetSmartRoutineList';
  const reqData = {action, token, data};
  return API.send('user/R', reqData).then(res => {
    store.dispatch(SmartRoutineList(res?.data));
    store.dispatch(SmartRoutineListTotal(res?.totalCount));
    return res;
  })
}

export const smartRoutineCUD = async (data) => {
  const token = utils.getToken();
  const action = 'SetSmartRoutine';
  const reqData = {action, token, data};
  return API.send('user/CUD', reqData).then(res => {
    return res;
  })
}
