import React, {useState, useEffect} from 'react';
import {Tooltip} from 'antd';
import {useSelector} from 'react-redux';
import {store} from '../../App';
import {filterBadgeCounter, toggleHeaderDatepicker} from '../../redux/actions';
import DataTableView from '../../components/uplink-components/DataTableView';
import {pagination} from '../../utils/helper';
import util from '../../utils';
import IntlMessage from '../../components/util-components/IntlMessage';
import * as api from 'services';

function LogInLog(props) {
  const [loginLog, setLoginLog] = useState([]); // api로 값을 받을 state
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchData, setSearchData] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const currentPageRef = React.useRef(1);
  const wsData = useSelector((state) => state.ws);
  const [fromDate, setFromDate] = React.useState(util.toStartDateTS(util.getCurrentDate()));
  const [toDate, setToDate] = React.useState(util.toEndDateTS(util.getCurrentDate()));
  const prevKey = React.useRef()

  if (prevKey.current !== props.location.key) {
    prevKey.current = props.location.key;
    setIsRefresh(true);
  }

  const onSearch = async (searchInfo) => {
    setSearchData(searchInfo);
    const tableInfo = {...pagination, page: currentPage, ...searchInfo};
    await api.getLogInLog(tableInfo);
  };

  useEffect(() => {
    if (isRefresh) {
      const fromDt = searchData?.fromDate ? searchData.fromDate : fromDate;
      const toDt = searchData?.toDate ? searchData.toDate : toDate;
      onSearch({page: 1, searchWord: searchData?.searchWord, fromDate: fromDt, toDate: toDt}).catch();
    }
  }, [isRefresh]);

  useEffect(() => {
    pagination['searchWord'] = '';
    store.dispatch(toggleHeaderDatepicker(false));
    store.dispatch(filterBadgeCounter(0));
  }, []);

  useEffect(() => {
    setLoginLog(wsData.logInLog);
    setTotalCount(wsData.logInLogTotal);
  }, [wsData]);

  //refresh를 위한 함수
  const setRefresh = (data) => {
    const {isRefresh, page} = data;
    setSearchData(data);
    setIsRefresh(isRefresh);
    setCurrentPage(page);
  };

  const getCurrentPage = (page) => {
    currentPageRef.current = page;
    setCurrentPage(page);
  };

  const tableColumns = [
    {
      title: <IntlMessage id={'name'}/>,
      dataIndex: 'user_name',
      width: 150,
      sorter: true,
    },
    {
      title: 'IP',
      dataIndex: 'ipaddress',
      width: 200,
      sorter: true,
    },
    {
      title: <IntlMessage id={'keyValue'}/>,
      dataIndex: 'key_str',
      width: 250,
      sorter: true,
    },
    {
      title: <IntlMessage id={'create_date'}/>,
      dataIndex: 'created',
      width: 200,
      sorter: true,
      render: (_, record) => (
        <div className="d-flex">
          {util.formatDTS(record['created'])}
        </div>
      ),
    },
    {
      title: <IntlMessage id={'Updated'}/>,
      dataIndex: 'updated',
      width: 200,
      sorter: true,
      render: (value) => {
        if (value !== 0) {
          return value;
        }
      },
    },
    {
      title: <IntlMessage id={'userAgent'}/>,
      dataIndex: 'useragent',
      width: 200,
      render: (value) => {
        return (
          <Tooltip placement="bottom" title={value}>
            <span>{util.textSubstring(value, 40)} </span>
          </Tooltip>
        );
      },
      sorter: true,
    },
  ];
  return (
    <div>
      <DataTableView
        columns={tableColumns}
        dataSource={loginLog}
        rowKey="created"
        totalCount={totalCount}
        onSearch={onSearch}
        getCurrentPage={getCurrentPage}
        showSearchWord={true}
        showSearchDate={true}
        isRefresh={isRefresh}
        setRefresh={setRefresh}
      />
    </div>
  );
}

export default LogInLog;
