export const mqttSettingHString = (apiKey, deviceSerial, baseTopic) => {
  return `#ifndef UPLINK_MQTT_SETTINGS_H
#define UPLINK_MQTT_SETTINGS_H

#define WIFI_SSID "YOUR_WIFI_SSID"
#define WIFI_PASS "YOUR_WIFI_PASSWORD" 

#define MQTT_SERVER "iotdevice.uplink.plus" 
#define MQTT_SERVER_PORT 8883
#define BASE_TOPIC "${baseTopic}/v1"

#define API_KEY "${apiKey}" 
#define DEVICE_SERIAL "${deviceSerial}"

#endif`
}
