import {SET_BRANCH_ID, SET_BRANCH_TYPE, SET_LOGO, SET_TESTBED_BRANCH_ID,} from '../constants/User';
import utils from 'utils';
import {BRANCH_TYPE_MAP, USER_TYPE_NUMBER_MAP} from '../../constants/UplinkConstant';
import {env} from 'configs/EnvironmentConfig'

const userInfo = utils.getUserInfo();
const initState = {

  logo: userInfo?.user_img || `${env.API_ENDPOINT_URL}/static/images/user/noimage.jpg`,
  branchId: utils.getAuth() === USER_TYPE_NUMBER_MAP.user || utils.getAuth() === USER_TYPE_NUMBER_MAP.sion_user ? utils.getBranchId() : undefined,
  branchType: utils.getAuth() === USER_TYPE_NUMBER_MAP.sion_user
    ? BRANCH_TYPE_MAP.sion
    : utils.getAuth() === USER_TYPE_NUMBER_MAP.testbed_user
      ? BRANCH_TYPE_MAP.testbed
      : BRANCH_TYPE_MAP.home,
  testBedBranchId: utils.getAuth() === USER_TYPE_NUMBER_MAP.testbed_user ? utils.getBranchId() : undefined,
}

const user = (state = initState, action) => {
  switch (action.type) {
    case SET_BRANCH_ID:
      return {
        ...state,
        branchId: action.branchId,
      }
    case SET_BRANCH_TYPE:
      return {
        ...state,
        branchType: action.branchType,
      }
    case SET_TESTBED_BRANCH_ID:
      return {
        ...state,
        testBedBranchId: action.testBedBranchId,
      }
    case SET_LOGO:
      return {
        ...state,
        logo: action.logo,
      }
    default:
      return state;
  }
}

export default user;
