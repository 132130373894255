import React, {useState} from "react";
import {Card, Col, Row, Form, Button} from "antd";
import LoginForm from "components/uplink-components/LoginForm";
import {useSelector} from "react-redux";
import {APP_PREFIX_PATH} from "../../configs/AppConfig";
import RegisterForm from "components/uplink-components/RegisterForm";
import NavLanguage from "../../components/layout-components/NavLanguage";
import {useIntl} from "react-intl";
import IntlMessage from "../../components/util-components/IntlMessage";

const backgroundStyle = {
  backgroundImage: "url(/admin/img/others/img-17.jpg)",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

const Login = () => {
  const theme = useSelector((state) => state.theme.currentTheme);
  const [registered, setRegistered] = useState(true);
  const intl = useIntl();

  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100 w-100">
        <Row justify="center">
          <Col xs={20} sm={15} md={15} lg={6}>
            <Card>
              <Row align="middle"><NavLanguage style={{borderBottom: "none"}}/><IntlMessage id={"language"}/></Row>
              <div className="my-4">
                <div className="text-center">
                  <img
                    className="img-fluid"
                    src={`${APP_PREFIX_PATH}/img/${theme === "light" ? "logo_uplinkx2.png" : "logo-white.png"}`}
                    alt=""
                  />
                </div>
                <Row justify="center">
                  {registered ? (
                    <Col xs={24} sm={24} md={14} lg={14}>
                      <LoginForm allowRedirect={true}/>{" "}
                      {registered &&
                      <Row justify="center">
                        <a type="primary" onClick={() => setRegistered(false)} style={{textDecoration: "underline"}}>
                          {intl.formatMessage({id: "create"})}
                        </a>
                      </Row>
                      }
                    </Col>
                  ) : (
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <RegisterForm setRegistered={() => setRegistered(true)}/>
                    </Col>
                  )}
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
