import React, {Suspense} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import Login from './login/login';
import {APP_PREFIX_PATH} from 'configs/AppConfig'
import Dashboard from './dashboard/Dashboard';
import Data from './list/Data';
import TestBedData from './testbed/TestBedData';
import Device from './list/Device';
import TestBedDevice from './testbed/TestBedDevice';
import UsersList from './list/UserList';
import {SmartRoutine} from './list/SmartRoutine';
import {TestBedSmartRoutine} from './testbed/TestBedSmartRoutine';
import {Branch} from './setting/Branch';
import {House} from './setting/House';
import DeviceType from './list/DeviceType';
import RemoteType from './setting/RemoteType';
import LogInLog from './list/LogInLog'
import ErrorLog from './setting/ErrorLog'
import EventLog from './list/EventLog'
import {USER_TYPE_NUMBER_MAP} from '../constants/UplinkConstant';
import utils from 'utils'
import EmailCheck from './login/EmailCheck';

const owner = utils.getUserInfo();

const Router = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route exact path={APP_PREFIX_PATH + '/login'} component={Login}/>
        <Route exact path={APP_PREFIX_PATH + '/register'} component={Login}/>
        <Route exact path={APP_PREFIX_PATH + '/list/dashboard'} component={Dashboard}/>
        <Route exact path={APP_PREFIX_PATH + '/list/data'} component={Data}/>
        <Route exact path={APP_PREFIX_PATH + '/list/devicetype'} component={DeviceType}/>
        <Route exact path={APP_PREFIX_PATH + '/list/device'} component={Device}/>
        <Route exact path={APP_PREFIX_PATH + '/list/user'} component={UsersList}/>
        <Route exact path={APP_PREFIX_PATH + '/list/smartroutine'} component={SmartRoutine}/>
        <Route exact path={APP_PREFIX_PATH + '/list/eventLog'} component={EventLog}/>
        <Route key="testbed_dashboard" exact path={APP_PREFIX_PATH + '/testbed/dashboard'} component={Dashboard}/>
        <Route exact path={APP_PREFIX_PATH + '/testbed/data'} component={TestBedData}/>
        <Route exact path={APP_PREFIX_PATH + '/testbed/device'} component={TestBedDevice}/>
        <Route exact path={APP_PREFIX_PATH + '/testbed/smartroutine'} component={TestBedSmartRoutine}/>
        <Route key="testbed-eventlog" exact path={APP_PREFIX_PATH + '/testbed/eventLog'} component={EventLog}/>
        <Route exact path={APP_PREFIX_PATH + '/setting/branch'} component={Branch}/>
        <Route exact path={APP_PREFIX_PATH + '/setting/house'} component={House}/>
        <Route exact path={APP_PREFIX_PATH + '/setting/remotetype'} component={RemoteType}/>
        <Route exact path={APP_PREFIX_PATH + '/setting/loginlog'} component={LogInLog}/>
        <Route exact path={APP_PREFIX_PATH + '/setting/errorlog'} component={ErrorLog}/>
        <Route exact path={APP_PREFIX_PATH + '/email_check/*'} component={EmailCheck}/>
        <Redirect from={APP_PREFIX_PATH}
                  to={owner && owner.auth === USER_TYPE_NUMBER_MAP.testbed_user
                    ? APP_PREFIX_PATH + '/testbed/dashboard'
                    : APP_PREFIX_PATH + '/list/dashboard'}/>
      </Switch>
    </Suspense>
  )
};

export default Router;
