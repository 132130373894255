import React from 'react'
import {Tag, Tooltip, Button, Popconfirm} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {labels} from '../Labels';
import {useSelector} from "react-redux";
import DeviceTypeFormInDrawer from "../../components/uplink-components/DeviceTypeFormInDrawer";
import {store} from "../../App";
import {filterBadgeCounter, toggleHeaderDatepicker} from "../../redux/actions";
import DataTableView from "../../components/uplink-components/DataTableView";
import {pagination} from "../../utils/helper";
import IntlMessage from "../../components/util-components/IntlMessage";
import * as api from 'services';
import utils from '../../utils';
import {useIntl} from "react-intl";

export const DeviceType = (props) => {
  const isTestBed = utils.isTestBed();
  const [deviceTypeList, setDeviceTypeList] = React.useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchData, setSearchData] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const branchId = useSelector(state => isTestBed ? state.user?.testBedBranchId : state.user?.branchId);
  const deviceTypeL = useSelector(state => state.ws.deviceTypeList);
  const deviceTypeListTotal = useSelector(state => state.ws.deviceTypeListTotal);
  const prevKey = React.useRef()
  const intl = useIntl();

  if (prevKey.current !== props.location.key) {
    prevKey.current = props.location.key;
    setIsRefresh(true);
  }

  const onSearch = async (searchInfo) => {
    setSearchData(searchInfo);
    const tableInfo = {...pagination, page: currentPage, branchId, ...searchInfo};
    setTimeout(() => {
      api.getDeviceTypeList(tableInfo);
    }, 0);
  };

  React.useEffect(() => {
    if (isRefresh && branchId !== undefined) {
      onSearch({page: 1, searchWord: searchData?.searchWord}).then((res) => {
        if (res) {
          setDeviceTypeList(res.data);
          setTotalCount(res.totalCount);
        }
      });
    }
    if (!isRefresh && branchId === undefined) {
      setIsRefresh(true);
    }
  }, [branchId, isRefresh]);

  React.useEffect(() => {
    let typeList = [];
    const deviceTypeList = deviceTypeL;
    if (deviceTypeList) {
      for (let key of deviceTypeList) {
        typeList.push(key);
      }
    }
    setDeviceTypeList(typeList);
    setTotalCount(deviceTypeListTotal);
  }, [deviceTypeL, deviceTypeListTotal]);


  React.useEffect(() => {
    pagination["searchWord"] = '';
    store.dispatch(toggleHeaderDatepicker(false));
    store.dispatch(filterBadgeCounter(0));
  }, []);

  const setRefresh = (data) => {
    const {isRefresh, page} = data
    setSearchData(data);
    setIsRefresh(isRefresh);
    setCurrentPage(page);
  };


  const getCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const deleteDeviceType = async elm => {
    await api.deviceTypeCUD({deviceTypeId: elm["device_type_id"], isDel: true, branchId}).then(res => {
      onSearch({page: currentPage}).catch();
    });
  };

  const tableColumns = [
    {
      title: intl.formatMessage({id: 'typeName'}),
      dataIndex: 'device_type_name',
      width: 150,
      sorter: true,
    },
    {
      title: intl.formatMessage({id: 'maker'}),
      dataIndex: 'maker',
      width: 100,
      sorter: true,
    },
    {
      title: intl.formatMessage({id: 'activate'}),
      dataIndex: 'isactivated',
      width: 100,
      render: status => (
        <Tag className="text-capitalize" color={status === 'Y' || status === true ? 'cyan' : 'red'}>
          {(status === 'Y' || status === true) ? labels.ACTIVATE : labels.DEACTIVATE}
        </Tag>
      ),
      sorter: true,
    },
    {
      title: '',
      width: 100,
      dataIndex: 'actions',
      render: (_, elm) => (
        <div className="text-right d-flex justify-content-end" style={{height: '5px', alignItems: 'center'}}>
          <Tooltip title="View">
            <DeviceTypeFormInDrawer title={labels.DEVICE_TYPE_EDIT} elm={elm} onSearch={onSearch}/>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm placement="leftTop" title={<IntlMessage id={"DELETE_TITLE"}/>} onConfirm={() => {
              deleteDeviceType(elm).catch();
            }} okText={<IntlMessage id={"YES"}/>} cancelText={<IntlMessage id={"NO"}/>}>
              <Button danger icon={<DeleteOutlined/>} size="small"/>
            </Popconfirm>
          </Tooltip>
        </div>
      )
    }
  ];
  return (
    <div>
      <DataTableView
        columns={tableColumns}
        dataSource={deviceTypeList}
        rowKey='device_type_id'
        totalCount={totalCount}
        onSearch={onSearch}
        getCurrentPage={getCurrentPage}
        showSearchWord={true}
        setRefresh={setRefresh}
        title={labels.DEVICE_TYPE_ADD}
        showBranch={true}
      />
    </div>
  );
};

export default DeviceType
