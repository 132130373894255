import React, {useState} from 'react';
import {Form, Button, Col, Row, Input} from 'antd';
import util from '../../utils';
import {useIntl} from 'react-intl';
import IntlMessage from '../util-components/IntlMessage';
import {LockOutlined, MailOutlined} from '@ant-design/icons';
import * as api from 'services';

function RegisterForm(props) {
  const [email, setEmail] = React.useState();
  const [username, setUsername] = React.useState();
  // const [userid, setUserid] = React.useState();
  const [cphone, setCphone] = React.useState();
  const [form] = Form.useForm();
  const [inputData, setInputData] = React.useState({isDel: false});
  const intl = useIntl();

  const handleInputData = (e) => {
    const name = e.target.id;
    const value = e.target.value;

    setInputData({...inputData, [name]: value});
  };

  const onSubmit = async () => {
    if (inputData) {
      if (inputData.passwd !== inputData.passwdConfirm) {
        util.showError(intl.formatMessage({id: 'write_valid_confirm_password'}));
      } else {
        if (inputData['email'] !== undefined && inputData['userName'] !== undefined && inputData['cphone'] !== undefined) {
          await api.userC(inputData).then((res) => {
            if (res) {
              util.showSuccess(intl.formatMessage({id: 'success_create'}));
              resetFields();
              props.setRegistered();
            }
          });
        }
      }
    }
  };

  const onClose = () => {
    form.resetFields();
    props.setRegistered();
  };

  const resetFields = () => {
    form.resetFields();
    setEmail(undefined);
    setUsername(undefined);
    setCphone(undefined);
    // setUserid(undefined);
    setInputData(undefined);
  };

  return (
    <Form layout="vertical" hideRequiredMark form={form}>
      <Row gutter={32}>
        <Col xs={24} sm={24} lg={24}>
          <Form.Item name="email" label={intl.formatMessage({id: 'email'})} rules={[
            {
              required: true,
              message: <IntlMessage id={'write_email'}/>,
            },
            {
              type: 'email',
              message: <IntlMessage id={'write_valid_email'}/>
            }
          ]}>
            <Input prefix={<MailOutlined className="text-primary"/>} onChange={(e) => handleInputData(e)}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32} justify="space-around">
        <Col xs={24} sm={24} lg={12}>
          <Form.Item name="passwd" label={intl.formatMessage({id: 'password'})}
                     rules={[
                       {
                         required: true,
                         message: <IntlMessage id={'write_password'}/>,
                       }
                     ]}>
            <Input.Password autoComplete="new-password" prefix={<LockOutlined className="text-primary"/>}
                            onChange={(e) => handleInputData(e)}/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item name="passwdConfirm" label={intl.formatMessage({id: 'confirm_password'})}
                     rules={[
                       {
                         required: true,
                         message: <IntlMessage id={'write_confirm_password'}/>,
                       }
                     ]}>
            <Input.Password prefix={<LockOutlined className="text-primary"/>} onChange={(e) => handleInputData(e)}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32} justify="space-around">
        <Col xs={24} sm={24} lg={12}>
          <Form.Item name="userName" label={intl.formatMessage({id: 'company_name'})}
                     rules={[
                       {
                         required: true,
                         message: <IntlMessage id={'write_company_name'}/>,
                       }
                     ]}>
            <Input defaultValue={username} type={'text'} onChange={(e) => handleInputData(e)}/>
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} lg={12}>
          <Form.Item name="cphone" label={intl.formatMessage({id: 'phone_num'})}
                     rules={[
                       {
                         required: true,
                         message: <IntlMessage id={'write_phone_number'}/>,
                       }
                     ]}>
            <Input defaultValue={cphone} type={'tel'} onChange={(e) => handleInputData(e)}/>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item>
        <Row gutter={16} justify="center">
          <Col>
            <Button onClick={() => onClose()}>{intl.formatMessage({id: 'cancel'})}</Button>
          </Col>
          <Col>
            <Button onClick={onSubmit} htmlType={'submit'} type="primary">
              {intl.formatMessage({id: 'create'})}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
}

export default RegisterForm;
