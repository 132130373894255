import React, {useEffect, useState} from 'react';
import {Popover} from 'antd';
import {useSelector} from 'react-redux';
import {store} from '../../App';
import {filterBadgeCounter, toggleHeaderDatepicker} from '../../redux/actions';
import DataTableView from '../../components/uplink-components/DataTableView';
import {pagination} from '../../utils/helper';
import util from '../../utils';
import IntlMessage from '../../components/util-components/IntlMessage';
import * as api from 'services';

function ErrorLog(props) {
  const [errorLog, setErrorLog] = useState([]);
  const [totalCount, setTotalCount] = React.useState(0);
  const [searchData, setSearchData] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isRefresh, setIsRefresh] = React.useState(false);
  const wsData = useSelector((state) => state.ws);
  const [fromDate, setFromDate] = React.useState(util.toStartDateTS(util.toStartDateForErrorLog()));  //30일 전 데이터 부터 출력
  const [toDate, setToDate] = React.useState(util.toEndDateTS(util.getCurrentDate()));

  const onSearch = async (searchInfo) => {
    setSearchData(searchInfo);
    const tableInfo = {...pagination, page: currentPage, ...searchInfo};
    await api.getErrorLog(tableInfo);
  };

  useEffect(() => {
    const fromDt = searchData?.fromDate ? searchData.fromDate : fromDate;
    const toDt = searchData?.toDate ? searchData.toDate : toDate;

    onSearch({page: 1, searchWord: searchData?.searchWord, fromDate: fromDt, toDate: toDt}).catch();
  }, []);

  useEffect(() => {
    pagination['searchWord'] = '';
    store.dispatch(toggleHeaderDatepicker(false));
    store.dispatch(filterBadgeCounter(0));
  }, []);

  useEffect(() => {
    setErrorLog(wsData.errorLog);
    setTotalCount(wsData.errorLogTotal);
    return () => {
      setErrorLog([]);
    };
  }, [wsData]);

  const setRefresh = (data) => {
    const {isRefresh, page} = data;
    setSearchData(data);
    setIsRefresh(isRefresh);
    setCurrentPage(page);
  };

  const getCurrentPage = (page) => {
    setCurrentPage(page);
  };

  // 에러 메세지를 key value 형태로 보여주는 함수, 사용 유무 아직 모름 03/25/2022
  // function parseToPutOver(value) {
  //   try {
  //     let arrValue = value.replace(/False/g, '"false"').replace(/True/g, '"true"').replace(/\'/g, '"');
  //     let arrJSON = JSON.parse(arrValue); //json 내부 key가 ""로 감싸 있어야됨
  //
  //     let keys = Object.keys(arrJSON);
  //     let values = Object.values(arrJSON);
  //
  //     return (
  //       <div style={{display: 'flex'}}>
  //         <div style={{margin: '0 20px'}}>
  //           <div>
  //             {/* map 두개를 겹쳐서 시도 하였으니 안됨, map을 따로 만듬  */}
  //             {keys.map((key, index) => {
  //               return <div key={index}>{key}: </div>;
  //             })}
  //           </div>
  //         </div>
  //         <div>
  //           <div>
  //             {values.map((value, index) => {
  //               return <div key={index}>{value} </div>;
  //             })}
  //           </div>
  //         </div>
  //       </div>
  //     );
  //   } catch (e) {
  //     return <div>{value}</div>
  //   }
  // }

  const tableColumns = [
    {
      title: <IntlMessage id={'create_date'}/>,
      dataIndex: 'created',
      width: 20,
      sorter: true,
      render: (_, record) => (
        <div className="d-flex">
          {util.formatDTS(record['created'])}
        </div>
      ),
    },
    {
      title: <IntlMessage id={'ErrorLog_IP'}/>,
      dataIndex: 'ipaddress',
      width: 30,
      sorter: true,
    },
    {
      title: <IntlMessage id={'error_log_message'}/>,
      dataIndex: 'err_msg',
      width: '70%',
      render: (value) => {

        return (
          <Popover
            overlayStyle={{
              width: '20%',
              fontWeight: 'bold',
            }}
            placement="bottom"
            content={value}
          >
            <span>{util.textSubstring(value, 100)}</span>
          </Popover>
        );
      },
      sorter: true,
    },
  ];

  return (
    <div>
      <DataTableView
        columns={tableColumns}
        dataSource={errorLog}
        rowKey="created"
        onSearch={onSearch}
        getCurrentPage={getCurrentPage}
        totalCount={totalCount}
        showSearchWord={true}
        showSearchDate={true}
        showSearchDateForErrorLog={true}
        isRefresh={isRefresh}
        setRefresh={setRefresh}
        errorLog={true}
      />
    </div>
  );
}

export default ErrorLog;
