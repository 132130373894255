import {
  TESTBED_DEVICE_TYPE_LIST,
  TESTBED_DEVICE_LIST,
  TESTBED_DEVICE_TYPE_LIST_TOTAL,
  TESTBED_DEVICE_LIST_TOTAL,
  TESTBED_SMART_ROUTINE_LIST,
  TESTBED_SMART_ROUTINE_LIST_TOTAL,
  TESTBED_DEVICE_DATA_LIST,
  TESTBED_DEVICE_DATA_LIST_TOTAL,
  TESTBED_DEVICE_TYPE_ITEMS,
  TESTBED_DEVICE_VIEW_LIST,
  TESTBED_DEVICE_VIEW_LIST_TOTAL,
  TESTBED_BRANCH_LIST,
  TESTBED_BRANCH_LIST_TOTAL,
  TESTBED_EVENT_LOG,
  TESTBED_EVENT_LOG_TOTAL,
} from '../constants/TestBed';
import _ from 'lodash';

export const TestBedDeviceTypeList = (data) => {
  return {
    type: TESTBED_DEVICE_TYPE_LIST,
    testBedDeviceTypeData: data
  }
};

export const TestBedDeviceList = (data) => {
  return {
    type: TESTBED_DEVICE_LIST,
    testBedDeviceData: data
  }
};

export const TestBedDeviceTypeListTotal = (totalCount) => {
  return {
    type: TESTBED_DEVICE_TYPE_LIST_TOTAL,
    testBedDeviceTypeListTotal: totalCount
  }
};

export const TestBedDeviceListTotal = (totalCount) => {
  return {
    type: TESTBED_DEVICE_LIST_TOTAL,
    testBedDeviceListTotal: totalCount
  }
};

export const TestBedSmartRoutineList = (data) => {
  return {
    type: TESTBED_SMART_ROUTINE_LIST,
    testBedSmartRoutineList: data
  }
};

export const TestBedSmartRoutineListTotal = (totalCount) => {
  return {
    type: TESTBED_SMART_ROUTINE_LIST_TOTAL,
    testBedSmartRoutineListTotal: totalCount
  }
};

export const TestBedDeviceDataList = (data) => {
  const newData = data?.map((deviceData) => {
    return {..._.omit(deviceData, ['device_data']), ...deviceData?.device_data};
  })
  return {
    type: TESTBED_DEVICE_DATA_LIST,
    testBedDeviceDataList: newData
  }
};

export const TestBedDeviceDataListTotal = (totalCount) => {
  return {
    type: TESTBED_DEVICE_DATA_LIST_TOTAL,
    testBedDeviceDataListTotal: totalCount
  }
};

export const TestBedDeviceTypeItems = (data) => {
  return {
    type: TESTBED_DEVICE_TYPE_ITEMS,
    testBedDeviceTypeItems: data
  }
};

export const TestBedDeviceViewList = (data) => {
  return {
    type: TESTBED_DEVICE_VIEW_LIST,
    testBedDeviceViewList: data
  }
};

export const TestBedDeviceViewListTotal = (totalCount) => {
  return {
    type: TESTBED_DEVICE_VIEW_LIST_TOTAL,
    testBedDeviceViewListTotal: totalCount
  }
}

export const TestBedBranchList = (data) => {
  return {
    type: TESTBED_BRANCH_LIST,
    testBedBranchList: data
  }
};

export const TestBedBranchListTotal = (totalCount) => {
  return {
    type: TESTBED_BRANCH_LIST_TOTAL,
    testBedBranchListTotal: totalCount
  }
}

export const TestBedEventLog = (data) => {
  return {
    type: TESTBED_EVENT_LOG,
    testBedEventLog: data
  }
}

export const TestBedEventLogTotal = (data) => {
  return {
    type: TESTBED_EVENT_LOG_TOTAL,
    testBedEventLogTotal: data
  }
}
