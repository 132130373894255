import React from 'react'
import {Button, Col, Form, Popconfirm, Row, Table, Tooltip} from 'antd';
import TestBedActuatorDrawer from './TestBedActuatorDrawer';
import {labels} from '../../Labels';
import {DeleteOutlined} from '@ant-design/icons';
import IntlMessage from "../../../components/util-components/IntlMessage";
import {useIntl} from "react-intl";

export const TestBedActuatorTable = (props) => {
  const intl = useIntl();
  const deleteActuator = (index) => {
    const clonedActuator = [...props.actuatorData];
    clonedActuator.splice(index, 1);
    props.submittedValues({actuator: clonedActuator});
  }

  const getActuatorData = (data, index) => {
    const clonedActuator = [...props.actuatorData];
    if (index !== undefined) {
      clonedActuator[index] = data;
    } else {
      clonedActuator.push(data);
    }
    props.submittedValues({actuator: clonedActuator});
  };

  const actuatorTableColumns = [
    {
      title: intl.formatMessage({id:'command'}),
      dataIndex: 'commandType',
      width: 150,
    },
    {
      title: intl.formatMessage({id:'description'}),
      dataIndex: 'commandName',
      width: 150,
    },
    {
      title:  intl.formatMessage({id:'values'}),
      dataIndex: 'commandValues',
      width: 300,
      render: (_, record) => (
        <div className="d-flex">
          {record['commandValues']?.map((com, i) => {
            const isComma = record['commandValues'].length - 1 === i ? '' : ',';
            return (
              <div key={com.name}>"{com.value}": "{com.name}" {isComma}</div>
            );
          })}
        </div>
      ),
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 120,
      render: (_, elm, index) => (
        <div className="text-right d-flex justify-content-center align-items-center">
          <Tooltip title="View">
            <TestBedActuatorDrawer
              title={labels.COMMAND_EDIT_ENG}
              elm={elm}
              elmIndex={index}
              actuatorData={props.actuatorData}
              getActuatorData={getActuatorData}/>
          </Tooltip>
          <Popconfirm placement="leftTop" title={intl.formatMessage({id:'DELETE_TITLE'})} onConfirm={() => {
            deleteActuator(index)
          }} okText={<IntlMessage id={'YES'}/>} cancelText={<IntlMessage id={'NO'}/>}>
            <Button danger icon={<DeleteOutlined/>} size="small"/>
          </Popconfirm>
        </div>
      )
    }
  ];

  return (
    <Row gutter={16}>
      <Col span={24}>
        <Form.Item
          label={intl.formatMessage({id:'commands'})}
          initialValue={props.actuatorData}
        >
          <Table columns={actuatorTableColumns} dataSource={props.actuatorData} rowKey="commandType"
                 bordered
                 pagination={false} size="small" scroll={{x: 'max-content', y: 210}}/>
          <TestBedActuatorDrawer title={intl.formatMessage({id:'command_add'})} actuatorData={props.actuatorData}
                                 getActuatorData={getActuatorData}/>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default TestBedActuatorTable;
