import {
  SION_DASHBOARD_HOUSE_LIST
} from '../constants/Sion';


const initState = {
  sionDashboardHouseList: [],
}

const sion = (state = initState, action) => {
  switch (action.type) {
    case SION_DASHBOARD_HOUSE_LIST:
      return {
        ...state,
        sionDashboardHouseList: action.sionDashboardHouseList
      }
    default:
      return state;
  }
}

export default sion;
