import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from 'antd';
import {useSelector} from 'react-redux';
import {APP_PREFIX_PATH} from '../../configs/AppConfig';
import * as api from 'services';

const backgroundStyle = {
  backgroundImage: 'url(/admin/img/others/img-17.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const EmailCheck = (props) => {
  const theme = useSelector((state) => state.theme.currentTheme);
  const [isActivated, setIsActivated] = useState(undefined);

  useEffect(() => {
    api.SetActivateSionUser({api_key: props?.match?.params[0] ?? undefined}).then((res) => {
      if(res?.error_msg === undefined) {
        setIsActivated(true);
      } else {
        setIsActivated(false);
      }
    })
  }, [])

  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100 w-100">
        <Row justify="center">
          <Col xs={20} sm={15} md={15} lg={6}>
            <Card>
              <div className="my-4">
                <div className="text-center">
                  <img
                    className="img-fluid"
                    src={`${APP_PREFIX_PATH}/img/${theme === 'light' ? 'logo_uplinkx2.png' : 'logo-white.png'}`}
                    alt=""
                  />
                </div>
                <Row justify="center">
                  <div>
                    {isActivated === undefined ? "이메일 인증하는 중입니다. 잠시만 기다려 주세요." :
                      isActivated ? "이메일 인증을 완료하였습니다." : "이메일 인증이 실패하였습니다."}
                  </div>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EmailCheck;
