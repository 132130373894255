import utils from '../utils';
import {store} from '../App';
import {sionDashboardHouseList} from '../redux/actions/Sion';
import API from './API';

export const getSionDashboardHouseList = async (data) => {
  const token = utils.getToken();
  const action = 'GetDashboardHouseList';
  const reqData = {action, token, data};

  return API.send('sion/R', reqData).then(res => {
    store.dispatch(sionDashboardHouseList(res?.data));
  })
}
