import React from 'react';
import {BRANCH_TYPE_MAP} from '../../constants/UplinkConstant';
import SionDashboardTable from './components/SionDashboardTable';
import Default from './Default';
import {useSelector} from 'react-redux';
import utils from '../../utils';

export const Dashboard = (props) => {
  const isTestBed = utils.isTestBed();
  const branchType = useSelector(state => state.user?.branchType);

  return (
    <>
      {!isTestBed && branchType === BRANCH_TYPE_MAP.sion
        ? <SionDashboardTable locationKey={props.location.key}/>
        : <Default/>}
    </>
  )
}

export default Dashboard;
