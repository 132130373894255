import React from 'react'
import {
  Card,
  Table,
  Pagination,
  DatePicker,
  Input,
  Button,
  Select,
  notification,
  Popover,
  Checkbox,
  Row,
  Col
} from 'antd';
import {pagination} from '../../utils/helper';
import utils from '../../utils';
import {PlusOutlined, SearchOutlined} from '@ant-design/icons';
import {Resizable} from 'react-resizable';
import DeviceFormInDrawer from './DeviceFormInDrawer';
import RemoteTypeFormInDrawer from './RemoteTypeFormInDrawer';
import {labels} from '../../views/Labels';
import SmartRoutineFormInDrawer from './SmartRoutineFormInDrawer';
import TestBedSmartRoutineDrawer from '../../views/testbed/components/TestBedSmartRoutineDrawer';
import UserFormInDrawer from './UserFormInDrawer';
import BranchFormInDrawer from './BranchFormInDrawer';
import HouseFormInDrawer from './HouseFormInDrawer';
import DeviceTypeFormInDrawer from './DeviceTypeFormInDrawer';
import {useSelector, useDispatch} from 'react-redux';
import {useIntl} from 'react-intl';
import {setBranchId, setTestBedBranchId} from '../../redux/actions/User';
import {CSVLink} from 'react-csv';
import * as api from 'services';

const {Option} = Select;
const owner = utils.getUserInfo();

const ResizableTitle = (props) => {
  const {onResize, width, ...restProps} = props;
  const style = {
    position: 'absolute',
    right: '-5px',
    bottom: 0,
    zIndex: 1,
    width: '10px',
    height: '100%',
    cursor: 'col-resize',
  };
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          style={style}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{enableUserSelectHack: false}}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const DataTableView = (props) => {
  const isTestBed = utils.isTestBed();
  const {Search} = Input;
  const {RangePicker} = DatePicker;
  // Errorlog는 현재가 아닌 30일전 데이터 부터 출력
  const startDateRef = React.useRef(props.errorLog ? utils.toStartDateForErrorLog() : utils.getCurrentDate());
  const toDateRef = React.useRef(utils.getCurrentDate());
  const [current, setCurrent] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [searchWord, setSearchWord] = React.useState();
  const [fromDate, setFromDate] = React.useState(utils.toStartDateTS(startDateRef.current));
  const [toDate, setToDate] = React.useState(utils.toEndDateTS(utils.getCurrentDate()));
  const [isDesc, setIsDesc] = React.useState(pagination.isDesc);
  const [orderBy, setOrderBy] = React.useState(pagination.orderBy);
  const [columns, setColumns] = React.useState();
  const [branchOptions, setBranchOptions] = React.useState([]);
  const branchId = useSelector(state => isTestBed ? state.user?.testBedBranchId : state.user?.branchId);
  const [selectedColumnFilterOptions, setSelectedColumnFilterOptions] = React.useState([]);
  const branchList = useSelector(state => isTestBed ? state.testBed?.testBedBranchList : state.ws?.branchList);
  const intl = useIntl();
  const dispatch = useDispatch()
  const [filterBoxVisiable, setFilterBoxVisiable] = React.useState();
  const [exportedData, setExportedData] = React.useState([])
  const [emptySelectedFilter, setEmptySelectedFilter] = React.useState(false);


  const onGetBranchList = async () => {
    await api.getBranchList(isTestBed ? {isTestbed: true} : {isTestbed: false});
  };

  const onChangeWord = (e) => {
    pagination['searchWord'] = e.target.value;
    setSearchWord(e.target.value);
  };

  const onChange = (value) => {
    if (props.getCurrentPage) {
      props.getCurrentPage(value);
    }
    setCurrent(value);
    if (props.showSearchDate) {
      props.onSearch({
        page: value,
        rowCount: props.pageSize ?? pagination.rowCount,
        searchWord,
        fromDate,
        toDate,
        isDesc,
        orderBy
      });
    } else {
      props.onSearch({
        page: value,
        rowCount: props.pageSize ?? pagination.rowCount,
        searchWord,
        isDesc,
        orderBy
      });
    }
  };

  const onSearchWord = () => {
    setCurrent(1);
    if (props.showSearchDate) {
      props.onSearch({
        page: 1, searchWord, fromDate, toDate, isDesc, orderBy
      });
    } else {
      props.onSearch({page: 1, searchWord, isDesc, orderBy});
    }
  };

  const handleDatePicker = (dates) => {
    if (dates && dates.length > 1) {
      setFromDate(utils.toStartDateTS(dates[0]));
      setToDate(utils.toEndDateTS(dates[1]));
      setCurrent(1);
      if (props.showSearchDate) {
        props.onSearch({
          page: 1,
          searchWord,
          fromDate: utils.toStartDateTS(dates[0]),
          toDate: utils.toEndDateTS(dates[1]),
          branchId,
          isDesc,
          orderBy
        }).catch();
      } else {
        props.onSearch({
          page: 1,
          searchWord,
          branchId,
          isDesc,
          orderBy
        }).catch();
      }
    }
  };

  //브랜치 아이디 변경
  const handleSelectData = (value) => {
    (isTestBed) ? dispatch(setTestBedBranchId(value)) : dispatch(setBranchId(value));
    if (props.showSearchDate) {
      props.onSearch({
        page: 1,
        searchWord,
        fromDate: utils.toStartDateTS(fromDate),
        toDate: utils.toEndDateTS(toDate),
        branchId: value
      });
    } else {
      props.onSearch({
        page: 1,
        searchWord,
        branchId: value
      })
    }
  };
  // RowCount 변경
  const handleRowCount = (value) => {
    if (props.getRowCount) {
      props.getRowCount(value);
    }
    if (props.showSearchDate) {
      props.onSearch({
        page: current,
        rowCount: value,
        searchWord,
        fromDate,
        toDate,
        isDesc,
        orderBy
      });
    } else {
      props.onSearch({
        page: current,
        rowCount: value,
        searchWord,
        isDesc,
        orderBy
      });
    }
  }

  const components = {
    header: {
      cell: ResizableTitle,
    },
  };

  const getTableColumns = (columns = [], selectedColumnFilterOptions = []) => {
    let tableColumns = [...columns];
    if (props.columnFilterList !== undefined) {
      const filterValues = props.columnFilterList?.map((col) => col.value) || [];
      tableColumns = tableColumns.filter((col) => !filterValues.includes(col.dataIndex) || selectedColumnFilterOptions.includes(col.dataIndex));
    }
    return tableColumns.map((col, index) => ({
      ...col,
      showSorterTooltip: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      onHeaderCell: (column) => ({
        onClick: () => {
          if (col.sorter) {
            const sort = !isDesc;
            setIsDesc(sort);
            setOrderBy(column.dataIndex);
            setCurrent(1);
            if (props.showSearchDate) {
              props
                .onSearch({
                  orderBy: column.dataIndex,
                  isDesc: sort,
                  page: 1,
                  searchWord,
                  fromDate,
                  toDate,
                })
                .catch();
            } else {
              props.onSearch({orderBy: column.dataIndex, isDesc: sort, page: 1, searchWord}).catch();
            }
          }
        },
        width: column.width,
        onResize: (e, {size}) => {
          const nextColumns = [...columns];
          nextColumns[index] = {
            ...nextColumns[index],
            width: size.width,
          };
          setColumns(nextColumns);
        }
      }),
    }));
  }

  const onSearchWithPagination = () => {

    if (props.showSearchDate) {
      props.onSearch(
        {
          orderBy,
          isDesc,
          page: 1,
          searchWord,
          fromDate,
          toDate,
        })
    } else {
      props.onSearch(
        {
          orderBy,
          isDesc,
          page: 1,
          searchWord
        })
    }


  }

  let drawer;
  switch (props.title) {
    case labels.DEVICE_ADD:
      drawer = <DeviceFormInDrawer title={labels.DEVICE_ADD} onSearch={onSearchWithPagination}/>;
      break;
    case labels.SMART_ROUTINE_ADD:
      drawer = <SmartRoutineFormInDrawer title={labels.SMART_ROUTINE_ADD} onSearch={onSearchWithPagination} smartRoutineData={props.dataSource}/>;
      break;
    case labels.USER_ADD:
      drawer = <UserFormInDrawer title={intl.formatMessage({id: 'user_add'})} onSearchUser={onSearchWithPagination}/>;
      break;
    case labels.BRANCH_ADD:
      drawer = <BranchFormInDrawer title={intl.formatMessage({id: 'branch_add'})} onSearch={onSearchWithPagination}/>;
      break;
    case labels.HOUSE_ADD:
      drawer = <HouseFormInDrawer title={intl.formatMessage({id: 'house_add'})} onSearch={onSearchWithPagination}/>;
      break;
    case labels.DEVICE_TYPE_ADD:
      drawer = <DeviceTypeFormInDrawer title={labels.DEVICE_TYPE_ADD} onSearch={onSearchWithPagination}/>;
      break;
    case labels.REMOTE_TYPE_ADD:
      drawer = <RemoteTypeFormInDrawer title={labels.REMOTE_TYPE_ADD} onSearch={onSearchWithPagination}/>;
      break;
    case labels.DEVICE_ADD_WIZARD:
      const MAXIMUM_TESTBED_DEVICE_NUMBER = 5;
      drawer = <Button
        type="primary"
        icon={<PlusOutlined/>}
        onClick={() => {
          if (props.totalCount >= MAXIMUM_TESTBED_DEVICE_NUMBER) {
            notification.warning({
              message: intl.formatMessage({id: 'testbed_device_value_limit_message'},
                {max: MAXIMUM_TESTBED_DEVICE_NUMBER})
            })
          } else {
            props.setOnAddDeviceWizard(true)
          }
        }
        }>
        {intl.formatMessage({id: 'add_device_wizard'})}
      </Button>
      break;
    case labels.SMART_ROUTINE_ADD_ENG:
      drawer = <TestBedSmartRoutineDrawer title={labels.SMART_ROUTINE_ADD_ENG} smartRoutineData={props.dataSource}
                                          onSearch={props.onSearch}/>;
      break;
    default:
      drawer = undefined;
      break;
  }

  React.useEffect(() => {
    if (props.rowKey !== 'houseSelect') {
      if (props.dataSource?.length > 0) {
        setLoading(false);
      } else {
        setLoading(true);
        setTimeout(() => {

          setLoading(false);
        }, 500);
      }
    }
    if (props.isRefresh) {
      setCurrent(1);
      props.setRefresh({isRefresh: false, page: 1, rowCount: pagination.rowCount, searchWord, fromDate, toDate,});
    }
  }, [props.dataSource]);

  React.useEffect(() => {
    if (branchList?.length > 0) {
      const newBranchList = branchList.map((data) => {
        return (
          {label: data.branch_name, value: data.branch_id}
        )
      });
      setBranchOptions(newBranchList);

      const matchedBranch = branchList.find((branch) => branch?.branch_id === branchId);
      if (matchedBranch === undefined) {
        isTestBed
          ? dispatch(setTestBedBranchId(newBranchList[0]?.value))
          : dispatch(setBranchId(newBranchList[0]?.value))
      }
    }
  }, [branchList]);

  React.useEffect(() => {
    if (owner['auth'] === 0 && props.showBranch) {
      onGetBranchList().catch();
    }
  }, []);

  React.useEffect(() => {
    setColumns(getTableColumns(props.columns, selectedColumnFilterOptions));
  }, [selectedColumnFilterOptions])

  React.useEffect(() => {
    const columnFilterValues = props.columnFilterList?.map(col => col.value);
    setColumns(getTableColumns(props.columns,
      selectedColumnFilterOptions?.length > 0
        ?
        selectedColumnFilterOptions
        :
        emptySelectedFilter ? [] : columnFilterValues));

    setSelectedColumnFilterOptions(selectedColumnFilterOptions?.length > 0 ? selectedColumnFilterOptions : emptySelectedFilter ? [] : columnFilterValues);
  }, [props.columns, props.columnFilterList])

  //Data 테이블을 위한 항목 필터
  const renderColumnFilter = () => {
    return (
      <Checkbox.Group className={'d-flex flex-column'} style={{lineHeight: 2.5, marginRight: '20px'}}
                      onChange={(e) => {
                        if (e.length === 0) {
                          setEmptySelectedFilter(true);
                        } else {
                          setEmptySelectedFilter(false);
                        }
                        setSelectedColumnFilterOptions(e)
                      }}
                      defaultValue={props.columnFilterList?.map((col) => col.value)}
      >
        {props.columnFilterList?.map((col) => {
          return <Checkbox key={col.value} style={{marginLeft: 0}} value={col.value}>{col.label}</Checkbox>
        })}
      </Checkbox.Group>
    )
  }
  //Export될 데이터를 만드는 함수
  const exportData = (dataSource) => {
    const fixedColumnNameMap = {
      created: 'create_date',
      serial: 'serial',
      device_name: 'device_name'
    }

    const newData = [];
    Object.keys(fixedColumnNameMap).forEach((key) => {
      dataSource?.forEach((data, index) => {
        if (key === 'created') {
          newData[index] = {};
          newData[index][intl.formatMessage({id: 'create_date'})] = utils.formatDTS(data[key])
        } else {
          newData[index][intl.formatMessage({id: fixedColumnNameMap[key]})] = data[key]
        }
      })
    });

    selectedColumnFilterOptions?.forEach((key) => {
      dataSource?.forEach((data, index) => {
        let filterdValue = {}
        filterdValue = props.deviceTypeItems?.find((item) => {
          return item.sensorType === key;
        })
        if (key in data) {
          newData[index][`${filterdValue?.sensorName}(${filterdValue?.sensorUnit})`] = data[key]
        } else {
          newData[index][`${filterdValue?.sensorName}(${filterdValue?.sensorUnit})`] = ''
        }
      })
    });
    setExportedData(newData)
  }


  const onFilterVisibleChange = () => {
    if (props.columnFilterList.length === 0) {
      setFilterBoxVisiable(false)
    } else {
      setFilterBoxVisiable(!filterBoxVisiable)
    }
  }


  return (
    <>
      <Row justify='space-between'>
        <Row gutter={[9, 3]}>
          {/*브랜치 select 와 검색*/}
          <Col>
            <Row gutter={[9, 8]}>
              {props.showBranch && owner['auth'] === 0 && (
                <Col span={10}>
                  <Select value={branchId} required={true} onChange={handleSelectData} className={'show-branch-select'}>
                    {branchOptions?.map((type) => (
                      <Option key={type.value} value={type.value} name={type.label}>{type.label}</Option>
                    ))}
                  </Select>
                </Col>
              )}
              {props.showSearchWord && (
                <Col span={11.5}>
                  <Search
                    placeholder={intl.formatMessage({id: 'search'})}
                    className={'show-search-word'}
                    onChange={(e) => onChangeWord(e)}
                    onSearch={onSearchWord}
                  />
                </Col>
              )}
              {props.title &&
              <Col span={5}>
                <div className={'drawer-button-mobile'}>{drawer}</div>
              </Col>
              }
            </Row>
          </Col>
          {/*날짜 검색과 필터 */}
          <Col>
            <Row gutter={8}>
              {props.showSearchDate && (
                <Col span={18}>
                  <RangePicker
                    style={{width: 220}}
                    defaultValue={[startDateRef.current, toDateRef.current]}
                    onChange={(dateStrings) => handleDatePicker(dateStrings)}
                  />
                </Col>
              )}
              {props.columnFilterList &&
              <Col span={6}>
                <Popover
                  content={renderColumnFilter()}
                  visible={filterBoxVisiable}
                  trigger='click'
                  placement="bottomLeft"
                  onVisibleChange={onFilterVisibleChange}
                >
                  <Button disabled={props.columnFilterList.length === 0}>
                    {intl.formatMessage({id: 'filter'})}
                  </Button>
                </Popover>
              </Col>
              }
            </Row>
          </Col>
          {/*RowCount select와 export*/}
          <Col>
            <Row gutter={8}>
              {props.getRowCount &&
              <Col span={13}>
                <Select defaultValue={10} style={{width: 100}} onChange={handleRowCount}>
                  <Option value={10}>10</Option>
                  <Option value={100}>100</Option>
                  <Option value={1000}>1000</Option>
                  <Option value={10000}>10000</Option>
                </Select>
              </Col>
              }
              {props.exportData &&
              <Col span={11}>
                <Button disabled={props.dataSource?.length > 0 ? false : true}
                        onClick={() => exportData(props.dataSource)}><CSVLink
                  data={exportedData}>Export</CSVLink></Button>
              </Col>
              }
            </Row>
          </Col>
        </Row>
        {/*Drawer 버튼*/}
        {props.title &&
        <Row className={'drawer-button-desktop'}>{drawer}</Row>
        }
      </Row>

      <div style={{width: '100%', height: props.height ?? '100%', marginTop: "5px"}}>
        <Card bodyStyle={{padding: '0px'}}>
          <div className={`table-responsive`}>
            <Table
              rowSelection={props.rowSelection}
              columns={columns}
              dataSource={props.dataSource}
              rowKey={props.rowKey}
              pagination={false}
              loading={loading}
              components={components}
              scroll={{x: 'max-content', y: 600}}
            />
          </div>
        </Card>
      </div>
      {props.dataSource?.length > 0 && !props.pagination ? (
        <div style={{position: 'absolute', bottom: 5, right: 50}}>
          <Pagination
            current={current}
            pageSize={props.pageSize ?? pagination.rowCount}
            total={props.totalCount}
            onChange={onChange}
            showSizeChanger={false}
          />
        </div>
      ) : undefined}
    </>
  );
};

export default DataTableView;
