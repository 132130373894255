import React from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AppLayout from 'layouts/app-layout';
import AuthLayout from 'layouts/auth-layout';
import AppLocale from 'lang';
import {IntlProvider} from 'react-intl';
import {ConfigProvider} from 'antd';
import {APP_PREFIX_PATH} from 'configs/AppConfig'
import useBodyClass from 'hooks/useBodyClass';
import EmailCheck from './login/EmailCheck';

function RouteInterceptor({children, isAuthenticated, ...rest}) {
  return (
    <Route
      {...rest}
      render={({location}) => {
        if (location.pathname.includes('email_check')) {
          return <Redirect
              to={{
                pathname: location.pathname,
                state: {from: location}
              }}
            />
        } else {
          return isAuthenticated ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: APP_PREFIX_PATH + '/login',
                state: {from: location}
              }}
            />
          )
        }
      }}
    />
  );
}

export const Views = (props) => {
  const {locale, token, location, direction} = props;
  const currentAppLocale = AppLocale[locale];

  useBodyClass(`dir-${direction}`);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/">
            <Redirect to={APP_PREFIX_PATH}/>
          </Route>
          <Route exact path={APP_PREFIX_PATH + '/login'}>
            <AuthLayout direction={direction}/>
          </Route>
          <Route path={APP_PREFIX_PATH + '/email_check/*'}>
            <AuthLayout direction={direction}/>
          </Route>
          <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
            <AppLayout direction={direction} location={location}/>
          </RouteInterceptor>
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  )
}

const mapStateToProps = ({theme, auth}) => {
  const {locale, direction} = theme;
  const {token} = auth;
  return {locale, direction, token}
};

export default withRouter(connect(mapStateToProps)(Views));
