import React from 'react';
import {Button, Col, Drawer, Form, Input, notification, Popconfirm, Row, Table} from 'antd';
import {DeleteOutlined, PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {labels} from '../../Labels';
import _ from 'lodash';
import IntlMessage from "../../../components/util-components/IntlMessage";
import {useIntl} from "react-intl";

const TestBedActuatorDrawer = ({title, elm, elmIndex, actuatorData, getActuatorData}) => {
  const [commandValues, setCommandValues] = React.useState([]);
  const [selectedCommandValueIndex, setSelectedCommandValueIndex] = React.useState(undefined);
  const [buttonTitle, setButtonTitle] = React.useState('Add');
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();
  const [valueFieldError, setValueFieldError] = React.useState(false);
  const [nameFieldError, setNameFieldError] = React.useState(false);
  const intl = useIntl();

  React.useEffect(() => {
    setButtonTitle('Add');
    setSelectedCommandValueIndex(undefined)
  }, [commandValues])

  const showDrawer = () => {
    setVisible(true);
    if (elm && title === labels.COMMAND_EDIT_ENG) {
      form.setFieldsValue({
        commandType: elm.commandType,
        commandName: elm.commandName,
      })
      setCommandValues(elm.commandValues);
    } else {
      setCommandValues([]);
    }
  };

  const resetFields = () => {
    form.resetFields();
    setButtonTitle('Add');
    setValueFieldError(false);
    setNameFieldError(false);
  }

  const onClose = () => {
    setVisible(false);
    resetFields();
  };

  const onFinish = (values) => {
    const matchedIndex = actuatorData?.findIndex((command) => {
      return command.commandType === values.commandType;
    })

    if (matchedIndex !== -1 && matchedIndex !== elmIndex) {
      notification.warning({message: intl.formatMessage({id: 'error_command_type'})})
      return;
    }

    setVisible(false);
    getActuatorData({
      commandType: values.commandType,
      commandName: values.commandName,
      commandValues: commandValues,
    }, elmIndex);
    form.resetFields();
  }

  const onAddValue = () => {
    let hasError = false;
    if (_.isEmpty(form.getFieldValue('value'))) {
      setValueFieldError(true);
      hasError = true;
    }
    if (_.isEmpty(form.getFieldValue('name'))) {
      setNameFieldError(true);
      hasError = true;
    }
    if (hasError) {
      return;
    }

    const matchedIndex = commandValues.findIndex((commandValue) => {
      return commandValue.value === form.getFieldValue('value');
    })
    if (matchedIndex !== -1 && matchedIndex !== selectedCommandValueIndex) {
      notification.warning({message: intl.formatMessage({id: 'error_command_value'})})
      return;
    }

    const clonedCommand = [...commandValues];
    const dataInfo = {value: form.getFieldValue('value'), name: form.getFieldValue('name')};
    if (selectedCommandValueIndex === undefined) {
      clonedCommand.push(dataInfo);
    } else {
      clonedCommand[selectedCommandValueIndex] = dataInfo;
      setButtonTitle('Add');
    }
    setCommandValues(clonedCommand);
    form.resetFields(['value', 'name']);
  };

  const onDeleteValue = (index) => {
    const clonedCommand = [...commandValues];
    clonedCommand.splice(index, 1);
    setCommandValues(clonedCommand);
    form.resetFields(['value', 'name']);
  }

  const onClickedValueRow = (elm, title, elmIndex) => {
    setSelectedCommandValueIndex(elmIndex);
    setButtonTitle(title);
    form.setFieldsValue({
      value: elm.value,
      name: elm.name
    });
  };

  const buttonStyle = title === labels.SENSOR_EDIT_ENG || title === labels.COMMAND_EDIT_ENG ?
    {display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginRight: '8px'} :
    {display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '12px', marginRight: '8px'};

  const commandValuesTableColumns = [
    {
      title: <IntlMessage id={"value"}/>,
      dataIndex: 'value',
      width: '40%',
    },
    {
      title: <IntlMessage id={"description"}/>,
      dataIndex: 'name',
      width: '40%',
    },
    {
      title: '',
      dataIndex: 'actions',
      width: '40%',
      render: (_, elm, index) => (
        <div className="text-right d-flex justify-content-end"
             onClick={(e) => {
               e.stopPropagation();
             }}>
          <Popconfirm placement="leftTop" title={intl.formatMessage({id: 'DELETE_TITLE'})} onConfirm={() => {
            onDeleteValue(index)
          }} okText={<IntlMessage id={'YES'}/>} cancelText={<IntlMessage id={'NO'}/>}>
            <Button danger icon={<DeleteOutlined/>} size="small"/>
          </Popconfirm>
        </div>
      )
    }
  ];

  return (
    <div style={buttonStyle}>
      <Button className="text-align-center" type="primary"
              icon={title === labels.COMMAND_EDIT_ENG ? <SettingOutlined/> : <PlusOutlined/>}
              onClick={showDrawer}>
        {title === labels.COMMAND_EDIT_ENG ? undefined : title}
      </Button>
      <Drawer
        title={title}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={onClose}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout="vertical" hideRequiredMark onFinish={onFinish} form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="commandType"
                label={<IntlMessage id={"command"}/>}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({id: 'write_command'})
                  }
                ]}
              >
                <Input placeholder={intl.formatMessage({id: 'write_command'})}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="commandName"
                label={<IntlMessage id={"description"}/>}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({id: 'write_descritpion'})
                  }
                ]}
              >
                <Input placeholder={intl.formatMessage({id: 'write_descritpion'})}/>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="commandValues"
                label={<IntlMessage id={'values'}/>}
                initialValue={commandValues}
              >
                <Table
                  columns={commandValuesTableColumns}
                  dataSource={commandValues}
                  rowKey="id"
                  bordered
                  pagination={false}
                  scroll={{y: 430}}
                  onRow={(record, index) => {
                    return {
                      onClick: (e) => {
                        onClickedValueRow(record, 'Edit', index);
                      }
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={9}>
              <Form.Item
                name="value" label=""
                help={valueFieldError ? intl.formatMessage({id: 'write_input_value'}) : undefined}
                validateStatus={valueFieldError ? 'error' : ''}
              >
                <Input onChange={() => setValueFieldError(false)}
                       placeholder={intl.formatMessage({id: 'write_value'})}/>
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item
                name="name" label=""
                help={nameFieldError ? intl.formatMessage({id: 'write_input_name'}) : undefined}
                validateStatus={nameFieldError ? 'error' : ''}>
                <Input onChange={() => setNameFieldError(false)}
                       placeholder={intl.formatMessage({id: 'write_descritpion'})}/>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Button onClick={onAddValue} style={{
                marginRight: 10,
                marginBottom: 20
              }}>{buttonTitle === 'Add' ? intl.formatMessage({id: 'add'}) : intl.formatMessage({id: 'edit'})}</Button>
            </Col>
          </Row>
          <Form.Item className='drawer-form-buttons'>
            <Button
              onClick={onClose}
              style={{marginRight: 8}}
            >
              {intl.formatMessage({id: 'cancel'})}
            </Button>
            <Button type="primary" htmlType="submit">
              {title === labels.COMMAND_EDIT_ENG ? intl.formatMessage({id: 'save'}) : intl.formatMessage({id: 'add'})}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default TestBedActuatorDrawer;
