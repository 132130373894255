import React from 'react';
import {Button, Col, Drawer, Form, Input, Row, Select, Switch} from 'antd';
import {PlusOutlined, SettingOutlined} from '@ant-design/icons';
import {labels} from '../../views/Labels';
import SelectInDrawer, {SELECT_IN_DRAWER_DATA_MAP} from 'components/uplink-components/SelectInDrawer';
import {useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import * as api from 'services';
import utils from "../../utils";

const {Option} = Select;

const SmartRoutineJactionFormInDrawer = ({title, elm, elmIndex, getJactionData, houseId}) => {
  const branchId = useSelector(state => state.user?.branchId);
  const deviceId = React.useRef();
  const deviceTypeId = React.useRef();
  const [commandType, setCommandType] = React.useState();
  const [commandValue, setCommandValue] = React.useState();
  const [jcommand, setJcommand] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [isactivated, setIsactivated] = React.useState(false);
  const [commandTypeOptions, setCommandTypeOptions] = React.useState([]);
  const [commandValueOptions, setCommandValueOptions] = React.useState([]);
  const deviceTypeList = useSelector(state => state.ws?.deviceTypeList);
  const [form] = Form.useForm();
  const intl = useIntl();

  React.useEffect(() => {
    const jcommandV = deviceTypeList[0]?.jcommand || []
    if (jcommandV?.length > 0) {
      setJcommand([jcommandV]);
      const commandTypeOption = jcommandV.map((data) => {
        return ({
            label: data.commandName,
            value: data.commandType
          }
        );
      });
      setCommandTypeOptions(commandTypeOption);
    }
  }, [deviceTypeList]);

  React.useEffect(() => {
    const jcommand = deviceTypeList[0]?.jcommand || []
    if (jcommand) {
      const command = jcommand?.filter(x => x.commandType === commandType)[0]?.commandValues;
      const option = command?.map((c) => {
        return ({
          label: c.name,
          value: c.value
        });
      });
      setCommandValueOptions(option);
    }
  }, [commandType, jcommand])

  const onSearchData = (id) => {
    return api.getDeviceTypeList({branchId, deviceTypeId: id});
  };

  const showDrawer = async (elm) => {
    if (!houseId) {
      return utils.showWarning(intl.formatMessage({id: 'select_house'}));
    }

    setVisible(true);
    resetFields();
    if (elm) {
      if (elm.deviceTypeId) {
        await onSearchData(elm.deviceTypeId).catch();
      }
      deviceId.current = elm.deviceId;
      deviceTypeId.current = elm.deviceTypeId;
      setIsactivated(elm.isactivated);
      setCommandType(elm.commandType);
      setCommandValue(elm.commandValue);
      setIsactivated(elm.isactivated);
      form.setFieldsValue({
        deviceName: elm.deviceName,
        commandType: elm.commandType,
        commandValue: elm.commandValue,
      });
    }
  };

  const resetFields = () => {
    form.resetFields();
    deviceId.current = undefined;
    deviceTypeId.current = undefined;
    setIsactivated(false);
    setCommandType(undefined);
    setCommandValue(undefined);
    setJcommand([]);
    setCommandTypeOptions([]);
    setCommandValueOptions([]);
  }

  const onClose = () => {
    setVisible(false);
    resetFields();
  };

  const getCommandName = (commandType) => {
    if (commandType === undefined) {
      return undefined;
    }
    const jcommand = deviceTypeList[0]?.jcommand || [];
    return jcommand.find((data) => data.commandType === commandType)?.commandName || [];
  }

  const getCommandValueName = (commandType, commandValue) => {
    if (commandType === undefined || commandValue === undefined) {
      return undefined;
    }
    const jcommand = deviceTypeList[0]?.jcommand || [];
    const commandValues = jcommand.find((data) => data.commandType === commandType)?.commandValues || [];
    return commandValues.find((data) => data.value === commandValue)?.name;
  }

  const onFinish = (values) => {

    if (values.deviceName === undefined) {
      return utils.showWarning(intl.formatMessage({id: 'select_device'}));
    }
    if (values.commandType === undefined) {
      return utils.showWarning(intl.formatMessage({id: 'select_command_name'}));
    }
    if (values.commandValue === undefined) {
      return utils.showWarning(intl.formatMessage({id: 'select_value'}));
    }

    const dataInfo = {
      deviceId: deviceId.current,
      deviceTypeId: deviceTypeId.current,
      deviceName: values.deviceName,
      commandType,
      commandName: getCommandName(commandType),
      commandValue,
      commandValueName: getCommandValueName(commandType, commandValue),
      isactivated
    };
    setVisible(false);
    getJactionData(dataInfo, elmIndex);
    resetFields();
  };

  const getSelectedDeviceData = (selectedDevice) => {
    const selectedDeviceId = selectedDevice?.device_id;

    if (deviceId.current !== selectedDeviceId) {
      const selectedDeviceName = selectedDevice?.device_name;
      const selectedDeviceTypeId = selectedDevice?.device_type_id;

      setCommandType(undefined);
      setCommandValue(undefined);
      deviceId.current = selectedDeviceId;
      deviceTypeId.current = selectedDeviceTypeId;
      setIsactivated(false);
      form.resetFields(['commandType', 'commandValue']);
      form.setFieldsValue({deviceName: selectedDeviceName});
      onSearchData(selectedDeviceTypeId).catch();
    }
  };

  const onSetCommandType = (selectedCommandType) => {
    form.resetFields(['commandValue']);
    setCommandValue(undefined);
    setCommandType(selectedCommandType);
  }

  const buttonStyle = title === labels.SMART_ROUTINE_JACTION_EDIT ?
    {display: 'flex', justifyContent: 'flex-end', marginRight: '8px'} :
    {display: 'flex', justifyContent: 'flex-end', marginTop: '12px', marginRight: '8px'};

  return (
    <div style={buttonStyle}>
      <Button type="primary"
              icon={title === labels.SMART_ROUTINE_JACTION_EDIT ? <SettingOutlined/> :
                <PlusOutlined/>}
              onClick={() => showDrawer(elm)} size={'small'}>
        {title === labels.SMART_ROUTINE_JACTION_EDIT ? undefined : intl.formatMessage({id: 'smart_routine_jaction_add'})}
      </Button>
      <Drawer
        title={title === labels.SMART_ROUTINE_JACTION_EDIT ? intl.formatMessage({id: 'smart_routine_jaction_edit'})
          : intl.formatMessage({id: 'smart_routine_jaction_add'})}
        width={window.innerWidth > 900 ? 720 : window.innerWidth}
        onClose={onClose}
        visible={visible}
        bodyStyle={{paddingBottom: 80}}
      >
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={10}>
              <Form.Item
                name="deviceName"
                label={intl.formatMessage({id: 'device_name'})}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({id: 'select_device_name'})
                  }
                ]}
              >
                <Input disabled={true} placeholder={intl.formatMessage({id: 'select_device'})}/>
              </Form.Item>
            </Col>
            <Col span={2} style={{marginTop: '29px'}}>
              <SelectInDrawer branchId={branchId} selectedRowKey={deviceId.current}
                              getSelectedRowData={getSelectedDeviceData}
                              componentDataMap={SELECT_IN_DRAWER_DATA_MAP.device}
                              houseId={houseId}/>
            </Col>
            <Col span={12}>
              <Form.Item
                name="commandType"
                label={intl.formatMessage({id: 'command'})}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({id: 'select_command'})
                  }
                ]}
              >
                <Select onChange={onSetCommandType} placeholder={intl.formatMessage({id: 'select_command'})}>
                  {commandTypeOptions?.map((type) => {
                    return (
                      <Option key={type.value} value={type.value} name={type.label}>{type.label}</Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="commandValue"
                label={intl.formatMessage({id: 'value'})}
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({id: 'select_value'})
                  }
                ]}
              >
                <Select onChange={setCommandValue} placeholder={intl.formatMessage({id: 'select_value'})}>
                  {commandValueOptions?.map((command) => {
                    return (
                      <Option key={command.value} value={command.value} name={command.value}>{command.label}</Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="isactivated"
                label={intl.formatMessage({id: 'activate'})}
                initialValue={isactivated}
                getValueFromEvent={setIsactivated}
              >
                <Switch checked={isactivated}/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item className="drawer-form-buttons">
            <Button
              onClick={onClose}
              style={{marginRight: 8}}
            >
              {intl.formatMessage({id: 'cancel'})}
            </Button>
            <Button htmlType="submit" type="primary">
              {title === labels.SMART_ROUTINE_JACTION_EDIT ? intl.formatMessage({id: 'edit'}) : intl.formatMessage({id: 'add'})}
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}

export default SmartRoutineJactionFormInDrawer;
