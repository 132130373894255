import React from 'react'
import utils from '../../../utils';
import {Button} from 'antd';
import {useIntl} from 'react-intl';
import DeviceConnectInfo from '../../../components/uplink-components/DeviceConnectInfo';

export const AddDeviceThirdStep = (props) => {
  const intl = useIntl();
  const userInfo = JSON.parse(localStorage.getItem('user_info'));
  const apiKey = userInfo['api_key'];
  const protocol = props.fields.protocol;
  const serial = props.fields.serial;
  const sensors = utils.isSensor(props.fields.deviceKind) ? props.fields.sensor : undefined;
  const commands = utils.isActuator(props.fields.deviceKind) ? props.fields.actuator : undefined;

  const renderFooterButtons = () => {
    return (
      <Button className="align-self-end" type="primary" onClick={props.handleDoneButton}>
        {intl.formatMessage({id: 'done'})}
      </Button>
    )
  }

  return (
    <div className="d-flex flex-column w-100 mt-3">
      <div>
        <DeviceConnectInfo protocol={protocol} apiKey={apiKey} serial={serial} sensors={sensors} commands={commands} />
      </div>
      {renderFooterButtons()}
    </div>
  )
};

export default AddDeviceThirdStep;
