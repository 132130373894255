import React from 'react'
import {useSelector} from 'react-redux'
import {Button, Popconfirm, Tag, Tooltip} from 'antd';
import {DeleteOutlined} from '@ant-design/icons';
import {labels} from '../Labels';
import {store} from '../../App';
import {filterBadgeCounter, toggleHeaderDatepicker} from '../../redux/actions';
import DataTableView from '../../components/uplink-components/DataTableView';
import {pagination} from '../../utils/helper';
import TestBedSmartRoutineDrawer from './components/TestBedSmartRoutineDrawer';
import utils from '../../utils';
import IntlMessage from '../../components/util-components/IntlMessage';
import {useIntl} from 'react-intl';
import * as api from 'services';

export const TestBedSmartRoutine = (props) => {
  const branchId = useSelector(state => state.user?.testBedBranchId);
  const testBedSmartRoutineList = useSelector(state => state.testBed?.testBedSmartRoutineList);
  const testBedSmartRoutineListTotal = useSelector(state => state.testBed?.testBedSmartRoutineListTotal);
  const [searchData, setSearchData] = React.useState();
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isRefresh, setIsRefresh] = React.useState(false);

  const intl = useIntl();
  const prevKey = React.useRef()

  if (prevKey.current !== props.location.key) {
    prevKey.current = props.location.key;
    setIsRefresh(true);
  }

  const onSearch = async (searchInfo) => {
    setSearchData(searchInfo);
    const tableInfo = {...pagination, page: currentPage, branchId, ...searchInfo};
    setTimeout(() => {
      api.getTestBedSmartRoutineList(tableInfo);
    }, 0);
  };

  React.useEffect(() => {
    if (isRefresh && branchId !== undefined) {
      onSearch({page: 1, searchWord: searchData?.searchWord}).catch();
    }
    if (!isRefresh && branchId === undefined) {
      setIsRefresh(true);
    }
  }, [branchId, isRefresh]);

  React.useEffect(() => {
    pagination['searchWord'] = '';
    store.dispatch(toggleHeaderDatepicker(false));
    store.dispatch(filterBadgeCounter(0));
  }, []);

  const setRefresh = (data) => {
    const {isRefresh, page} = data
    setSearchData(data);
    setIsRefresh(isRefresh);
    setCurrentPage(page);
  };

  const getCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const deleteSmartRoutine = async (elm) => {
    await api.testBedSmartRoutineCUD({isDel: true, routineId: elm.routine_id}).then(res => {
      onSearch().catch();
    });
  };

  const tableColumns = [
    {
      title: <IntlMessage id={'create_date'}/>,
      dataIndex: 'created',
      width: 200,
      render: (_, record) => (
        <div className="d-flex">
          {utils.formatDTS(record['created'])}
        </div>
      ),
      sorter: true,
    },
    {
      title: <IntlMessage id={'routineName'}/>,
      dataIndex: 'routine_name',
      width: 250,
      sorter: true,
    },
    {
      title: <IntlMessage id={'lastRun'}/>,
      dataIndex: 'last_run',
      width: 200,
      render: (_, record) => (
        <div className="d-flex">
          {utils.formatDTS(record['last_run'])}
        </div>
      ),
    },
    {
      title: <IntlMessage id={'activate'}/>,
      dataIndex: 'isactivated',
      width: 100,
      render: status => (
        <Tag className="text-capitalize" color={status === 'Y' || status === true ? 'cyan' : 'red'}>
          {(status === 'Y' || status === true) ? labels.ACTIVATE : labels.DEACTIVATE}
        </Tag>
      ),
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 100,
      render: (_, elm, index) => (
        <div className="text-right d-flex justify-content-end" style={{height: '5px', alignItems: 'center'}}>
          <Tooltip title="View">
            <TestBedSmartRoutineDrawer title={labels.SMART_ROUTINE_EDIT_ENG} elm={elm}
                                       elmIndex={index}
                                       smartRoutineData={testBedSmartRoutineList}
                                       onSearch={onSearch}/>
          </Tooltip>
          <Tooltip title="Delete">
            <Popconfirm placement="leftTop" title={intl.formatMessage({id: 'DELETE_TITLE'})} onConfirm={() => {
              deleteSmartRoutine(elm).catch();
            }} okText={<IntlMessage id={'YES'}/>} cancelText={<IntlMessage id={'NO'}/>}>
              <Button danger icon={<DeleteOutlined/>} size="small"/>
            </Popconfirm>
          </Tooltip>
        </div>
      )
    }
  ];
  return (
    <div>
      <DataTableView
        columns={tableColumns}
        dataSource={testBedSmartRoutineList}
        rowKey="routine_id"
        totalCount={testBedSmartRoutineListTotal}
        onSearch={onSearch}
        getCurrentPage={getCurrentPage}
        showSearchWord={true}
        isRefresh={isRefresh}
        setRefresh={setRefresh}
        title={labels.SMART_ROUTINE_ADD_ENG}
        showBranch={true}
        showSearchDate={false}
      />
    </div>
  );
};

export default TestBedSmartRoutine;
