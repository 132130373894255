import utils from 'utils'
import {store} from '../App';
import {
  BranchList,
  BranchListTotal,
  BranchTypeList, BranchTypeOption,
  HouseList,
  HouseListTotal,
  UserList,
  UserListTotal
} from '../redux/actions/WS';
import {TestBedBranchList, TestBedBranchListTotal} from '../redux/actions/TestBed';
import API from './API';
import _ from 'lodash';
import {USER_TYPE_NUMBER_MAP} from '../constants/UplinkConstant';

export const getUserList = async (data) => {
  const token = utils.getToken();
  const action = 'GetUserList';
  const reqData = {action, token, data};
  API.send('user/R', reqData).then(res => {
    store.dispatch(UserList(res?.data));
    store.dispatch(UserListTotal(res?.totalCount));
  })
}

export const userCUD = async (data) => {
  const token = utils.getToken();
  const action = 'SetUser';
  const reqData = {action, token, data};
  return API.send('user/CUD', reqData).then(res => {
    return res;
  })
}

export const userC = async (data) => {
  const action = 'RegisterUser';
  const reqData = {action, data};
  return API.send('user/CUD', reqData).then((res) => {
    return res;
  });
}

export const getBranchList = async (data) => {
  const token = utils.getToken();
  const action = 'GetBranchListForAdmin';
  const reqData = {action, token, data};
  return API.send('user/R', reqData).then(res => {
    if (data?.isTestbed) {
      store.dispatch(TestBedBranchList(res?.data));
      store.dispatch(TestBedBranchListTotal(res?.totalCount));
    } else {
      store.dispatch(BranchList(res?.data));
      store.dispatch(BranchListTotal(res?.totalCount));
    }
    return res;
  })
}

export const getSettingBranchList = async (data) => {
  const token = utils.getToken();
  const action = 'GetBranchList';
  const reqData = {action, token, data};
  return API.send('user/R', reqData).then(res => {
    store.dispatch(BranchList(res?.data));
    store.dispatch(BranchListTotal(res?.totalCount));
    return res;
  })
}

export const branchCUD = async (data) => {
  const token = utils.getToken();
  const action = 'SetBranch';
  const reqData = {action, token, data};
  return API.send('user/CUD', reqData).then(res => {
    return res;
  })
}

export const getBranchTypes = async (data) => {
  const token = utils.getToken();
  const action = 'GetBranchTypes';
  const reqData = {action, token, data};
  API.send('user/R', reqData).then(res => {
    store.dispatch(BranchTypeList(res?.data));
  })
}

export const getHouseList = (data) => {
  const token = utils.getToken();
  const action = 'GetHouseList';
  const reqData = {action, token, data};
  API.send('user/R', reqData).then(res => {
    store.dispatch(HouseList(res?.data));
    store.dispatch(HouseListTotal(res?.totalCount));
  })
}

export const getHouseArray = (data) => {
  const token = utils.getToken();
  const action = 'GetHouseArray';
  const reqData = {action, token, data};
  API.send('user/R', reqData).then(res => {
    store.dispatch(HouseList(res?.data));
    store.dispatch(HouseListTotal(res?.totalCount));
  })
}

export const houseCUD = async (data) => {
  const token = utils.getToken();
  const action = 'SetHouse';
  const reqData = {action, token, data};
  return API.send('user/CUD', reqData).then(res => {
    return res;
  })
}

export const setUserUI = async (data) => {
  const token = utils.getToken();
  const action = 'SetUserUI';
  const reqData = {action, token, data: _.omit(data, ['isTestBed'])};
  return API.send(data?.isTestBed ? 'tbed/CUD' : 'user/CUD', reqData).then(res => {
    if (utils.getAuth() !== USER_TYPE_NUMBER_MAP.super_admin) {
      utils.setUserInfo(data);
    }
    return res;
  })
}

export const GetBranchOptions = async (data) => {
  const token = utils.getToken();
  const action = 'GetBranches';
  const reqData = {action, token, data};
  return API.send('device/R', reqData).then((res) => {
    store.dispatch(BranchTypeOption(res?.data));
  });
}

export const SetActivateSionUser = async (data) => {
  const action = 'SetActivateSionUser';
  const reqData = {action, data};
  return API.send('user/CUD', reqData).then((res) => {
    return res;
  });
}
