import {
  TESTBED_DEVICE_TYPE_LIST,
  TESTBED_DEVICE_LIST,
  TESTBED_DEVICE_TYPE_LIST_TOTAL,
  TESTBED_DEVICE_LIST_TOTAL,
  TESTBED_SMART_ROUTINE_LIST,
  TESTBED_SMART_ROUTINE_LIST_TOTAL,
  TESTBED_DEVICE_DATA_LIST,
  TESTBED_DEVICE_DATA_LIST_TOTAL,
  TESTBED_DEVICE_TYPE_ITEMS,
  TESTBED_DEVICE_VIEW_LIST,
  TESTBED_DEVICE_VIEW_LIST_TOTAL,
  TESTBED_BRANCH_LIST,
  TESTBED_BRANCH_LIST_TOTAL,
  TESTBED_EVENT_LOG,
  TESTBED_EVENT_LOG_TOTAL,
} from '../constants/TestBed';

const initState = {
  testBedDeviceTypeList: [],
  testBedDeviceTypeListTotal: undefined,
  testBedDeviceList: [],
  testBedDeviceListTotal: undefined,
  testBedDeviceDataList: [],
  testBedDeviceDataListTotal: undefined,
  testBedSmartRoutineList: [],
  testBedSmartRoutineListTotal: undefined,
  testBedDeviceTypeItems: [],
  testBedDeviceViewList: [],
  testBedDeviceViewListTotal: undefined,
  testBedBranchList: [],
  testBedBranchListTotal: undefined,
  testBedEventLog: [],
  testBedEventLogTotal: undefined,
}

const testBed = (state = initState, action) => {
  switch (action.type) {
    case TESTBED_DEVICE_TYPE_LIST:
      return {
        ...state,
        testBedDeviceTypeList: action.testBedDeviceTypeData
      }
    case TESTBED_DEVICE_TYPE_LIST_TOTAL:
      return {
        ...state,
        testBedDeviceTypeListTotal: action.testBedDeviceTypeListTotal
      }
    case TESTBED_DEVICE_LIST:
      return {
        ...state,
        testBedDeviceList: action.testBedDeviceData
      }
    case TESTBED_DEVICE_LIST_TOTAL:
      return {
        ...state,
        testBedDeviceListTotal: action.testBedDeviceListTotal
      }
    case TESTBED_DEVICE_DATA_LIST:
      return {
        ...state,
        testBedDeviceDataList: action.testBedDeviceDataList
      }
    case TESTBED_DEVICE_DATA_LIST_TOTAL:
      return {
        ...state,
        testBedDeviceDataListTotal: action.testBedDeviceDataListTotal
      }
    case TESTBED_SMART_ROUTINE_LIST:
      return {
        ...state,
        testBedSmartRoutineList: action.testBedSmartRoutineList
      }
    case TESTBED_SMART_ROUTINE_LIST_TOTAL:
      return {
        ...state,
        testBedSmartRoutineListTotal: action.testBedSmartRoutineListTotal
      }
    case TESTBED_DEVICE_TYPE_ITEMS:
      return {
        ...state,
        testBedDeviceTypeItems: action.testBedDeviceTypeItems
      }
    case TESTBED_DEVICE_VIEW_LIST:
      return {
        ...state,
        testBedDeviceViewList: action.testBedDeviceViewList
      }
    case TESTBED_DEVICE_VIEW_LIST_TOTAL:
      return {
        ...state,
        testBedDeviceViewListTotal: action.testBedDeviceViewListTotal
      }
    case TESTBED_BRANCH_LIST:
      return {
        ...state,
        testBedBranchList: action.testBedBranchList
      }
    case TESTBED_BRANCH_LIST_TOTAL:
      return {
        ...state,
        testBedBranchListTotal: action.testBedBranchListTotal
      }
    case TESTBED_EVENT_LOG:
      return {
        ...state,
        testBedEventLog: action.testBedEventLog
      }
    case TESTBED_EVENT_LOG_TOTAL:
      return {
        ...state,
        testBedEventLogTotal: action.testBedEventLogTotal
      }
    default:
      return state;
  }
}

export default testBed;
