import React from 'react'
import {Button, Form} from 'antd';
import TestBedSensorTable from './TestBedSensorTable';
import TestBedActuatorTable from './TestBedActuatorTable';
import util from '../../../utils';
import {useIntl} from "react-intl";

export const AddDeviceSecondStep = (props) => {
  const intl = useIntl();
  const renderFooterButtons = () => {
    return (
      <Form.Item>
        <div className="steps-action mt-3 d-flex justify-content-between">
          <Button onClick={props.handleCancelButton}>
            {intl.formatMessage({id: 'cancel'})}
          </Button>
          <div>
            <Button style={{marginRight: 8}} onClick={props.handlePreviousButton}>
              {intl.formatMessage({id: 'previous'})}
            </Button>
            <Button type="primary" onClick={props.handleFinishButton}>
              {intl.formatMessage({id: 'finish'})}
            </Button>
          </div>
        </div>
      </Form.Item>
    )
  }

  return (
    <Form className="w-100 mt-3 mx-3" layout="vertical">
      {util.isSensor(props.deviceKind) &&
      <TestBedSensorTable sensorData={props.fields.sensor} submittedValues={props.submittedValues}/>}
      {util.isActuator(props.deviceKind) &&
      <TestBedActuatorTable actuatorData={props.fields.actuator} submittedValues={props.submittedValues}/>}
      {renderFooterButtons()}
    </Form>
  );
};

export default AddDeviceSecondStep;
