import {combineReducers} from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import WS from './WS';
import Testbed from './TestBed';
import User from './User';
import Sion from './Sion';

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  ws: WS,
  testBed: Testbed,
  user: User,
  sion: Sion,
});

export default reducers;
